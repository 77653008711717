import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik, Form } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Paper, Button, CardContent, CardActions, Card, TextField, Box, Stack, FormControl, FormHelperText, MenuItem, Select, InputLabel, Autocomplete, Grid, Divider, Typography, TableRow, TableCell, IconButton, TablePagination } from '@mui/material';
import { Post, apiURL, Get, Put } from '../../../utils/apiCalls';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Base64File } from '../../../utils/Base64File';

const ProfileSchema = Yup.object().shape({
   first_name: Yup.string().required('First Name is required'),
   last_name: Yup.string().required('Last Name is required'),
   email: Yup.string().email('Invalid Email').required('Email is required'),
   // password: Yup.string().required('Password is required'),
   phone: Yup.string().required('Phone Number is required'),
   aadhar_number: Yup.string().matches(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/, 'Invalid Aadhar number').required('Aadhar Number is required'),
   pan_number: Yup.string().matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, 'Invalid PAN number').required('Pan Number is required'),
   //  aadhar_upload: Yup.string().required('Aadhar Upload is required'),
   //  pan_upload: Yup.string().required('Pan Upload is required'),
   address: Yup.string().required('Personal Address is required'),
   // bank_name: Yup.string().required('Bank Name is required'),
   // bank_account_number: Yup.string().required('Account Number is required'),
   // bank_ifsc_code: Yup.string().required('IFSC Number is required'),
   // bank_address: Yup.string().required('Bank Address is required'),
   // role: Yup.string().required('Role is required'),
});

const MyProfile = () => {

    const { id } = useParams()
    const navigate = useNavigate();
    const [profile, setProfile] = useState({});
    const [roles, setRoles] = useState([]);

    const Profile = async () => {
       const response = await Get(apiURL + '/api/v1/users/adminget', '')
       if (response && response.data) {
          setProfile(response.data.data)
       }
else {
   toast.error('Please Try Again')
   navigate('/dashboard/projectadmin')
}
      //  const responseRoles = await Get(apiURL + '/api/v1/users/getRoleByuser', '')
      //  if (responseRoles && responseRoles.data) {
      //     setRoles(responseRoles.data.data)
      //  }
    }
 
    useEffect(() => {
       Profile()
    }, [])
 
    const formik = useFormik({
       initialValues: {
         ...profile
       },
       enableReinitialize: true,
       validationSchema: ProfileSchema,
       onSubmit: async (values, { setSubmitting }) => {
          const response = await Put(apiURL + '/api/v1/users/adminedit', '', { ...values })
          if (response && response.data && response.data.status) {
             toast.success('Profile Updated Sucessfully')
             setSubmitting(false)
             navigate('/dashboard/projectadmin')
          }
          else {
             toast.error('Please Try Again')
             setSubmitting(false)
          }
       }
    });
 
    const { errors, touched, values, setFieldValue, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
   //  console.log(values)
   //  console.log(errors)
 
    const handleFiles = async (e) => {
       const { id, files } = e.target
       if ((files[0]['size'] / 1024) <= 2000) {
          const base64 = await Base64File(files[0])
          const file = { name: files[0]['name'], size: files[0]['size'], base64: base64 }
          setFieldValue(id, file)
       }
       else {
          toast.error('Please Upload File less than 2 MB')
       }
    }

    return (
      Object.keys(values).length > 0 ? <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
           <Card>
              <CardContent>
                 <Typography align='center' variant='h4'>My Profile</Typography>
                 <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={6}>
                       <TextField fullWidth label="First Name" {...getFieldProps('first_name')} error={Boolean(touched.first_name && errors.first_name)} helperText={touched.first_name && errors.first_name} />
                    </Grid>
                    <Grid item xs={6}>
                       <TextField fullWidth label="Last Name" {...getFieldProps('last_name')} error={Boolean(touched.last_name && errors.last_name)} helperText={touched.last_name && errors.last_name} />
                    </Grid></Grid>

                 <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={6}>
                       <TextField fullWidth label="Email Address" {...getFieldProps('email')} error={Boolean(touched.email && errors.email)} helperText={touched.email && errors.email} />
                    </Grid>
                    <Grid item xs={6}>
                       {/* <TextField fullWidth label="Password" {...getFieldProps('password')} error={Boolean(touched.password && errors.password)} helperText={touched.password && errors.password} /> */}
                    </Grid></Grid>

                 <Divider />
                 <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={4}>
                       <TextField fullWidth label="Phone Number" {...getFieldProps('phone')} error={Boolean(touched.phone && errors.phone)} helperText={touched.phone && errors.phone} />
                    </Grid>
                    <Grid item xs={4}>
                       <TextField fullWidth label="Aadhar Number" {...getFieldProps('aadhar_number')} error={Boolean(touched.aadhar_number && errors.aadhar_number)} helperText={touched.aadhar_number && errors.aadhar_number} />
                    </Grid>
                    <Grid item xs={4}>
                       <TextField fullWidth label="Pan Number" {...getFieldProps('pan_number')} error={Boolean(touched.pan_number && errors.pan_number)} helperText={touched.pan_number && errors.pan_number} />
                    </Grid></Grid>

                 <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={4}>
                       <Stack spacing={2}>
                          <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} >
                             Upload Aadhar
                             <VisuallyHiddenInput id='aadhar_upload' type="file" onChange={(e) => handleFiles(e)} />
                          </Button>
                          <Typography>{values.aadhar_upload?.['name'] ? values.aadhar_upload?.['name'] : "No File Choosen"}</Typography>
                       </Stack>
                    </Grid>
                    <Grid item xs={4}>
                       <Stack spacing={2}>
                          <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} >
                             Upload Pan Card
                             <VisuallyHiddenInput id='pan_upload' type="file" onChange={(e) => handleFiles(e)} />
                          </Button>
                          <Typography>{values.pan_upload?.['name'] ? values.pan_upload?.['name'] : "No File Choosen"}</Typography>
                       </Stack>
                    </Grid>
                    <Grid item xs={4}>
                       <TextField fullWidth label="Personal Address" {...getFieldProps('address')} error={Boolean(touched.address && errors.address)} helperText={touched.address && errors.address} />
                    </Grid></Grid>

                 <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={4}>
                       <TextField fullWidth label="Bank Name" {...getFieldProps('bank_name')} error={Boolean(touched.bank_name && errors.bank_name)} helperText={touched.bank_name && errors.bank_name} />
                    </Grid>
                    <Grid item xs={4}>
                       <TextField fullWidth label="Account Number" {...getFieldProps('bank_account_number')} error={Boolean(touched.bank_account_number && errors.bank_account_number)} helperText={touched.bank_account_number && errors.bank_account_number} />
                    </Grid>
                    <Grid item xs={4}>
                       <TextField fullWidth label="IFSC Code" {...getFieldProps('bank_ifsc_code')} error={Boolean(touched.bank_ifsc_code && errors.bank_ifsc_code)} helperText={touched.bank_ifsc_code && errors.bank_ifsc_code} />
                    </Grid></Grid>

                 <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={4}>
                       <TextField fullWidth label="Bank Address" {...getFieldProps('bank_address')} error={Boolean(touched.bank_address && errors.bank_address)} helperText={touched.bank_address && errors.bank_address} />
                    </Grid>
                    <Grid item xs={4}>
                       {/* <Autocomplete fullWidth
                          name='role'
                          id='role'
                          value={profile.find((data) => data.id === values.role)}
                          options={profile}
                          getOptionLabel={(option) =>
                             option.designation ?? option
                          }
                          renderOption={(props, option) => (
                             <Box component='li' sx={{ minWidth: '100px' }} {...props}>
                                {option.designation}
                             </Box>
                          )}
                          renderInput={(params) => (
                             <TextField
                                error={touched.role && Boolean(errors.role)}
                                helperText={touched.role && errors.role}
                                {...params}
                                label='Position or Role'
                             />
                          )}
                          onChange={(e, newvalue, reason) => {
                             setFieldValue('role', newvalue ? newvalue?.['id'] : '')
                          }}
                       /> */}
                    </Grid></Grid>
              </CardContent>

              <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                 <Button href="#/dashboard/projectAdmin" variant='contained' sx={{ backgroundColor: '#3f51b5', '&:hover': { color: '#ffff', backgroundColor: '#3f51b5' } }}>Back</Button>
                 <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ backgroundColor: '#3f51b5', '&:hover': { color: '#ffff', backgroundColor: '#3f51b5' } }}>Save </LoadingButton>
              </CardActions>
           </Card>
        </Form>
     </FormikProvider> : null
    )
}

export default MyProfile;

const VisuallyHiddenInput = styled('input')({
   clip: 'rect(0 0 0 0)',
   clipPath: 'inset(50%)',
   height: 1,
   overflow: 'hidden',
   position: 'absolute',
   bottom: 0,
   left: 0,
   whiteSpace: 'nowrap',
   width: 1,
});