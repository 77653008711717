
import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css/animate.css';
import { Button, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import Iconify from '../layouts/Iconify';
function About (){
  useEffect(() => {
    const wow = new WOW.WOW({
        live: false
    });
    wow.init();
}, []);

const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
return (
<>
  {/* <div id="page-preloader">
    <span className="spinner" />
  </div> */}
  {/* ========================== */}
  {/* Navigation */}
  {/* ========================== */}
  <header className="header scrolling-header">
      <nav
        id="nav"
        className="navbar navbar-default navbar-fixed-top"
        role="navigation"
      >
        <div className="container relative-nav-container">
          <a
            className="toggle-button visible-xs-block"
            data-toggle="collapse"
            data-target="#navbar-collapse"
          >
            <i className="fa fa-navicon" />
          </a>
          <a className="navbar-brand scroll" href="home.html" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <img className="normal-logo hidden-xs" src={require('../img/logo.png')} alt="logo" />
            <img className="scroll-logo hidden-xs" src={require('../img/logo-dark.png')} alt="logo" />
            <img className="scroll-logo visible-xs-block" src={require('../img/logo.png')} alt="logo" />
          </a>
          {/* <ul class="nav navbar-nav navbar-right nav-icons wrap-user-control">
                    <li>
                        <a id="search-open" href="#fakelink"><i class="fa fa-search"></i></a>
                    </li>
                    <li class="open-signup">
                        <a href="#fakelink"><i class="fa fa-user"></i></a>
                    </li>
                </ul> */}
          <div className="navbar-collapse collapse floated" id="navbar-collapse">
            <ul className="nav navbar-nav navbar-with-inside clearfix navbar-right with-border">
              <li >
                <a href="#/home">home</a>
                {/* <div class=" wrap-inside-nav">
                                <div class="inside-col">
                                    <ul class="inside-nav">
                                        <li><a href="home.html">Home 1</a></li>
                                        <li><a href="home-2.html">Home 2</a></li>
                                    </ul>
                                </div>
                            </div> */}
              </li>
              {/* <li>
                            <a href="#">Portfolio</a>
                            <div class=" wrap-inside-nav">
                                <div class="inside-col">
                                    <ul class="inside-nav">
                                        <li><a href="portfolio.html">Portfolio</a></li>
                                        <li><a href="portfolio-2.html">Portfolio 2</a></li>
                                        <li><a href="portfolio-3.html">Portfolio 3</a></li>
                                        <li><a href="portfolio-single.html">Portfolio single 1</a></li>
                                        <li><a href="portfolio-single-2.html">Portfolio single 2</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li> */}
              <li className="active">
                <a href="#/about">about</a>
              </li>
              <li>
                {/* <a href="#/project">Our Projects</a> */}
                {/* <Button
        aria-haspopup="true"
        className='menuButton'
        onClick={handleClick}
      >
        Our Projects
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ '.MuiMenu-paper' : {minWidth:'110px'} }}
      >
        <MenuItem component={'a'} href='#/project' onClose={handleClose}>
            <ListItemIcon >
            <AdminPanelSettingsIcon sx={{width:'1.5em', height:'1.5em'}} />
          </ListItemIcon>
          <ListItemText>Admin</ListItemText>
            </MenuItem>
        <MenuItem component={'a'} href='#/projectAgent' onClose={handleClose}>           
            <ListItemIcon>
            <PersonIcon sx={{width:'1.5em', height:'1.5em'}} />
          </ListItemIcon>
          <ListItemText>Agent</ListItemText>
            </MenuItem>
      </Menu> */}
      <Button aria-haspopup="true" href='#/projectLayout'>Our Projects</Button>

              </li>
              <li>
                <a href="#/services">services</a>
              </li>
              {/* <li>
                <a href="#/blog">Blog</a> */}
                {/* <div class=" wrap-inside-nav">
                                <div class="inside-col">
                                    <ul class="inside-nav">
                                        <li><a href="blog.html">Blog</a></li>
                                        <li><a href="blog-single.html">Blog single</a></li>
                                    </ul>
                                </div>
                            </div> */}
              {/* </li> */}
                {/* <a href="#/blog">Blog</a>
 
              </li> */}
              <li>
                <a href="#/contact">Contacts</a>
                {/* <div class=" wrap-inside-nav">
                                <div class="inside-col">
                                    <ul class="inside-nav">
                                        <li><a href="contact.html">Contacts 1 </a></li>
                                        <li><a href="contact-2.html">Contacts 2 </a></li>
                                    </ul>
                                </div>
                            </div> */}
              </li>
              <li> <Button className='menuButton' href='#/login' target='_blank'> Login In</Button> </li>

            </ul>
          </div>
        </div>
        {/* <div class="navbar-search ">
                <div class="container">
                    <form>
                        <div class="input-group">
                            <input type="text" placeholder="Type your search..." class="form-control" autocomplete="off">
                            <span class="input-group-btn">
                                <button type="reset" class="btn search-close" id="search-close">
                                    <i class="fa fa-close"></i>
                                </button>
                            </span>
                        </div>
                    </form>
                </div>
            </div> */}
      </nav>
    </header>
  {/*./navigation */}
  {/* ========================== */}
  {/* ABOUT - HEADER */}
  {/* ========================== */}
  <section className="top-header about-header with-bottom-effect transparent-effect ">
    <div className="" />
    <div className="header-container">
      <div className="header-title wow fadeInUp">
        <div className="header-icon">
        </div>
        <div className="title">about us</div>
       
      </div>
    </div>
    {/*container*/}
  </section>
  {/* ========================== */}
  {/* ABOUT - FEATURES */}
  {/* ========================== */}
  {/* <section class="features-section about-features-section">
      <div class="container">
          <div class="row wow fadeInUp">
              <div class="col-md-3 col-sm-6">
                  <article>
                      <div class="feature-item ">
                          <div class="wrap-feature-icon">
                              <div class="feature-icon">
                                  <span class="icon icon-Carioca"></span>
                              </div>
                          </div>
                          <div class="title">Clean &amp; modern</div>
                          <div class="text">
                              Lorem ipsum dolor sit amet consectetur
                              adipisicing elit sed don eiusmod tempor enim
                              minim veniam quis notrud exercitation
                          </div>
                      </div>
                  </article>
              </div> 
              <div class="col-md-3 col-sm-6">
                  <article>
                      <div class="feature-item active">
                          <div class="wrap-feature-icon">
                              <div class="feature-icon">
                                  <span class="icon icon-Heart"></span>
                              </div>
                          </div>
                          <div class="title">unique design</div>
                          <div class="text">
                              Amco laboris nisi ut aliquip xea commodo
                              consequat. Duis aute irure dolor reprehenderit
                              voluptate velit esse cillum dolore fugiat
                          </div>
                      </div>
                  </article>
              </div> 
              <div class="col-md-3 col-sm-6">
                  <article>
                      <div class="feature-item">
                          <div class="wrap-feature-icon">
                              <div class="feature-icon">
                                  <span class="icon  icon-Tools"></span>
                              </div>
                          </div>
                          <div class="title">multipurpose</div>
                          <div class="text">
                              Lorem ipsum dolor sit amet consectetur
                              adipisicing elit sed don eiusmod tempor enim
                              minim veniam quis notrud exercitation
                          </div>
                      </div>
                  </article>
              </div> 
              <div class="col-md-3 col-sm-6">
                  <article>
                      <div class="feature-item">
                          <div class="wrap-feature-icon">
                              <div class="feature-icon">
                                  <span class="icon icon-Blog"></span>
                              </div>
                          </div>
                          <div class="title">100% responsive</div>
                          <div class="text">
                              Amco laboris nisi ut aliquip xea commodo
                              consequat. Duis aute irure dolor reprehenderit
                              voluptate velit esse cillum dolore fugiat
                          </div>
                      </div>
                  </article>
              </div> 
          </div>
      </div>
  </section> */}
  {/* ========================== */}
  {/* ABOUT - LAPTOPS */}
  {/* ========================== */}
  {/* <section class="laptops-section">
      <div class="container">
          <div class="laptops text-center">
              <img src="img/laptop2.png" alt="" class="img-responsive" />
          </div>
      </div>
  </section> */}
  {/* ========================== */}
  {/* ABOUT - STEPS  */}
  {/* ========================== */}
  <section className="steps-section with-icon ">
    <div className="section-icon">
      <span className="icon icon-Umbrella" />
    </div>
    <div className="container">
      <div className="section-heading">
        <div className="section-title" >we are here for you</div>
        <div className="section-subtitle">
        Perfect Layout: Transforming Dreams into Reality
        </div>
        {/* <div class="design-arrow"></div> */}
      </div>
    </div>
    {/* <div class="container">
          <div class="row steps-list">
              <div class="col-md-4 col-sm-4 col-xs-4 wow fadeInUp" data-wow-delay="0.3s">
                  <div class="step-item">
                      <div class="item-icon" data-count="1">
                          <span class="icon icon-Pencil"></span>
                      </div>
                      <div class="item-text">
                          <h5>We Plan &amp; <br />
                              Sketch .
                          </h5>
                      </div>
                  </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-4 wow fadeInUp" data-wow-delay="0.5s">
                  <div class="step-item invert">
                      <div class="item-icon" data-count="2">
                          <span class="icon icon-Glasses"></span>
                      </div>
                      <div class="item-text">
                          <h5>We Design &amp; <br />
                              Develop .
                          </h5>
                      </div>
                  </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-4 wow fadeInUp" data-wow-delay="0.7s">
                  <div class="step-item">
                      <div class="item-icon" data-count="3">
                          <span class="icon icon-Plaine"></span>
                      </div>
                      <div class="item-text">
                          <h5>We Test &amp; <br />
                              Deliver .
                          </h5>
                      </div>
                  </div>
              </div>
          </div>
      </div> */}
    <div className="container results-container">
      <div className="row">
        <div className="col-md-6  col-sm-6">
          <img
            src={require("../img/hand.jpg")}
            style={{ width: 500, height: 300, marginTop:'45px' }}
            alt=""
          />
          {/* <ul class="skills">
                      <li data-percent="75" class="skills-animated ">
                          <span>web design</span>
                          <div class="progress" >
                              <div class="progress-percent">
                                  <span class="progress-bar-tooltip">75</span>%
                              </div>
                          </div>
                      </li>
                      <li data-percent="90" class="skills-animated orange">
                          <span>programming</span>
                          <div class="progress" >
                              <div class="progress-percent">
                                  <span class="progress-bar-tooltip">90</span>%
                              </div>
                          </div>
                      </li>
                      <li data-percent="55" class="skills-animated">
                          <span>marketing</span>
                          <div class="progress" >
                              <div class="progress-percent">
                                  <span class="progress-bar-tooltip">55</span>%
                              </div>
                          </div>
                      </li>
                      <li data-percent="85" class="skills-animated orange">
                          <span>photography</span> 
                          <div class="progress">
                              <div class="progress-percent">
                                  <span class="progress-bar-tooltip">85</span>%
                              </div>
                          </div>
                      </li>
                  </ul> */}
        </div>
        <div className="col-md-6 col-sm-6">
          <div className="results-description">
            <h5 className="italic-title">Every customer is importany to us!</h5>
            <h4>
              {" "}
              PERFECT LAYOUT ONE STOP SOLUTION FOR ALL YOUR PROPERTY NEEDS
            </h4>
            <p style={{fontSize:'13px'}}>
            Welcome to PERFECT LAYOUT PRIVATE LIMITED, where our dedicated team of real estate professionals is committed to providing exceptional services in land sales. With a profound passion for facilitating connections between individuals and families and their ideal pieces of property, we are devoted to making the land-buying process both seamless and rewarding.

Our personalized approach sets us apart, as we understand the uniqueness of each client's needs and aspirations. Leveraging our industry expertise and an extensive portfolio of listings, we are confident in our ability to help you discover the perfect piece of land that aligns with your vision for the future.

At PERFECT LAYOUT PRIVATE LIMITED, we pride ourselves on creating an experience that goes beyond transactions; we aim to make your land ownership dreams a reality. Explore our company today and let us guide you through the journey of finding and acquiring the ideal land that suits your preferences and fulfills your aspirations.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* ========================== */}
  {/* ABOUT - ACHIEVEMENTS */}
  {/* ========================== */}
  <section class=" " style={{background:'rgb(154 176 229)'}}>
            <div class="bottom-effect"></div>
            <div class="container dark-content" >
                <div class="row list-achieve ">
                    {/* <div class="col-md-6 col-sm-6 col-xs-6 wow zoomIn" data-wow-delay="0.5s">
                        <article>
                            <div class="achieve-item">
                                <div class="achieve-icon">
                                    <span class="icon icon-Wallet"></span>
                                </div>
                                <div class="count">VISION</div>
                                <div class="name">To be the industry pioneer in indian real estate</div>
                            </div>
                        </article>
                    </div> */}
                     <div class="col-md-6 col-sm-6 col-xs-6 wow zoomIn">
                        <article>
                            <div class="achieve-item" style={{display:'flex', marginRight:'300px'}}>
                              
                                <div class="achieve-icon" >
                                <img style={{height:'160px', width:"250px"}} src='https://ap1-infinity-user-data.s3.amazonaws.com/13416/teams/3243_WhatsApp%20Image%202024-02-03%20at%2013.34.48_2e82fea7.jpg' />
                                {/* <Icon icon="mdi-light:home" /> */}
                                {/* <Iconify
                                style={{ color: 'white' }}
            icon={"covid:transmission-virus-touch-finger"} 
            sx={{ width: 50, height: 50, ml: 1,  }} */}
{/*           />   */}
                                    {/* <span class="icon icon-Users"></span> */}

                                </div>
                                <div style={{display:'block'}}>
                                <div class="count">VISION</div>
                                <div class="name">To be the commerical  real estate compnay of choice for a new generation of sales Associates and real estate owners</div>
                                </div>
                            </div>
                        </article>
                    </div>
                    {/* <!-- <div class="col-md-3 col-sm-3 col-xs-6 wow zoomIn" data-wow-delay="1.5s">
                        <article>
                            <div class="achieve-item">
                                <div class="achieve-icon">
                                    <span class="icon icon-Cup"></span>
                                </div>
                                <div class="count">24</div>
                                <div class="name">awards won</div>
                            </div>
                        </article>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-6 wow zoomIn" data-wow-delay="2.5s">
                        <article>
                            <div class="achieve-item">
                                <div class="achieve-icon">
                                    <span class="icon icon-Like"></span>
                                </div>
                                <div class="count">160</div>
                                <div class="name">happy clients</div>
                            </div>
                        </article>
                    </div> --> */}
                    <div class="col-md-6 col-sm-6 col-xs-6 wow zoomIn" >
                        <article>
                            <div class="achieve-item" style={{display:'flex', }}>
                              
                                <div class="achieve-icon" >
                                <img style={{height:'160px',width:"250px"}}  src='https://ap1-infinity-user-data.s3.amazonaws.com/13416/teams/7747_WhatsApp%20Image%202024-02-03%20at%2012.53.00_6ad163c4.jpg' />
                                {/* <Icon icon="mdi-light:home" /> */}
                                {/* <Iconify
                                style={{ color: 'white' }}
            icon={"covid:transmission-virus-touch-finger"} 
            sx={{ width: 50, height: 50, ml: 1,  }} */}
{/*           />   */}
                                    {/* <span class="icon icon-Users"></span> */}

                                </div>
                                <div style={{display:'block'}}>
                                <div class="count">MISSION</div>
                                <div class="name">To always provide our esteemed customers with plots at fair prices and with full transparency and legality</div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>
  {/* ========================== */}
  {/* ABOUT - TEAM */}
  {/* ========================== */}
  {/* <section class="team-section with-bottom-effect">
      <div class="bottom-effect"></div>
      <div class="container">
          <div class="section-heading">
              <div class="section-title">experienced team</div>
              <div class="section-subtitle">Lorem ipsum dolor amet consectetur adipisic elit</div>
              <div class="design-arrow"></div>
          </div>
      </div>
      <div class="container">
          <div class="wrap-team-slider"> 
              <div class="prev-btn"><span class="icon invertX icon-Goto"></span></div>
              <div class="next-btn"><span class="icon icon-Goto"></span></div>
              <div class="team-slider enable-stick-slider" data-slick='{"slidesToShow": 3, "slidesToScroll": 1, "prevArrow" : ".prev-btn", "nextArrow": ".next-btn" }'>
                  <div class="slide-item">
                      <div class="image">
                          <img src="img/team/member-1.png" alt="" />
                      </div>
                      <div class="slide-description">
                          <div class="member-info">
                              <div class="name">dan tarkist</div>
                              <div class="position">Creative Director</div>
                          </div>
                          <div class="contacts">
                              <ul>
                                  <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                  <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                  <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                  <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                  <li><a href="#"><i class="fa fa-envelope"></i></a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div class="slide-item">
                      <div class="image">
                          <img src="img/team/member-3.png" alt="" />
                      </div>
                      <div class="slide-description">
                          <div class="member-info">
                              <div class="name">sarina ken</div>
                              <div class="position">Creative Director</div>
                          </div>
                          <div class="contacts">
                              <ul>
                                  <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                  <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                  <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                  <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                  <li><a href="#"><i class="fa fa-envelope"></i></a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div class="slide-item">
                      <div class="image">
                          <img src="img/team/member-2.png" alt="" />
                      </div>
                      <div class="slide-description">
                          <div class="member-info">
                              <div class="name">ana mes</div>
                              <div class="position">Product Manager</div>
                          </div>
                          <div class="contacts">
                              <ul>
                                  <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                  <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                  <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                  <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                  <li><a href="#"><i class="fa fa-envelope"></i></a></li>
                              </ul>
                          </div>
                      </div>
                  </div> 
                  <div class="slide-item">
                      <div class="image">
                          <img src="img/team/member-1.png" alt="" />
                      </div>
                      <div class="slide-description">
                          <div class="member-info">
                              <div class="name">dan tarkist</div>
                              <div class="position">Creative Director</div>
                          </div>
                          <div class="contacts">
                              <ul>
                                  <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                  <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                  <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                  <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                  <li><a href="#"><i class="fa fa-envelope"></i></a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div class="slide-item">
                      <div class="image">
                          <img src="img/team/member-3.png" alt="" />
                      </div>
                      <div class="slide-description">
                          <div class="member-info">
                              <div class="name">sarina ken</div>
                              <div class="position">Creative Director</div>
                          </div>
                          <div class="contacts">
                              <ul>
                                  <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                  <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                  <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                  <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                  <li><a href="#"><i class="fa fa-envelope"></i></a></li>
                              </ul>
                          </div>
                      </div>
                  </div>

              </div>
          </div>

      </div>
  </section> */}
  {/* ========================== */}
  {/* ABOUT - HISTORY */}
  {/* ========================== */}
  {/* <section class="history-section">
      <div class="container">
          <div class="section-heading">
              <div class="section-title">company history</div>
              <div class="section-subtitle">Lorem ipsum dolor amet consectetur adipisic elit</div>
              <div class="design-arrow"></div>
          </div>
      </div>

      <div class="wrap-timeline">
          <div class="container">
              <div class="row top-row">
                  <div class="col-md-12">
                      <div class="time-title" id="timel"> <br />
                          <div class="round-ico"><span class="icon icon-Flag"></span></div>
                      </div>
                  </div>
              </div>
              <div class="row left-row">
                  <div class="round-ico little"></div>
                  <div class="col-md-6 col-sm-6 time-item wow fadeInUp" data-wow-duration="2s" >
                      <div class="date">October 2015</div>
                      <div class="title">5 New Projects Launched</div>
                      <p>Amco laboris nisi ut aliquip xea commodo consequt. Duis aute irure
                          dolor reprehenderit voluptate velit esse cillum dolore fugiat lore
                          ipsum dolor sit amet consectetur adipisicing elit sed do </p>
                  </div>
              </div>
              <div class="row right-row">
                  <div class="round-ico big"></div>
                  <div class="col-md-6 col-sm-6"></div>
                  <div class="col-md-6 col-sm-6 time-item wow fadeInUp" data-wow-duration="2s" >
                      <div class="date">September 2015</div>
                      <div class="title">Celebrating 10 years of Creativity</div>
                      <div class="time-image">
                          <img src="img/img10.jpg" alt="" />
                      </div>
                      <p>Amco laboris nisi ut aliquip xea commodo consequt. Duis aute irure
                          dolor reprehenderit voluptate velit esse cillum</p>
                  </div>
              </div>
              <div class="row left-row">
                  <div class="round-ico little"></div>
                  <div class="col-md-6 col-sm-6 time-item wow fadeInUp" data-wow-duration="2s" >
                      <div class="date">August 2015</div>
                      <div class="title">New Jobs Opening : Apply Now!</div>
                      <p>Amco laboris nisi ut aliquip xea commodo consequt. Duis aute irure
                          dolor reprehenderit voluptate velit esse cillum dolore fugiat lore
                          ipsum dolor sit amet consectetur adipisicing elit sed do </p>
                  </div>
              </div>
              <div class="plus">
                  <a href="#" class="plus-ico">+</a>
              </div>
          </div>
      </div>
  </section> */}
  {/* ========================== */}
  {/* ABOUT - LATEST NEWS */}
  {/* ========================== */}
  <section className="latest-news-section clearfix">
    <div className="container">
      <div className="section-heading">
        <div className="section-title">Meet Our director or team</div>
        <div className="section-subtitle">Dedicated Team</div>
        <div className="design-arrow" />
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-4 col-sm-4 wow fadeInUp">
          <div className="news-item">
            <div className="image">
              <a>
                <img src={require("../img/sathesh.jpeg")} alt="" />
                <div className="image-content">
                  {/* <span class="read-more">read more</span>   */}
                </div>
              </a>
            </div>
            <div className="user-avatar clearfix"></div>
          </div>
        </div>
        <div className="col-md-1" />
        <div className="col-md-7 col-sm-8 ">
          <div className="news-body"  >
            <h5>
              SATEESH KUMAR BUDDE
              <p>( Founder &amp; CEO )</p>
            </h5>
            <p style={{fontSize:'13px'}}>
            I am honored to welcome you to PERFECT LAYOUT PRIVATE LIMITED, where innovation meets excellence. As the Founder and CEO, I am thrilled to lead a team of dedicated individuals who share a passion for Real-Estate.
<br></br><br></br>

Passionate founder and CEO dedicated to driving innovation and success. Visionary leader with a proven track record of turning ideas into reality. Committed to fostering a culture of excellence, collaboration, and growth. Transforming challenges into opportunities and steering the company towards unprecedented achievements. Ready to inspire and lead our team to new heights."

<br></br><br></br>
I invite you to join us on this exciting journey. As we continue to grow and evolve, your support and partnership are invaluable. Together, we will redefine what's possible and leave a lasting impact on  Real-Estate industry.

<br ></br> <div style={{paddingBottom:'10px'}}></div>
Thank you for being a part of the PERFECT LAYOUT PRIVATE LIMITED family.

              {/* Team Leader of 200 individuals in siddhi vinayaka property
              developers. Independent Business Associates in 5 companies in Land
              Mark indra, Nature Avenues infra, Diamond Green Cities, Real
              Vision infra &amp; Siddhi Vinayaka property Developer. 7+ years of
              experties in Real Estate, Finance and Stack Market. Financial
              Advisor of HDFC, ICICI, Aditya Birla Mutual Funds &amp; IIFL
              Securities Ltf */}
            </p>
          </div>
        </div>
        {/* <div class="col-md-4 col-sm-4 wow fadeInUp">
                  <div class="news-item">
                      <div class="image">
                          <a >
                              <img src="img/sathesh.jpeg" alt="" />

                              <div class="image-content">
                                
                              </div>
                          </a> 
                      </div> 
                      <div class="user-avatar clearfix">
                      </div>
                      <div class="news-body">
                          <h5>SATEESH KUMAR BUDDE</h5>
                          <p>
                              The Managing Director of Shathabdhi Townships Pvt. Ltd, Sri. K. Srinivas Reddy is a successful real estate professional with experience and expertise for more than a decade. His success is attributed to his clear cut vision and long term planning in making the company prosper consistently, year after year..

                          </p>
                      </div>
                  </div>
              </div>
              <div class="col-md-4 col-sm-4 wow fadeInUp">
                  <div class="news-item">
                      <div class="image">
                          <a >
                              <img src="img/sathesh.jpeg" alt="" />

                              <div class="image-content">
                              </div>
                          </a> 
                      </div> 
                      <div class="user-avatar clearfix">
                      </div>
                      <div class="news-body">
                          <h5>SATEESH KUMAR BUDDE</h5>
                          <p>
                              The Managing Director of Shathabdhi Townships Pvt. Ltd, Sri. K. Srinivas Reddy is a successful real estate professional with experience and expertise for more than a decade. His success is attributed to his clear cut vision and long term planning in making the company prosper consistently, year after year..

                          </p>
                      </div>
                  </div>
              </div> */}
      </div>
    </div>
  </section>
  {/* ========================== */}
  {/* ABOUT - VIDEO SECTION */}
  {/* ========================== */}
  <section >
  {/* className="video-section with-bottom-effect dark dark-strong" */}
    {/* <div class="video-play" id="video-play" data-property="{videoURL:'https://www.youtube.com/watch?v=MFQ1CBz4sv4',containment:'#video-play',autoPlay:true, mute:true, startAt:0, opacity:1}"></div> */}
    {/* <video class="video-play" src="videos/perfectlayoutvideo1.mp4" autoplay="true">
          Your browser doesn't support the `<video>` tag. /*this is a fallback* /
          </video> */}
                  <div className='container '>
            <div className="section-title ">Watch our Intro Video</div>
            <div style={{display:'flex',justifyContent:"center",marginBottom:'50px',marginTop:'50px'}}>
            <iframe width="500" height="390" src="https://www.youtube.com/embed/MpBen6miMRU" style={{boxShadow:"rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"}} >
</iframe>


            </div>
        
            </div>
    {/* <div className="video-play" id="video-play" data-property="my-video">
                <video id="video-play" controls style={{width: "100%"}}>
                    <source src={require("../videos/perfectlayoutvideo1.mp4")} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
    <div className="bottom-effect" />
    <div className="container dark-content">
      <div className="row">
        <div className="col-md-12 text-center">
          <div className="title">Watch our Intro Video</div>
          <em>Sample View of Land</em>
          <a href={'https://youtu.be/MpBen6miMRU'} target="_blank"><button type="button" className="btn-play"></button></a>

          <div className="duration">
            Duration : <span>2:34</span>
          </div>
        </div>
      </div>
    </div> */}
  </section>
  {/* ========================== */}
  {/* ABOUT - CREATE IDEAS */}
  {/* ========================== */}
  {/* <section class="create-ideas-section with-icon with-top-effect clearfix">
      <div class="section-icon"><span class="icon icon-Umbrella"></span></div>
      <div class="container">
          <div class="section-heading">
              <div class="section-title">we create beautiful ideas</div>
              <div class="section-subtitle">Lorem ipsum dolor sit amet consectetur elit</div>
              <div class="design-arrow"></div>
          </div>
      </div>
      <div class="container">
          <div class="idea-image">
              <img src="img/table-mac.png" alt="" />
          </div>
      </div>
  </section> */}
  {/* ========================== */}
  {/* FOOTER */}
  {/* ========================== */}
  <footer>
    {/* ========================== */}
    {/* BUY SECTION */}
    {/* ========================== */}
    {/* <section class="buy-section with-icon">
          <div class="section-icon"><span class="icon icon-Umbrella"></span></div>
          <div class="container">
              <div class="row">
                  <div class="col-md-8 col-md-offset-1 col-sm-9 wow fadeInLeft">
                      <div class="section-text">
                          <div class=" vcenter like">
                              <span class="icon icon-Like"></span> 
                          </div>
                          <div class="buy-text vcenter">
                              <div class="top-text">oscend - a creative & multipurpose template</div>
                              <div class="bottom-text">Lorem ipsum dolor sit amet consectetur elit sed</div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3 col-sm-3  wow fadeInRight">
                      <a href="#" class="btn btn-info ">Buy it now</a>
                  </div>
              </div>
          </div>
      </section> */}
    {/* ========================== */}
    {/* FOOTER - SOCIAL */}
    {/* ========================== */}
    {/* <section className="social-section dark dark-strong">
      <div className="container dark-content">
        <div className="tt">We are social 24/7 - Get in touch</div>
        <ul className="list-socials">
          <li>
            <a href="#">
              <i className="fa fa-twitter" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-facebook" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-linkedin" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-google-plus" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-dribbble" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-pinterest-p" />
            </a>
          </li>
        </ul>
      </div>
    </section> */}
    {/* ========================== */}
    {/* FOOTER - FOOTER */}
    {/* ========================== */}
    <section className="footer-section">
      <div className="container">
        <div className="row">
          {/* <div className="col-md-3 col-sm-3">
            <h5>about us</h5>
            <p>
              Crowned as ASIA’S GREATEST BRAND and ASIA’S GREATEST LEADER With
              Mr. Sathesh kumar Garu, the recipient of Global Indian Of The Year
              award, at the helm
            </p>
          </div> */}
          <div className="col-md-3 col-sm-3">
            <h5>Sitemap</h5>
            <div className="row">
              <div className="col-md-6">
                <ul className="footer-nav">
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li className="active">
                    <a href="#">Latest News</a>
                  </li>
                  <li>
                    <a href="#">Contact us</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="footer-nav">
                  <li>
                    <a href="#">Get Help</a>
                  </li>
                  <li>
                    <a href="#">Careers</a>
                  </li>
                  <li>
                    <a href="#">FAQ's</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <h5>Contact info</h5>
            <ul className="contacts-list">
              <li>
                <p>
                  <i className="icon icon-House" />
                  NO. 2-1-266/1/6, Flat No.201, Plot NO. 6, 2nd Floor, GSI (Sr) Bandlanguda, Nagole, Hyderabad -500068
                </p>
              </li>
              {/* <li>
                <p>
                  <i className="icon icon-Phone2" />
                  +91 8374416741
                </p>
              </li> */}
              <li>
                <p>
                  <i className="icon icon-Mail" />
                  <a href="mailto:support@oscend.com">
              care@perfectlayout.in
                  </a>{" "}
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-3">
            <h5>newsletter</h5>
            <p>
              Subscribe to our newsletter for latest updates about our creative
              agency
            </p>
            <div className="form-group has-feedback">
              <input
                type="email"
                className="form-control "
                placeholder="Email address ..."
              />
              <i className="icon icon-Mail form-control-feedback" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="copyright-section">
      <p>
        ©2023 <span>PERFECT LAYOUT PRIVATE LIMITED </span>. All Rights Reserved
      </p>
    </section>
  </footer>
  {/* ========================== */}
  {/* FOOTER - SOCIAL */}
  {/* ========================== */}
  {/* <section className="social-section dark dark-strong">
    <div className="container dark-content">
      <div className="tt">We are social 24/7 - Get in touch</div>
      <ul className="list-socials">
        <li>
          <a href="#">
            <i className="fa fa-twitter" />
          </a>
        </li>
        <li>
          <a href="#">
            <i className="fa fa-facebook" />
          </a>
        </li>
        <li>
          <a href="#">
            <i className="fa fa-linkedin" />
          </a>
        </li>
        <li>
          <a href="#">
            <i className="fa fa-google-plus" />
          </a>
        </li>
        <li>
          <a href="#">
            <i className="fa fa-dribbble" />
          </a>
        </li>
        <li>
          <a href="#">
            <i className="fa fa-pinterest-p" />
          </a>
        </li>
      </ul>
    </div>
  </section> */}
 
</>
)
}


export default About;


