import React, { useEffect } from 'react';
import WOW from 'wowjs';

const Blog = () => {
    useEffect(() => {
        const wow = new WOW.WOW({
            live: false
        });
        wow.init();
    }, []);
    return (
<>
<>
  {/* <div id="page-preloader">
    <span className="spinner" />
  </div> */}
  {/* ========================== */}
  {/* Navigation */}
  {/* ========================== */}
  <header className="header scrolling-header">
    <nav
      id="nav"
      className="navbar navbar-default navbar-fixed-top"
      role="navigation"
    >
      <div className="container relative-nav-container">
        <a
          className="toggle-button visible-xs-block"
          data-toggle="collapse"
          data-target="#navbar-collapse"
        >
          <i className="fa fa-navicon" />
        </a>
        <a className="navbar-brand scroll" href="home.html" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
          <img
            className="normal-logo hidden-xs"
            src={require("../img/logo.png")}
            alt="logo"
          />
          <img
            className="scroll-logo hidden-xs"
            src={require("../img/logo-dark.png")}
            alt="logo"
          />
          <img
            className="scroll-logo visible-xs-block"
            src={require("../img/logo.png")}
            alt="logo"
          />
        </a>
        {/* <ul class="nav navbar-nav navbar-right nav-icons wrap-user-control">
                  <li>
                      <a id="search-open" href="#fakelink"><i class="fa fa-search"></i></a>
                  </li>
                  <li class="open-signup">
                      <a href="#fakelink"><i class="fa fa-user"></i></a>
                  </li>
              </ul> */}
        <div className="navbar-collapse collapse floated" id="navbar-collapse">
          <ul className="nav navbar-nav navbar-with-inside clearfix navbar-right with-border">
            <li>
              <a href="#/home">home</a>
              {/* <div class=" wrap-inside-nav">
                              <div class="inside-col">
                                  <ul class="inside-nav">
                                      <li><a href="home.html">Home 1</a></li>
                                      <li><a href="home-2.html">Home 2</a></li>
                                  </ul>
                              </div>
                          </div> */}
            </li>
            {/* <li>
                          <a href="#">Portfolio</a>
                          <div class=" wrap-inside-nav">
                              <div class="inside-col">
                                  <ul class="inside-nav">
                                      <li><a href="portfolio.html">Portfolio</a></li>
                                      <li><a href="portfolio-2.html">Portfolio 2</a></li>
                                      <li><a href="portfolio-3.html">Portfolio 3</a></li>
                                      <li><a href="portfolio-single.html">Portfolio single 1</a></li>
                                      <li><a href="portfolio-single-2.html">Portfolio single 2</a></li>
                                  </ul>
                              </div>
                          </div>
                      </li> */}
            <li>
              <a href="#/about">about</a>
            </li>
            <li>
              <a href="#/project">Our Projects</a>
            </li>
            <li>
              <a href="#/services">services</a>
            </li>
            <li className="active">
              <a href="/blog">Blog</a>
              {/* <div class=" wrap-inside-nav">
                              <div class="inside-col">
                                  <ul class="inside-nav">
                                      <li><a href="blog.html">Blog</a></li>
                                      <li><a href="blog-single.html">Blog single</a></li>
                                  </ul>
                              </div>
                          </div> */}
            </li>
            <li>
              <a href="#/contact">Contacts</a>
              {/* <div class=" wrap-inside-nav">
                              <div class="inside-col">
                                  <ul class="inside-nav">
                                      <li><a href="contact.html">Contacts 1 </a></li>
                                      <li><a href="contact-2.html">Contacts 2 </a></li>
                                  </ul>
                              </div>
                          </div> */}
            </li>
          </ul>
        </div>
      </div>
      {/* <div class="navbar-search ">
              <div class="container">
                  <form>
                      <div class="input-group">
                          <input type="text" placeholder="Type your search..." class="form-control" autocomplete="off">
                          <span class="input-group-btn">
                              <button type="reset" class="btn search-close" id="search-close">
                                  <i class="fa fa-close"></i>
                              </button>
                          </span>
                      </div>
                  </form>
              </div>
          </div> */}
    </nav>
  </header>
  {/*./navigation */}
  {/* ========================== */}
  {/* BLOG - HEADER */}
  {/* ========================== */}
  <section className="top-header blog-header with-bottom-effect transparent-effect dark dark-strong">
    <div className="bottom-effect" />
    <div className="header-container wow fadeInUp">
      <div className="header-title">
        <div className="header-icon">
          <span className="icon icon-Wheelbarrow" />
        </div>
        <div className="title">latest news</div>
        <em>Lorem ipsum dolor amet consect</em>
      </div>
    </div>
    {/*container*/}
  </section>
  {/* ========================== */}
  {/* BLOG - CONTENT */}
  {/* ========================== */}
  <section className="blog-content-section">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-md-8 left-column">
          {/* Blog post*/}
          <div className="wrap-blog-post wow fadeInUp">
            <div
              id="carousel-example-generic"
              className="carousel slide"
              data-ride="carousel"
            >
              {/* Wrapper for slides */}
              {/* <div className="carousel-inner">
                <div className="item active">
                  <img
                    src={require("../img/blog/slider-image1.jpg")}
                    className="img-responsive"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    src={require("../img/blog/slider-image1.jpg")}
                    className="img-responsive"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    src={require("../img/blog/slider-image1.jpg")}
                    className="img-responsive"
                    alt=""
                  />
                </div>
              </div> */}
              {/* Controls */}
              <a
                className="left carousel-control"
                href="#carousel-example-generic"
                data-slide="prev"
              >
                <i className="fa fa-angle-left" />
              </a>
              <a
                className="right carousel-control"
                href="#carousel-example-generic"
                data-slide="next"
              >
                <i className="fa fa-angle-right" />
              </a>
            </div>
            <div className="wrap-post-description">
              {/* <a className="post-avatar" href="#fakelink">
                <img className="" src={ require("../img/avatars/img1.png")} alt="avatar" />
              </a> */}
              <div className="meta">
                <div className="meta-item">
                  <span className="icon icon-Tag" />
                  MOBile ui, creative, Team
                </div>
                <div className="meta-item">
                  <span className="icon icon-Agenda" />
                  26 SEP
                </div>
                <div className="meta-item">
                  <span className="icon icon-Eye" />
                  32 views
                </div>
              </div>
            </div>
            <div className="post-body">
              <h2>Lorem ipsum dolor sit amet</h2>
              <p>
                Amco laboris nisi ut aliquip xea commodo consequt. Duis aute
                irure dolor reprehenderit voluptate velit esla fugit lore ipsum
                dolor sit consectetur adipisicing elit sed do eiusmod tempor
                incididunt bore aliqua ute enim ad mid veniam quis nostrud
                exercitationullamco. Lorem ipsum dolor sit amet consectetur
                adipisicing elit sed do eiusmod.
              </p>
              <div className="row">
                <div className="col-md-12 clearfix">
                  <a href="index.html" className="btn btn-primary   pull-left">
                    Read more
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*blog-post*/}
          {/* Blog post*/}
          <div className="wrap-blog-post wow fadeInUp">
            <div className="wrap-image">
              {/* <img
                className="img-responsive"
                src={require("../img/blog/image1.jpg")}
                alt=""
              /> */}
            </div>
            <div className="wrap-post-description">
              {/* <a className="post-avatar" href="#fakelink">
                <img className="" src={require("../img/avatars/img2.png")} alt="avatar" />
              </a> */}
              <div className="meta">
                <div className="meta-item">
                  <span className="icon icon-Tag" />
                  MOBile ui, creative, Team
                </div>
                <div className="meta-item">
                  <span className="icon icon-Agenda" />
                  26 SEP
                </div>
                <div className="meta-item">
                  <span className="icon icon-Eye" />
                  32 views
                </div>
              </div>
            </div>
            <div className="post-body">
              <h2>consect etur adipisicing elit sed do</h2>
              <p>
                Amco laboris nisi ut aliquip xea commodo consequt. Duis aute
                irure dolor reprehenderit voluptate velit esla fugit lore ipsum
                dolor sit consectetur adipisicing elit sed do eiusmod tempor
                incididunt bore aliqua ute enim ad mid veniam quis nostrud
                exercitationullamco. Lorem ipsum dolor sit amet consectetur
                adipisicing elit sed do eiusmod.
              </p>
              <div className="row">
                <div className="col-md-12 clearfix">
                  <a href="index.html" className="btn btn-primary   pull-left">
                    Read more
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*blog-post*/}
          {/* Blog post*/}
          {/* <div className="wrap-blog-post wow fadeInUp">
            <div className="wrap-video">
              <img
                className="img-responsive"
                src={require("../img/blog/image2.jpg")}
                alt=""
              />
              <div className="play-video">
                <span />
              </div>
            </div>
            <div className="wrap-post-description">
              <a className="post-avatar" href="#fakelink">
                <img className="" src={require("../img/avatars/img2.png")} alt="avatar" />
              </a>
              <div className="meta">
                <div className="meta-item">
                  <span className="icon icon-Tag" />
                  MOBile ui, creative, Team
                </div>
                <div className="meta-item">
                  <span className="icon icon-Agenda" />
                  26 SEP
                </div>
                <div className="meta-item">
                  <span className="icon icon-Eye" />
                  32 views
                </div>
              </div>
            </div>
            <div className="post-body">
              <h2>minim veniam quis nostrud ullamco</h2>
              <p>
                Amco laboris nisi ut aliquip xea commodo consequt. Duis aute
                irure dolor reprehenderit voluptate velit esla fugit lore ipsum
                dolor sit consectetur adipisicing elit sed do eiusmod tempor
                incididunt bore aliqua ute enim ad mid veniam quis nostrud
                exercitationullamco. Lorem ipsum dolor sit amet consectetur
                adipisicing elit sed do eiusmod.
              </p>
              <div className="row">
                <div className="col-md-12 clearfix">
                  <a href="index.html" className="btn btn-primary   pull-left">
                    Read more
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          {/*blog-post*/}
          {/* Blog post*/}
          <div className="wrap-blog-post wow fadeInUp">
            <div className="wrap-linked-image">
              {/* <img
                className="img-responsive"
                src={require("../img/blog/image3.jpg")}
                alt=""
              /> */}
              <a href="http://www.themeforest.net" target="_blank">
                <span className="icon icon-Unlinked" />
                www.themeforest.net
              </a>
            </div>
            <div className="wrap-post-description">
              {/* <a className="post-avatar" href="#fakelink">
                <img className="" src={require("../img/avatars/img1.png")} alt="avatar" />
              </a> */}
            </div>
          </div>
          {/*blog-post*/}
          {/* Blog post*/}
          <div className="wrap-blog-post wow fadeInUp">
            <div className="wrap-image">
              {/* <img
                className="img-responsive"
                src={ require("../img/blog/image4.jpg")}
                alt=""
              /> */}
            </div>
            <div className="wrap-post-description">
              {/* <a className="post-avatar" href="#fakelink">
                <img className="" src= {require("../img/avatars/img2.png")} alt="avatar" />
              </a> */}
              <div className="meta">
                <div className="meta-item">
                  <span className="icon icon-Tag" />
                  MOBile ui, creative, Team
                </div>
                <div className="meta-item">
                  <span className="icon icon-Agenda" />
                  26 SEP
                </div>
                <div className="meta-item">
                  <span className="icon icon-Eye" />
                  32 views
                </div>
              </div>
            </div>
            <div className="post-body">
              <h2>eiusmod tempor incididunt labore et</h2>
              <p>
                Amco laboris nisi ut aliquip xea commodo consequt. Duis aute
                irure dolor reprehenderit voluptate velit esla fugit lore ipsum
                dolor sit consectetur adipisicing elit sed do eiusmod tempor
                incididunt bore aliqua ute enim ad mid veniam quis nostrud
                exercitationullamco. Lorem ipsum dolor sit amet consectetur
                adipisicing elit sed do eiusmod.
              </p>
              <div className="row">
                <div className="col-md-12 clearfix">
                  <a href="index.html" className="btn btn-primary   pull-left">
                    Read more
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*blog-post*/}
          <div className="row wrap-pagination wow fadeInUp">
            <div className="col-md-12">
              <ul className="pagination-list clearfix">
                <li>
                  <a href="#">
                    <i className="fa-chevron-left" />
                  </a>
                </li>
                <li className="active">
                  <a href="#">1</a>
                </li>
                <li>
                  <a href="#">2</a>
                </li>
                <li>
                  <a href="#">3</a>
                </li>
                <li>
                  <a href="#">4</a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa-chevron-right" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-lg-offset-1 col-md-4  sidebar">
          {/* SEARCH */}
          <div
            className="form-group search has-feedback wow fadeInUp"
            data-wow-duration="2s"
          >
            <form>
              <input className="form-control " placeholder="SEARCH" />
              <i className="icon icon-Search form-control-feedback" />
            </form>
          </div>
          <div className="sidebar-box wow fadeInUp">
            <h5>Categories</h5>
            <div className="sidebar-box-content">
              <ul className="category-list">
                <li>
                  <a href="#">Business Analysis</a>
                </li>
                <li>
                  <a href="#">Web Design Development</a>
                </li>
                <li>
                  <a href="#">Modern Layouts</a>
                </li>
                <li>
                  <a href="#">3D Animation</a>
                </li>
                <li>
                  <a href="#">Free PSD Graphics</a>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="sidebar-box wow fadeInUp">
            <h5>recent posts</h5>
            <div className="sidebar-box-content">
              <div className="recent-posts">
                <div className="post-item">
                  <div className="image">
                    <a href="#">
                      <img src= {require("../img/blog/image5.jpg" )}alt="" />
                    </a>
                  </div>
                  <h5>Lorem ipsum dolor sit </h5>
                  <div className="meta-item">
                    <span className="icon icon-Agenda" />
                    September 06, 2015
                  </div>
                </div>
                <div className="post-item">
                  <div className="image">
                    <a href="#">
                      <img src= {require("../img/blog/image6.jpg")} alt="" />
                    </a>
                  </div>
                  <h5>amet conseca dipisicing</h5>
                  <div className="meta-item">
                    <span className="icon icon-Agenda" />
                    September 06, 2015
                  </div>
                </div>
                <div className="post-item">
                  <div className="image">
                    <a href="#">
                      <img src= {require("../img/blog/image7.jpg")} alt="" />
                    </a>
                  </div>
                  <h5>tempor incididunt labore et</h5>
                  <div className="meta-item">
                    <span className="icon icon-Agenda" />
                    September 06, 2015
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="sidebar-box wow fadeInUp">
            <h5>Instagram</h5>
            {/* <ul className="list-social">
              <li>
                <a href="#">
                  <img src={require("../img/blog/mini-img1.jpg")} alt="" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={require("../img/blog/mini-img2.jpg")} alt="" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={require("../img/blog/mini-img3.jpg")} alt="" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={require("../img/blog/mini-img4.jpg")} alt="" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src= {require("../img/blog/mini-img5.jpg")} alt="" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={require("../img/blog/mini-img6.jpg")} alt="" />
                </a>
              </li>
            </ul> */}
          </div>
          <div className="sidebar-box wow fadeInUp">
            <h5>tags cloud</h5>
            <ul className="list-tags">
              <li>
                <a href="#" className="btn btn-primary-warning">
                  designing
                </a>
              </li>
              <li>
                <a href="#" className="btn btn-primary-warning">
                  Development
                </a>
              </li>
              <li>
                <a href="#" className="btn btn-primary-warning">
                  photography
                </a>
              </li>
              <li>
                <a href="#" className="btn btn-primary-warning">
                  website
                </a>
              </li>
              <li>
                <a href="#" className="btn btn-primary-warning">
                  ALL PROJECTS
                </a>
              </li>
              <li>
                <a href="#" className="btn btn-primary-warning">
                  SEO
                </a>
              </li>
              <li>
                <a href="#" className="btn btn-primary-warning">
                  responsive
                </a>
              </li>
              <li>
                <a href="#" className="btn btn-primary-warning">
                  ecommerce
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* ========================== */}
  {/* FOOTER */}
  {/* ========================== */}
  <footer>
    {/* ========================== */}
    {/* BUY SECTION */}
    {/* ========================== */}
    <section className="buy-section buy-text-section with-icon relative">
      <div className="section-icon">
        <span className="fa fa-twitter" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="section-text text-center wow fadeInUp">
              <div className="buy-text vcenter">
                <div className="top-text">latest from twitter</div>
                <div className="bottom-text">
                  Lorem ipsum dolor sit amet consecteur adipiscing elit aiusmod
                  tempor ncididue ut labore etor dolore magna aliqua enim minim
                  veniam qua nostrud exercitat ullamco laboris nisi ut aliquip
                  ex ea commodo consequat.{" "}
                </div>
                <em>2 minutes ago</em>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* ========================== */}
    {/* FOOTER - SOCIAL */}
    {/* ========================== */}
    {/* <section className="social-section dark dark-strong">
      <div className="container dark-content wow fadeInUp">
        <div className="tt">We are social 24/7 - Get in touch</div>
        <ul className="list-socials">
          <li>
            <a href="#">
              <i className="fa fa-twitter" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-facebook" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-linkedin" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-google-plus" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-dribbble" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-pinterest-p" />
            </a>
          </li>
        </ul>
      </div>
    </section> */}
    {/* ========================== */}
    {/* FOOTER - FOOTER */}
    {/* ========================== */}
    <section className="footer-section">
      <div className="container">
        <div className="row">
          {/* <div className="col-md-3 col-sm-3">
            <h5>about us</h5>
            <p>
              Lorem ipsum dolor sit amet consect etural adipisicing elit sed don
              eiusmod temp enim minim veniam quis notrud exercitation lore ipsum
              dolor sit amet.
            </p>
          </div> */}
          <div className="col-md-3 col-sm-3">
            <h5>Sitemap</h5>
            <div className="row">
              <div className="col-md-6">
                <ul className="footer-nav">
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li>
                    <a href="#">Portfolio</a>
                  </li>
                  <li className="active">
                    <a href="#">Latest News</a>
                  </li>
                  <li>
                    <a href="#">Contact us</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="footer-nav">
                  <li>
                    <a href="#">Get Help</a>
                  </li>
                  <li>
                    <a href="#">Careers</a>
                  </li>
                  <li>
                    <a href="#">FAQ's</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <h5>Contact info</h5>
            <ul className="contacts-list">
              <li>
                <p>
                  <i className="icon icon-House" />
                  68 Landsriver Street, California USA 22036
                </p>
              </li>
              <li>
                <p>
                  <i className="icon icon-Phone2" />
                  0800 126 01268
                </p>
              </li>
              <li>
                <p>
                  <i className="icon icon-Mail" />
                  <a href="mailto:support@oscend.com">
                    support@oscend.com
                  </a>{" "}
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-3">
            <h5>newsletter</h5>
            <p>
              Subscribe to our newsletter for latest updates about our creative
              agency
            </p>
            <div className="form-group has-feedback">
              <input
                type="email"
                className="form-control "
                placeholder="Email address ..."
              />
              <i className="icon icon-Mail form-control-feedback" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="copyright-section">
      <p>
        ©2015 <span>oscend Creative Agency by templines </span>. All Rights
        Reserved
      </p>
    </section>
  </footer>
</>
</>)}

export default Blog;