import React,{useState,useEffect} from "react";
import { FormikProvider, useFormik, Form } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  Button,
  CardContent,
  CardActions,
  Card,
  TextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,Box,Autocomplete
} from "@mui/material";
import { Post, apiURL ,GetAll} from "../../../utils/apiCalls";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import { FileUploader } from 'react-drag-drop-files';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import WifiIcon from '@mui/icons-material/Wifi';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
const AdminSchema = Yup.object().shape({
  project_id: Yup.string().required("Project is required"),
  type: Yup.string().required("Type is required"),
  active: Yup.string().required("Active is required"),
  name: Yup.string().required("Project Name is required"),
});


const OfferEditCreate = () => {
    const [filedata, setFiledata] = useState([]);
    const [fileurl, Setfileurl] = useState('');
    const [error, setError] = useState({ type: "", msg: "" });
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [project_list, setProject_list] = useState([])
    
const fileTypes = ["JPEG", "JPG", "PNG"];
const handleDocuments = async (file) => {

  if(values.project_id){
    const list = [];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('file_name', file.name);
    formData.append('doc_type', "offers");
    formData.append('is_active', '1');
    formData.append('project_id', values.project_id);
    

    const Responsedata = await Post(apiURL + '/api/v1/users/upload/offers', formData)
    if (Responsedata.data.status) {
        Setfileurl(Responsedata.data.data.file_path)
        list.push(Responsedata.data.data)
        toast.success('Uploaded successfully');
        setError({ type: "success", msg: "Uploaded successfully" })
    } else {
        if (Responsedata.data?.status == false && Responsedata.data?.message) {
            toast.error(Responsedata.data?.message)
        }
    }
    setList(list)
    setFiledata(list)
  }
  else{
    setError({ type: "error", msg: "please select project" })
  }
}
const handleTypeError = (error) => {
    setError({ type: "error", msg: "Please upload correct format" })
}

const handleSizeError = (error) => {
    setError({ type: 'error', msg: 'Exceeded maximun upload size' })
}
async function GetList () {
  setLoading(true);
  const response = await GetAll(apiURL + '/api/v1/projects');
       if(response) {
      if (response.data.status) {       
      setLoading(false);
      setProject_list(response.data.data)
      }
      else {
      setLoading(false);
      toast.error(response.data.message)
      setProject_list([])
      }
    }
    else {
      setLoading(false);
    }
};

useEffect(()=> {
  GetList();    
},[])

  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      project_id: "",
      type: "",
      active: "",
      name:""
    },
    validationSchema: AdminSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log(list)
      const response = await Post(apiURL + "/api/v1/offer_banner", {
        ...values,related_id:list[0].id
      });
      if (response && response.data && response.status) {
        toast.success("Offer Created Sucessfully");
        setSubmitting(false);
      } else {
        toast.error(response?.data.message || "Please Try Again");
        setSubmitting(false);
      }
    },
  });
  
  const navigate = useNavigate();
  const {
    errors,
    touched,
    values,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
  } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <Typography align="center" variant="h4">
              CREATE OFFER
            </Typography>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Offer Name"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={4}>
              <Autocomplete fullWidth
                           name='project_id'
                           id='project_id'
                           value={values.project_id ? project_list.find((data) => data.id === values.project_id):''}
                           options={project_list}
                           getOptionLabel={(option) =>
                              option.name ?? option
                           }
                           renderOption={(props, option) => (
                              <Box component='li' sx={{ minWidth: '100px' }} {...props}>
                                 {option.name}
                              </Box>
                           )}
                           renderInput={(params) => (
                              <TextField
                                 error={touched.project_id && Boolean(errors.project_id)}
                                 helperText={touched.project_id && errors.project_id}
                                 {...params}
                                 label='Project '
                              />
                           )}
                           onChange={(e, newvalue, reason) => {
                          
                          
                              setFieldValue('project_id', newvalue ? newvalue?.['id'] : '')
                           }}
                        /> 
              </Grid>
              <Grid item xs={4}>
              <FormControl
                fullWidth
                error={Boolean(touched.active && errors.active)}
              >
                <InputLabel id="active">Active</InputLabel>
                <Select
                  id="active"
                  value={values.active}
                  label="Active"
                  onChange={(e) => setFieldValue("active", e.target.value)}
                  defaultValue="yes"
                >
                  <MenuItem  value={"yes"}>Yes</MenuItem>
                  <MenuItem value={"no"}>No</MenuItem>
                </Select>
                <FormHelperText>
                  {touched.active && errors.active}
                </FormHelperText>
              </FormControl>
              </Grid>
            </Grid>
            <Grid>
            <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                error={Boolean(touched.active && errors.active)}
              >
                <InputLabel id="type">Type</InputLabel>
                <Select
                  id="active"
                  value={values.type}
                  label="Active"
                  onChange={(e) => setFieldValue("type", e.target.value)}
                >
                  <MenuItem value={"Agent"}>Agent</MenuItem>
                  <MenuItem value={"Customer"}>Customer</MenuItem>
                </Select>
                <FormHelperText>
                  {touched.type && errors.type}
                </FormHelperText>
              </FormControl>
              </Grid>
              <Grid item xs={8}>

                <Grid>
              <Typography >
            Banner Image
            </Typography>
                                        <FileUploader label="Upload or drop a file Accepts 2MB"
                                            types={fileTypes}
                                            maxSize={5}
                                            handleChange={handleDocuments}
                                            name="file" hoverTitle=" "
                                            onTypeError={handleTypeError}
                                            onSizeError={handleSizeError}
                                            children={
                                                <div     
                                                        style={{
                                                        height: '180px',
                                                        cursor: 'pointer',
                                                        fontSize: '0.9rem !important',
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        padding: '29px',
                                                        border: '2px solid #FED8B1',
                                                        borderRadius: '5px',
                                                        color: error.type === 'error' ? 'red' : 'inherit',
                                                        marginTop: '16px',
                                                        backgroundColor: '#04cee512',       
                                                    }}
                                                >
                                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.33317 6.66667H22.6665V16H25.3332V6.66667C25.3332 5.196 24.1372 4 22.6665 4H5.33317C3.8625 4 2.6665 5.196 2.6665 6.66667V22.6667C2.6665 24.1373 3.8625 25.3333 5.33317 25.3333H15.9998V22.6667H5.33317V6.66667Z" fill="#0658C2"></path><path d="M10.6665 14.6667L6.6665 20H21.3332L15.9998 12L11.9998 17.3333L10.6665 14.6667Z" fill="#0658C2"></path><path d="M25.3332 18.6667H22.6665V22.6667H18.6665V25.3333H22.6665V29.3333H25.3332V25.3333H29.3332V22.6667H25.3332V18.6667Z" fill="#0658C2"></path></svg>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1, alignItems: 'center' }}>
                                                        {error.msg ?
                                                            <span style={{ fontSize: '0.9rem !important' }}>
                                                                {error.msg}
                                                            </span>
                                                            :
                                                            <span style={{ fontSize: '0.9rem !important' }}>
                                                                Upload or drop a file. Accepts 5MB
                                                              
                                                            </span>
                                                        }&nbsp;

                                                    </div>
                                                </div>
                                            }
                                        />
                                        <div style={{  fontWeight: 400,
fontSize: '11px',
  marginTop: '10px',
  color: '#5a77a6'}}>
                                        Please ensure the file size is less than 5MB
                                        </div>

                                        </Grid>
                                        <Grid>

                                        <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}    >
      {list.length>0 && list.map((data)=>{
        return(
      <ListItem>
      <ListItemText id={'dd'} primary={data?.file_name} />
        <ListItemIcon>
          <a href={data?.filepaths3}>

         
          <OpenInNewIcon />
          </a>
        </ListItemIcon>
      </ListItem>)})
}

    </List>
                                        </Grid>
                                        
                                        
                                        </Grid>
                                        
                                    </Grid>
            </Grid>
          </CardContent>
          <CardActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              onClick={() =>{ navigate(-1)}}
              variant="contained"
              sx={{
                backgroundColor: "#3f51b5",
                "&:hover": { color: "#ffff", backgroundColor: "#3f51b5" },
              }}
            >
              Back
            </Button>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{
                backgroundColor: "#3f51b5",
                "&:hover": { color: "#ffff", backgroundColor: "#3f51b5" },
              }}
            >
              Save{" "}
            </LoadingButton>
          </CardActions>
        </Card>
      </Form>
    </FormikProvider>
  );
};

export default OfferEditCreate;
