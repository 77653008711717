import React, { useEffect } from 'react';
import WOW from 'wowjs';


const Layoutvideo =() => {
    useEffect(() => {
        const wow = new WOW.WOW({
            live: false
        });
        wow.init();
    }, []);


    return (
        <>        
        <div class="Layout-video-play">
            <p class="section-title">Layout Video</p>
            <video id="video-play" controls style={{width: "100%"}}>
                    <source src={require("../videos/perfectlayoutvideo1.mp4")} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
        </div>
        </>
    )
}

export default Layoutvideo;