import { Container, Stack, Button, Typography,TableContainer, Table, TableBody, TableRow, TableCell, IconButton, TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Iconify from '../../../layouts/Iconify';
import Scrollbar from '../../../layouts/Scrollbar';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../layouts/user';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import MiscSkeleton from '../../../layouts/user/MiscSkeleton';
import { GetAll, apiURL } from '../../../utils/apiCalls';
import { filter } from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import Label from '../../../layouts/Label';
import { capitalCase } from 'change-case';
import SearchNotFound from '../../../layouts/SearchNotFound';
import { useParams } from 'react-router-dom';
import ImageList from "@mui/material/ImageList";

import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import ImageListItem from "@mui/material/ImageListItem";
import '../../../sass/custom.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// ----------------------------------------------------------------------

const CustomerofferImages = () => {
  
    const { id } = useParams();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    async function GetList () {
        setLoading(true);
        const response = await GetAll(apiURL + '/api/v1/offer_banner/'+id);
             if(response) {
            if (response.data.status) {       
            setLoading(false);
           let data= response?.data?.data.filter((data) => data.type == 'Customer')
            setList(data)
            }
            else {
            setLoading(false);
            toast.error(response.data.message)
              setList([])
            }
          }
          else {
            setLoading(false);
          }
    };
    
      useEffect(()=> {
        GetList();    
      },[])
    

    const navigate = useNavigate();
    return (
        <Container sx={{m:0, width:'100%', maxWidth:'100%'}} maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>

          <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={()=>{navigate(-1)}}>
            Back
          </Button>
          <Typography variant="h4" gutterBottom style={{marginBottom:0,paddingTop:'3px',marginRight:'20px'}}>
            Customer Offers
          </Typography>
          <Typography variant="h4" gutterBottom>
            {/* Customer Offers */}
          </Typography>
        </Stack>
        {/* <ImageList sx={{ width: "100%", height: "100%" }} rowHeight={164}> */}
      {
      list.length>0?
      list.map((item) => (
        <div>
        {item.type==='Customer'?
                <Card sx={{padding:0,marginBottom:'20px',borderRadius:'15px'}}>
      
        <ImageListItem key={item?.docs?.filepaths3}>
            <a href={item?.docs?.filepaths3} >
          <img
            srcSet={item?.docs?.filepaths3}
            src={item?.docs?.filepaths3}
            alt={item?.docs?.file_name}
            loading="lazy"
            // style={{ height: '500px',width: '100%',borderRadius:'15px'}}
            className='offerimage'
          />
          </a>
        </ImageListItem>
        
        </Card>:null}
        </div>
      )) :<div style={{textAlign:'center', fontSize: '24px' }}>
        
        No Offers
        </div>}
    {/* </ImageList> */}
       
      
      </Container>
    )
}

export default CustomerofferImages;