import React, { useState, useEffect } from "react";
import { FormikProvider, useFormik, Form } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  Button,
  CardContent,
  CardActions,
  Card,
  TextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Box,
  OutlinedInput,
  Chip,
  Stack,
} from "@mui/material";
import { Post, apiURL, GetAll, Put, Get } from "../../../utils/apiCalls";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
const CommissionView = () => {
  const [loading, setLoading] = useState(false);
  const [datacheck, setDatacheck] = useState([]);
  const [load, setLoad] = useState(false);
  let account = JSON.parse(sessionStorage.getItem("user_details"));

  const AdminSchema =
    account.user_type == "admin"
      ? Yup.object().shape({
          plot: Yup.array()
            .required("Plot is required")
            .min(1, "Plot is required"),
          agent: Yup.string().required("Agent is required"),
        })
      : Yup.object().shape({
          plot: Yup.array()
            .required("Plot is required")
            .min(1, "Plot is required"),
          agent: Yup.string().when("commission_type", {
            is: (value) => value === "other_agent",
            then: Yup.string().required("Agent is required"),
          }),
        });

  const navigate = useNavigate();
  const [project_list, setProject_list] = useState({
    name: "",
    commission_value: "",
    commission_type: "",
    unit: "",
  });
  const [plot_list, setPlot_list] = useState([]);
  const [users, setUsers] = useState([]);
  const { id } = useParams();

  async function GetList() {
    setLoading(true);
    const response = await Get(apiURL + "/api/v1/projects/", id);
    const plot = await Get(apiURL + "/api/v1/plots/", id);
    const users =
      account.user_type == "admin"
        ? await GetAll(apiURL + "/api/v1/users/agent")
        : await GetAll(apiURL + "/api/v1/users/get_agent_chain_asc");
    if (response && plot && users) {
      if (response.data.status) {
        setLoading(false);
        setProject_list(response.data.data);
        setPlot_list(plot.data.data);
        setUsers(users.data.data);
      } else {
        setLoading(false);
        toast.error(response.data.message);
        setProject_list({});
      }
    } else {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (id) {
      GetList();
    }
  }, [id]);

  const formik = useFormik({
    initialValues: {
      plot: [],
      agent: "",
      commission_check: "myself",
    },
    validationSchema: AdminSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoad(true);
      const response = await Post(apiURL + "/api/v1/commision/agent", {
        ...values,
        project_id: id,
      });
      if (response && response.data && response.status) {
        setSubmitting(false);
        setDatacheck(response.data.data);
        setLoad(false);
      } else {
        toast.error(response?.data.message || "Please Try Again");
        setSubmitting(false);
        setLoad(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <Typography align="center" variant="h4">
              AGENT COMMISSION CALCULATOR {project_list?.name?.toUpperCase()}
            </Typography>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              {account.user_type != "admin" ? (
                <Grid item xs={12} md={4}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Commission Check{" "}
                      <Tooltip
                        title="1. My Self : To check how you will get a commission.
2. My Agent: If you want to check how much you'll get if your agent sells plots"
                        placement="top-end"
                      >
                        <Button
                          style={{ minWidth: "2px", padding: "1px" }}
                          variant=""
                          startIcon={<InfoIcon style={{ fontSize: 18 }} />}
                        ></Button>
                      </Tooltip>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="commission_check"
                      defaultValue="myself"
                      value={values.commission_check}
                      onChange={(e) =>
                        setFieldValue("commission_check", e.target.value)
                      }
                    >
                      <FormControlLabel
                        value="myself"
                        control={<Radio />}
                        label="My Self"
                      />
                      <FormControlLabel
                        value="other_agent"
                        control={<Radio />}
                        label="My Agents"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              ) : null}
            </Grid>

            <Grid container spacing={2} sx={{ mt: 0 }}>
            <Grid item xs={12} md={4}>
      <FormControl fullWidth error={Boolean(touched.plot && errors.plot)}>
        <InputLabel>Plot (sqft)</InputLabel>
  
        <Select
          multiple
          value={values.plot}
          onChange={(e) => setFieldValue("plot", e.target.value)}
          input={<OutlinedInput label="Multiple Select" />}
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((id) => {
                const plot = plot_list.find((item) => item.id === id);
                return (
                  <Chip
                    key={id}
                    label={plot.plot_no}
                    onDelete={() =>
                      setFieldValue(
                        "plot",
                        values.plot.filter((item) => item !== id)
                      )
                    }
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                  />
                );
              })}
            </Stack>
          )}
        >
          {plot_list.map((plot) => (
            <MenuItem
              key={plot.id}
              value={plot.id}
              sx={{ justifyContent: "space-between" }}
            >
              {plot.plot_no + " " + "(" + plot.plot_area + ")"}
              {values.plot.includes(plot.id) ? <CheckIcon color="info" /> : null}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText style={{ color: "red" }}>
          {touched.plot && errors.plot}
        </FormHelperText>
      </FormControl>
    </Grid>
              {account.user_type == "admin" ||
              values.commission_check == "other_agent" ? (
                <Grid item xs={12} md={4}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.agent && errors.agent)}
                  >
                    <InputLabel id="Agent">Agent</InputLabel>
                    <Select
                      id="agent"
                      value={values.agent}
                      label="Type"
                      onChange={(e) => setFieldValue("agent", e.target.value)}
                      defaultValue="P"
                    >
                      {users.map((name) => (
                        <MenuItem key={name.id} value={name.id}>
                          {name.first_name + " " + "(" + name.emp_id + ")"}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched.agent && errors.agent}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item xs={12} md={4}>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  sx={{
                    backgroundColor: "#3f51b5",
                    "&:hover": { color: "#ffff", backgroundColor: "#3f51b5" },
                  }}
                >
                  Check{" "}
                </LoadingButton>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{ mt: 0 }}
              style={{ overflowX: "auto", overflowY: "auto", height: "auto" }}
            >
              {load ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              ) : (
                datacheck?.map((data) => (
                  <Grid xs={12} md={4} style={{ padding: "16px" }}>
                    <Card elevation={2}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }}>
                          Agent Name :{" "}
                          {data?.first_name + " " + data?.last_name}
                        </Typography>
                        <Typography sx={{ fontSize: 14 }}>
                          Agent ID: {data?.emp_id}
                        </Typography>
                        <Typography sx={{ fontSize: 14 }}>
                          Designation : {data?.role_name}
                        </Typography>
                        <Typography sx={{ fontSize: 14 }}>
                          Commission : {"₹" + " " + data?.amount?.toFixed(2)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              )}
            </Grid>
          </CardContent>

          <CardActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              onClick={(e) => navigate(-1)}
              variant="contained"
              sx={{
                backgroundColor: "#3f51b5",
                "&:hover": { color: "#ffff", backgroundColor: "#3f51b5" },
              }}
            >
              Back
            </Button>
          </CardActions>
        </Card>
      </Form>
    </FormikProvider>
  );
};

export default CommissionView;
