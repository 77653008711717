import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import WOW from 'wowjs';
import 'animate.css/animate.css';
import Router from './router';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function App() {
  useEffect(() => {
    const wow = new WOW.WOW({
        live: false
    });
    wow.init();
}, []);

  return (
    <>
    <ToastContainer />
    <Router />
    </>
  );
}

export default App;
