import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik, Form } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Paper, Button, CardContent, CardActions, Card, TextField, Box, Stack, FormControl, FormHelperText, MenuItem, Select, InputLabel, Autocomplete, Grid, Divider, Typography, TableRow, TableCell, IconButton, TablePagination } from '@mui/material';
import { Post, apiURL, Get, Put } from '../../../utils/apiCalls';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Base64File } from '../../../utils/Base64File';

const ChangePasswordSchema = Yup.object().shape({
   oldPassword: Yup.string().required('Old Password is required'),
   password: Yup.string().required('New Password is required').min(6, 'New Password must be at least 6 characters')
   .matches(
     /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]+$/,
     'New Password must contain at least one capital letter, one symbol, and one number'
   ),
   confirmPassword: Yup.string().required('Confirm Password is required').when(['password'], {
       is: (password) => true,
       then: () => Yup.string().test('is-equal', 'Passwords does not Match',
       function (confirmPassword) {
           const Newpassword = this.resolve(Yup.ref('password'));
           return confirmPassword === Newpassword;
           }),
       otherwise: Yup.string().notRequired()
   }),
});
  
const ChangePassword = () => {
    const navigate = useNavigate();
 
    const formik = useFormik({
       initialValues: {
        confirmPassword:'',
        password: '',
        oldPassword:''
       },
       validationSchema: ChangePasswordSchema,
       onSubmit: async (values, { setSubmitting }) => {
          const response = await Post(apiURL + '/api/v1/users/admin/changepassword', { ...values })
          if (response && response.data && response.data.status) {
             toast.success('Password Updated Sucessfully')
             setSubmitting(false)
             navigate('/dashboard/projectadmin')
          }
          else {
            toast.error(response?.data?.message || 'Please Try Again')
            setSubmitting(false)
          }
       }
    });
 
    const { errors, touched, values, setFieldValue, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
   //  console.log(values)
   //  console.log(errors)
 
    return (
<FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
           <Card>
              <CardContent>
                 <Typography align='center' variant='h4'>My Profile</Typography>
                 <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                    <TextField fullWidth label="Old Password" {...getFieldProps('oldPassword')} error={Boolean(touched.oldPassword && errors.oldPassword)} helperText={touched.oldPassword && errors.oldPassword} />
                    </Grid>
                    <Grid item xs={6}>
                    <TextField fullWidth label="New Password" {...getFieldProps('password')} error={Boolean(touched.password && errors.password)} helperText={touched.password && errors.password} />
                    </Grid>

                    <Grid item xs={6}>
                       <TextField fullWidth label="Confirm Password" {...getFieldProps('confirmPassword')} error={Boolean(touched.confirmPassword && errors.confirmPassword)} helperText={touched.confirmPassword && errors.confirmPassword} />
                    </Grid></Grid>
                
              </CardContent>

              <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                 <Button href="#/dashboard/projectadmin" variant='contained' sx={{ backgroundColor: '#3f51b5', '&:hover': { color: '#ffff', backgroundColor: '#3f51b5' } }}>Back</Button>
                 <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ backgroundColor: '#3f51b5', '&:hover': { color: '#ffff', backgroundColor: '#3f51b5' } }}>Save </LoadingButton>
              </CardActions>
           </Card>
        </Form>
     </FormikProvider> 
    )
}

export default ChangePassword;