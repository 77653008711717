import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Grid, Paper, Avatar, Box, Button, CssBaseline, TextField, Typography, Stack, Tabs, Tab, InputAdornment, IconButton } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "animate.css/animate.css";
import react, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import WOW from "wowjs";
import { apiURL, Post } from "../../utils/apiCalls";
import { FormikProvider, useFormik, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios, { AxiosError } from 'axios';

const defaultTheme = createTheme();
const rnd = ["sections/home-top-background.jpg", "hand.jpg", "home1.jpg", "area.png"]
const rnd_no = Math.floor(Math.random() * rnd.length)

const ResetPasswordScheme = Yup.object({
  reenter_password: Yup.string().required('Re-enter M-Pin is required').test('is-equal', 'Re-Enter M-Pin is not equal',
  async function (reenter_password) {
       const Passsword = this.resolve(Yup.ref('password'));
       return reenter_password === Passsword;
   }),
  password: Yup.string().required('M-Pin is required').matches(/^[0-9]{4}$/, 'Invalid M-Pin'),
});

export default function ResetPassword() {
  const navigate = useNavigate();
const [showPassword, setShowPassword] = useState({'reenter_password': false, 'password': false});


  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  const handleClickShowPassword = (val) => setShowPassword((prev)=> ({...prev, [val] : !showPassword[val]}));

const handleMouseDownPassword = (event) => {
  event.preventDefault();
};

  const handleResetPasswordLogin = async (values, { setSubmitting }) => {
    setSubmitting(true)
    try {
      const response =  await axios({ method: 'POST', url: apiURL+ '/api/v1/users/reset_password', data: {...values}, headers: {'Authorization': 'Barer ' + sessionStorage.getItem('otp_token')} })
      if(response && response.data && response.data.status)  {
        toast.success('M-Pin Reset Successful')
        navigate("/login");
        setSubmitting(false)
        sessionStorage.removeItem('otp_token')
      }
      else {
        toast.error(response?.data?.message)
        setSubmitting(false)
      }
  }
  catch (error) {
    console.log(error)
    toast.error('Please Try Again')
    setSubmitting(false)
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
     
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid  item xs={false} sm={4} md={7}
          sx={{
            backgroundImage:
              `url(${require(`../../img/${rnd[rnd_no]}`)})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset M-Pin
            </Typography>

            <Box sx={{ p: 3 }}>
         
      <Box sx={{ mt: 1 }}>
      <Formik validationSchema={ResetPasswordScheme} onSubmit={handleResetPasswordLogin} initialValues={{ reenter_password: "", password: "" }} >
       {({ handleSubmit, handleChange, values, errors, getFieldProps, touched }) => (
        <form onSubmit={handleSubmit}>
             <TextField  margin="normal"
                // id="password"
                type={showPassword['password'] ? 'text' : 'password'}
                placeholder="New M-Pin"
                label=""
                fullWidth
                variant="outlined"
                // onChange={(e) => handleChangeData(e, 'agent')}
                // value={values['agent']['password']}
                {...getFieldProps('password')}
                error={touched?.password && Boolean(errors?.password)}
                helperText={touched?.password && errors?.password || 'Enter 4 digit M-Pin'}
                InputProps={{
                endAdornment: 
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>handleClickShowPassword('password')}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword['password'] ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }}
              />

              <TextField  margin="normal"
                type={showPassword['reenter_password'] ? 'text' : 'password'}
                placeholder="Re-enter M-Pin"
                label=""
                fullWidth
                variant="outlined"             
                {...getFieldProps('reenter_password')}
                error={touched?.reenter_password && Boolean(errors?.reenter_password)}
                helperText={touched?.reenter_password && errors?.reenter_password || 'Enter 4 digit M-Pin'}
                InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>handleClickShowPassword('reenter_password')}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword['reenter_password'] ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }}
              />

              <Stack spacing={2} direction={'row'} sx={{mt: 2}}>
              <Button type="submit" fullWidth variant="contained"  sx={{ mt: 3, mb: 2 }} >Save</Button>
              </Stack>
              </form>
               )}
               </Formik>
            </Box>

      
          </Box></Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
