import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik, Form } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Paper, Button, CardContent, CardActions, Card, TextField, Box, Stack, FormControl, FormControlLabel, Checkbox, FormHelperText, MenuItem, Select, InputLabel, Autocomplete, Grid, Divider, Typography, TableRow, TableCell, IconButton, TablePagination } from '@mui/material';
import { Post, apiURL, Get } from '../../../utils/apiCalls';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Base64File } from '../../../utils/Base64File';
import {dataURItoBlob } from '../../../utils/Base64File';
import LaunchIcon from '@mui/icons-material/Launch';

const AgentSchema = Yup.object().shape({
   first_name: Yup.string().required('First Name is required'),
   last_name: Yup.string().required('Last Name is required'),
   email: Yup.string().email('Invalid Email').required('Email is required'),
   password: Yup.string().required('M-Pin is required').matches(/^[0-9]{4}$/, 'Invalid M-Pin'),
   // .min(6, 'M-Pin must be at least 6 characters')
   //    .matches(
   //       /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]+$/,
   //       'M-Pin must contain at least one capital letter, one symbol, and one number'
   //    ),
   phone: Yup.string().matches(/^[6-9]\d{9}$/, 'Please Enter valid Phone Number').required('Phone Number is required'),
   aadhar_number: Yup.string().matches(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/, 'Invalid Aadhar number').required('Aadhar Number is required'),
   pan_number: Yup.string().matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, 'Invalid PAN number').required('Pan Number is required'),
   //  aadhar_upload: Yup.string().required('Aadhar Upload is required'),
   //  pan_upload: Yup.string().required('Pan Upload is required'),
   address: Yup.string().required('Personal Address is required'),
   city: Yup.string().required('City is required'),
   state: Yup.string().required('State is required'),
   country: Yup.string().required('Country is required'),
   pincode: Yup.string().required('Pincode is required'),
   // bank_name: Yup.string().required('Bank Name is required'),
   // account_number: Yup.string().required('Account Number is required'),
   // bank_ifsc_code: Yup.string().required('IFSC Number is required'),
   // bank_address: Yup.string().required('Bank Address is required'),
   role: Yup.object().shape({ id: Yup.string().required('Role is required') }),
   active: Yup.string().required('Active is required'),
});

const AgentEditCreate = () => {
   const { id } = useParams()
   // console.log(id)
   const navigate = useNavigate();
   const [roles, setRoles] = useState([]);
   const [checked, setChecked] = useState(false);
   const [upload, setUpload] = useState({aadhar_upload:'', pan_upload:''});

   const Roles = async () => {
      const response = await Get(apiURL + '/api/v1/users/getRoleByuser', '')
      if (response && response.data) {
         setRoles(response.data.data)
      }
   }

   useEffect(() => {
      Roles()
   }, [])

   const formik = useFormik({
      initialValues: {
         first_name: '',
         last_name: '',
         password: '',
         email: '',
         phone: '',
         aadhar_number: '',
         pan_number: '',
         aadhar_upload: {},
         pan_upload: {},
         address: '',
         bank_name: '',
         account_number: '',
         bank_ifsc_code: '',
         bank_address: '',
         role: { id: '' },
         city:'',
         state:'',
         country:'',
         pincode:'',
         nominee_relation:'',
         nominee_age:'',
         nominee_name:'',
         active: 'yes',
      },
      validationSchema: AgentSchema,
      onSubmit: async (values, { setSubmitting }) => {
         //   console.log(values)
         const response = await Post(apiURL + '/api/v1/users/create/agent', { ...values })
         if (response && response.data && response.data.status) {
            toast.success(response.data.message)
            setSubmitting(false)
            navigate('/dashboard/agentUser')
         }
         else {
            toast.error(response?.data.message || 'Please Try Again')
            setSubmitting(false)
         }
      }
   });

   const { errors, touched, values, setFieldValue, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
   // console.log(values)
   // console.log(errors)

   const handleDocUpload = async(id, file) => {
      const data = {file: id==='aadhar_upload'? 'AAdhar Card': 'Pan Card', data: file}
      const response = await Post(apiURL+ '/api/v1/users/uploaddoc', {...data}, navigate)
      if(response && response.data && response.data.status) {
      setUpload((prev)=> ({...prev, [id]: response?.data?.data?.data}))
      toast.success(`${data.file} Uploaded Successfully`)
      setFieldValue(id, response?.data?.data?.id)
      }
      else {
         toast.error(response?.data?.message || 'Please Try Again')
      }
   }

   const handleFiles = async (e) => {
      const { id, files } = e.target
      if ((files[0]['size'] / 1024) <= 2000) {
         const base64 = await Base64File(files[0])
         const file = { name: files[0]['name'], size: files[0]['size'], base64: base64 }
         handleDocUpload(id, file)
      }
      else {
         toast.error('Please Upload File less than 2 MB')
      }
   }

   const handleChangeCheck = (e) => {
      setChecked(e.target.checked)
   }

   return (
      <FormikProvider value={formik}>
         <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Card>
               <CardContent>
                  <Typography align='center' variant='h4'>CREATE AGENTS</Typography>
                  <Grid container spacing={2} sx={{ mt: 0 }}>
                     <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                        <TextField fullWidth label="First Name" {...getFieldProps('first_name')} error={Boolean(touched.first_name && errors.first_name)} helperText={touched.first_name && errors.first_name} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                        <TextField fullWidth label="Last Name" {...getFieldProps('last_name')} error={Boolean(touched.last_name && errors.last_name)} helperText={touched.last_name && errors.last_name} />
                     </Grid></Grid>

                  <Grid container spacing={2} sx={{ mt: 0 }}>
                     <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                        <TextField fullWidth label="Email Address" {...getFieldProps('email')} error={Boolean(touched.email && errors.email)} helperText={touched.email && errors.email} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                        <TextField fullWidth label="M-Pin" {...getFieldProps('password')} error={Boolean(touched.password && errors.password)} helperText={touched.password && errors.password || 'Enter 4 digit M-Pin'} />
                     </Grid></Grid>

                  <Divider />
                  <Grid container spacing={2} sx={{ mt: 0 }}>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        <TextField fullWidth label="Phone Number" {...getFieldProps('phone')} error={Boolean(touched.phone && errors.phone)} helperText={touched.phone && errors.phone} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        <TextField fullWidth label="Aadhar Number" {...getFieldProps('aadhar_number')} error={Boolean(touched.aadhar_number && errors.aadhar_number)} helperText={touched.aadhar_number && errors.aadhar_number} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        <TextField fullWidth label="Pan Number" {...getFieldProps('pan_number')} error={Boolean(touched.pan_number && errors.pan_number)} helperText={touched.pan_number && errors.pan_number} />
                     </Grid></Grid>

                  <Grid container spacing={2} sx={{ mt: 0 }}>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        {/* <TextField fullWidth  label="Company Name" {...getFieldProps('company_name')} error={Boolean(touched.company_name && errors.company_name)} helperText={touched.company_name && errors.company_name} /> */}
                        <Stack spacing={2}>
                           <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} >
                              Upload Aadhar
                              <VisuallyHiddenInput id='aadhar_upload' type="file" onChange={(e) => handleFiles(e)} />
                           </Button>
                           <Stack direction={'row'} spacing={1}>
                           <Typography sx={{display:'flex', alignItems:'center'}}>{upload.aadhar_upload?.['name'] ? upload.aadhar_upload?.['name'] : "No File Choosen"}</Typography>
                           {upload.aadhar_upload?.['name'] ? <IconButton component={'a'} href={dataURItoBlob(upload.aadhar_upload?.['base64'])} target='_blank'><LaunchIcon /></IconButton>:null}
                           </Stack>
                        </Stack>
                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        {/* <TextField fullWidth label="Company Address" {...getFieldProps('address')} error={Boolean(touched.address && errors.address)} helperText={touched.address && errors.address} /> */}
                        <Stack spacing={2}>
                           <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} >
                              Upload Pan Card
                              <VisuallyHiddenInput id='pan_upload' type="file" onChange={(e) => handleFiles(e)} />
                           </Button>
                           <Stack direction={'row'} spacing={1}>
                           <Typography sx={{display:'flex', alignItems:'center'}}>{upload.pan_upload?.['name'] ? upload.pan_upload?.['name'] : "No File Choosen"}</Typography>
                          {upload.pan_upload?.['name'] ? <IconButton component={'a'} href={dataURItoBlob(upload.pan_upload?.['base64'])} target='_blank'><LaunchIcon /></IconButton>:null}
                           </Stack>
                        </Stack>
                     </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mt: 0 }}>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        <TextField fullWidth label="Personal Address" {...getFieldProps('address')} error={Boolean(touched.address && errors.address)} helperText={touched.address && errors.address} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        <TextField fullWidth label="City" {...getFieldProps('city')} error={Boolean(touched.city && errors.city)} helperText={touched.city && errors.city} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        <TextField fullWidth label="State" {...getFieldProps('state')} error={Boolean(touched.state && errors.state)} helperText={touched.state && errors.state} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        <TextField fullWidth label="Country" {...getFieldProps('country')} error={Boolean(touched.country && errors.country)} helperText={touched.country && errors.country} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        <TextField fullWidth label="Pincode" {...getFieldProps('pincode')} error={Boolean(touched.pincode && errors.pincode)} helperText={touched.pincode && errors.pincode} />
                     </Grid></Grid>

                  <Grid container spacing={2} sx={{ mt: 0 }}>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        <TextField fullWidth label="Bank Name" {...getFieldProps('bank_name')} error={Boolean(touched.bank_name && errors.bank_name)} helperText={touched.bank_name && errors.bank_name} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        <TextField fullWidth label="Account Number" {...getFieldProps('account_number')} error={Boolean(touched.account_number && errors.account_number)} helperText={touched.account_number && errors.account_number} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        <TextField fullWidth label="IFSC Code" {...getFieldProps('bank_ifsc_code')} error={Boolean(touched.bank_ifsc_code && errors.bank_ifsc_code)} helperText={touched.bank_ifsc_code && errors.bank_ifsc_code} />
                     </Grid></Grid>

                  <Grid container spacing={2} sx={{ mt: 0 }}>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        <TextField fullWidth label="Bank Address" {...getFieldProps('bank_address')} error={Boolean(touched.bank_address && errors.bank_address)} helperText={touched.bank_address && errors.bank_address} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        {/* <TextField fullWidth label="Postion or Role" {...getFieldProps('role')} error={Boolean(touched.role && errors.role)} helperText={touched.role && errors.role} /> */}
                        <Autocomplete fullWidth
                           name='role.id'
                           id='role.id'
                           value={values.role['id'] ? roles.find((data) => data.id === values.role['id']) : ''}
                           options={roles}
                           getOptionLabel={(option) =>
                              option.designation ?? option
                           }
                           renderOption={(props, option) => (
                              <Box component='li' sx={{ minWidth: '100px' }} {...props}>
                                 {option.designation}
                              </Box>
                           )}
                           renderInput={(params) => (
                              <TextField
                                 error={touched.role?.id && Boolean(errors.role?.id)}
                                 helperText={touched.role?.id && errors.role?.id}
                                 {...params}
                                 label='Position or Role'
                              />
                           )}
                           onChange={(e, newvalue, reason) => {
                              setFieldValue('role.id', newvalue ? newvalue?.['id'] : '')
                           }}
                        />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        {/* <TextField fullWidth label="Active" {...getFieldProps('active')} error={Boolean(touched.active && errors.active)} helperText={touched.active && errors.active} /> */}
                        <FormControl fullWidth error={Boolean(touched.active && errors.active)}>
                           <InputLabel id="active">Active</InputLabel>
                           <Select
                              id="active"
                              value={values.active}
                              label="Active"
                              onChange={(e) => setFieldValue('active', e.target.value)}
                           >
                              <MenuItem value={'yes'}>Yes</MenuItem>
                              <MenuItem value={'no'}>No</MenuItem>
                           </Select>
                           <FormHelperText>{touched.active && errors.active}</FormHelperText>
                        </FormControl>

                     </Grid></Grid>

                     <Grid container spacing={2} sx={{ mt: 0 }}>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        <TextField fullWidth label="Nominee Name" {...getFieldProps('nominee_name')} error={Boolean(touched.nominee_name && errors.nominee_name)} helperText={touched.nominee_name && errors.nominee_name} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        <TextField type='number' fullWidth label="Nominee Age" InputProps={{ inputProps: { min: 0, max: 100 } }} {...getFieldProps('nominee_age')} error={Boolean(touched.nominee_age && errors.nominee_age)} helperText={touched.nominee_age && errors.nominee_age} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={6} xl={4}>
                        <TextField fullWidth label="Nominee Relation" {...getFieldProps('nominee_relation')} error={Boolean(touched.nominee_relation && errors.nominee_relation)} helperText={touched.nominee_relation && errors.nominee_relation} />
                     </Grid></Grid>
               </CardContent>
               
               <Stack ml={'20px'} spacing={1} direction={'row'}>
               <FormControlLabel
          value="end"
          control={<Checkbox  checked={checked}
          onChange={handleChangeCheck} />}
          label=""
          labelPlacement="end"
        />
        <Typography align='center' justifyContent={'center'} display={'flex'} alignItems={'center'}>I Accept the <a href={require('../../../img/terms and conditions.pdf')} target="_blank" style={{cursor:'pointer', marginLeft:'5px'}}>Terms and Conditions</a></Typography>
               </Stack>
               <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button href="#/dashboard/agentUser" variant='contained' sx={{ backgroundColor: '#3f51b5', '&:hover': { color: '#ffff', backgroundColor: '#3f51b5' } }}>Back</Button>
                  <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting} disabled={!checked} sx={{ backgroundColor: '#3f51b5', '&:hover': { color: '#ffff', backgroundColor: '#3f51b5' } }}>Save </LoadingButton>
               </CardActions>
            </Card>
         </Form>
      </FormikProvider>
   )
};

export default AgentEditCreate;

const VisuallyHiddenInput = styled('input')({
   clip: 'rect(0 0 0 0)',
   clipPath: 'inset(50%)',
   height: 1,
   overflow: 'hidden',
   position: 'absolute',
   bottom: 0,
   left: 0,
   whiteSpace: 'nowrap',
   width: 1,
});