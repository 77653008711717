
import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css/animate.css';
import './css/Project.css'
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem, ListItemIcon, ListItemText, Stack, Card, CardMedia, CardContent, Grid, Button, CardActions, Paper, TableHead, Container, Typography, IconButton } from '@mui/material';
import { apiURL, GetAll } from '../utils/apiCalls';
import Iconify from '../js/Iconify';
import {
    WhatsappShareButton
  } from "react-share";
  import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
  import PersonIcon from '@mui/icons-material/Person';

const ProjectsAgent = () => {
const [data, setData] = useState([]);

const getProjects = async () => {
        const response = await GetAll(apiURL + '/api/v1/projects')
        if(response && response.data && response.data.status) {
            setData(response.data.data)
        }
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {
        const wow = new WOW.WOW({
            live: false
        });
        wow.init();
        getProjects()
    }, []);

      const handleShare = (e) => {

      }

return (
    <>
        {/* // <!-- ========================== -->
        // <!-- Navigation -->
        // <!-- ========================== --> */}
        <header className="header scrolling-header">
            <nav id="nav" className="navbar navbar-default navbar-fixed-top" role="navigation">
                <div className="container relative-nav-container">
                    <a className="toggle-button visible-xs-block" data-toggle="collapse" data-target="#navbar-collapse">
                        <i className="fa fa-navicon"></i>
                    </a>
                    <a className="navbar-brand scroll" href="home.html" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <img className="normal-logo hidden-xs" src={require("../img/logo.png")} alt="logo" />
                        <img className="scroll-logo hidden-xs" src={require("../img/logo-dark.png")} alt="logo" />
                        <img className="scroll-logo visible-xs-block" src={require("../img/logo.png")} alt="logo" />
                    </a>
                   
                    <div className="navbar-collapse collapse floated" id="navbar-collapse">
                        <ul className="nav navbar-nav navbar-with-inside clearfix navbar-right with-border"> 
                            <li>
                                <a href="#/home">home</a>                            
                            </li>
                            
                            <li>
                                <a href="#/about">about</a>
                            </li>
                            <li className="active">
                                {/* <a href="#/project">Our Projects</a> */}
                                <Button
        aria-haspopup="true"
        className='menuButton'
        onClick={handleClick}
      >
        Our Projects
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ '.MuiMenu-paper' : {minWidth:'110px'} }}
      >
        <MenuItem component={'a'} href='#/project' onClose={handleClose}>
            <ListItemIcon >
            <AdminPanelSettingsIcon sx={{width:'1.5em', height:'1.5em'}} />
          </ListItemIcon>
          <ListItemText>Admin</ListItemText>
            </MenuItem>
        <MenuItem component={'a'} href='#/login' onClose={handleClose} target='_blank'>           
            <ListItemIcon>
            <PersonIcon sx={{width:'1.5em', height:'1.5em'}} />
          </ListItemIcon>
          <ListItemText>ASSOCIATE</ListItemText>
            </MenuItem>
      </Menu>
                            </li>
                            <li><a href="#/services">services</a></li>
                            {/* <li >
                                <a href="#/project">Blog</a>
                               
                            </li> */}
                            <li>
                               <a  href="#/contact">Contacts</a>                               
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>

        {/* <!-- ========================== -->
        <!-- BLOG - HEADER -->
        <!-- ========================== --> */}
        <section className="top-header blog-header with-bottom-effect transparent-effect dark dark-strong">
            <div className="bottom-effect"></div>
            <div className="header-container wow fadeInUp">	
                <div className="header-title">
                    <div className="header-icon"><span className="icon icon-Wheelbarrow"></span></div>
                    <div className="title">Our Projects ASSOCIATE</div>             
                </div>
            </div>
            
        </section>  

<section>

<Container sx={{mb:2}}>
    <div className="section-title" style={{ marginBottom:'20px' }}>Projects List</div>
{/* <TableContainer component={Paper} >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Project Name</TableCell>
            <TableCell align="right">Place</TableCell>
            <TableCell align="right">Share</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <TableRow
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor:'pointer' }}
              onClick={(e)=> handleClick(e, row.id)}
            >
              <TableCell scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.place}</TableCell>  
              <TableCell align="right">
              <IconButton aria-label="Download" onClick={(e)=>handleDownload(e)}>
        <Iconify icon='material-symbols:download-sharp' sx={{width: 30, height: 30}} /> 
      </IconButton>
      <IconButton aria-label="Share" onClick={(e)=>handleShare(e)}>
      <Iconify icon='material-symbols:share' sx={{width: 30, height: 30}} /> 
      </IconButton>
      <IconButton aria-label="Share WhatsUps" onClick={(e)=>handleShareWhatsUp(e)}>
      <Iconify icon='ic:baseline-whatsapp' sx={{width: 30, height: 30}} /> 
      </IconButton>
                </TableCell>              
            
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> */}

    <Grid container spacing={{ xs: 2, md: 3 }} sx={{justifyContent:'center'}}>
    {data.map((row, i) => (
        <Grid item xs={12} sm={6} md={4} key={i} sx={{justifyContent:'center', display:'flex'}}>
<Card sx={{ maxWidth: 345, p: 2, width: 400 }}>
      <CardMedia
        component={'a'}
        sx={{ height: 140 }}
        image={row.img}
        title={row.name}
        href={`#/projectAgent/${row.id}`}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" >
        {row.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {row.place}
        </Typography>
      </CardContent>
      <CardActions>
      <Stack spacing={2} direction={'row'}>
        <Button href={row.brochure} download={'Brochure'} target="_blank" rel="noreferrer" startIcon={<Iconify icon='material-symbols:download-sharp' sx={{width: 30, height: 30}} />}>Brochure</Button>
        <Button href={row.flyer} download={'Flyer'} target="_blank" rel="noreferrer" startIcon={<Iconify icon='material-symbols:download-sharp' sx={{width: 30, height: 30}} /> }>Flyers</Button>
       
        <WhatsappShareButton url='https://perfectlayout.in/' title={`Check out our  brochure and flyer to Know more about our Project:

Brochure URL:${row.brochure} 
Flyer URL: ${row.flyer}

Feel free to reach out if you have any questions or need more information.

Team,
Perfect layout`} >


        <Button  startIcon={<Iconify icon='ic:baseline-whatsapp' sx={{width: 30, height: 30}} /> }>Share WhatsUps</Button>
        </WhatsappShareButton>
</Stack>
      </CardActions>
    </Card>
    </Grid>))}</Grid>

    </Container>

</section>


        {/* <!-- ========================== -->
        <!-- BLOG - CONTENT -->
        <!-- ========================== -->
    

        <!-- ========================== -->
        <!-- FOOTER -->
        <!-- ========================== --> */}
        <footer>
            {/* <!-- ========================== -->
            <!-- BUY SECTION -->
            <!-- ========================== --> */}
            {/* <!-- <section className="buy-section with-icon">
                <div className="section-icon"><span className="icon icon-Umbrella"></span></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-1 col-sm-9 wow fadeInLeft">
                            <div className="section-text">
                                <div className=" vcenter like">
                                    <span className="icon icon-Like"></span> 
                                </div>
                                <div className="buy-text vcenter">
                                    <div className="top-text">oscend - a creative & multipurpose template</div>
                                    <div className="bottom-text">Lorem ipsum dolor sit amet consectetur elit sed</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-3  wow fadeInRight">
                            <a href="#" className="btn btn-info ">Buy it now</a>
                        </div>
                    </div>
                </div>
            </section> --> */}
            {/* <!-- ========================== -->
            <!-- FOOTER - SOCIAL -->
            <!-- ========================== --> */}
            {/* <section className="social-section dark dark-strong">
                <div className="container dark-content">
                    <div className="tt">We are social 24/7 - Get in touch</div>
                    <ul className="list-socials">
                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                        <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                        <li><a href="#"><i className="fa fa-dribbble"></i></a></li>
                        <li><a href="#"><i className="fa fa-pinterest-p"></i></a></li>
                    </ul>
                </div>
            </section> */}

            {/* <!-- ========================== -->
            <!-- FOOTER - FOOTER -->
            <!-- ========================== --> */}
            <section className="footer-section">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-md-3 col-sm-3">
                            <h5>about us</h5>
                            <p>Crowned as ASIA’S GREATEST BRAND and ASIA’S GREATEST LEADER With Mr. Sathesh kumar Garu, the recipient of Global Indian Of The Year award, at the helm</p>
                        </div> */}
                        <div className="col-md-3 col-sm-3">
                            <h5>Sitemap</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="footer-nav">
                                        <li><a href="#">About Us</a></li>
                                        <li className="active"><a href="#">Latest News</a></li>
                                        <li><a href="#">Contact us</a></li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="footer-nav">
                                        <li><a href="#">Get Help</a></li>
                                        <li><a href="#">Careers</a></li>
                                        <li><a href="#">FAQ's</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <h5>Contact info</h5>
                            <ul className="contacts-list">
                                <li>
                                    <p><i className="icon icon-House"></i>NO. 2-1-266/1/6, Flat No.201, Plot NO. 6, 2nd Floor, GSI (Sr) Bandlanguda, Nagole, Hyderabad -500068, 500068</p>
                                </li>
                                <li>
                                    <p><i className="icon icon-Phone2"></i>+91 8374416741</p>
                                </li>
                                <li>
                                    <p><i className="icon icon-Mail"></i><a href="mailto:support@oscend.com">admin@perfectlayout.com</a> </p>

                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <h5>newsletter</h5>
                            <p>
                                Subscribe to our newsletter for latest updates 
                                about our creative agency
                            </p>
                            <div className="form-group has-feedback">
                                <input type="email" className="form-control " placeholder="Email address ..." />
                                <i className="icon icon-Mail form-control-feedback"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="copyright-section">
                <p>©2023 <span>PERFECT LAYOUT PRIVATE LIMITED </span>. All Rights Reserved</p>
            </section>
        </footer>
       </>
)
};

export default ProjectsAgent;