// @mui
import { Box, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Logo(disabledLink, sx) {
  const theme = useTheme();

  // const PRIMARY_LIGHT = theme.palette.primary.light;

  // const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

  const logo = (
    <Box sx={{ width: "100%", height: "auto", ...sx, display:'flex', justifyContent:'center'}}>
      <img style={{width:"100px"}} src={require("../../img/logo-dark.png")} />
      {/* <Iconify sx={{width:'100%', height:'100%'}} icon="bxs:home" color="#1976d2" /> */}
    </Box>
  );

  // if (disabledLink) {
  //   return <>{logo}</>;
  // }

  return (
    <Link underline="none" href="#/home" target="_blank"  sx={{ width: "100%"}}>
      {logo}
    </Link>
  );
}
