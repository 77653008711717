import React, { useEffect, useState} from "react";
import { Container ,Stack,Grid,UserListToolbar ,Button,useMediaQuery,Card} from "@mui/material";
import WOW from "wowjs";
import { GetAll, apiURL, Put } from "../../../utils/apiCalls";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Switch from "@mui/material/Switch";
import { toast } from "react-toastify";
import { margin } from "@mui/system";
import { useNavigate } from 'react-router-dom';
import Iconify from '../../../layouts/Iconify';
const label = { inputProps: { "aria-label": "Switch demo" } };

function Row(props) {
  const { row, togglechange } = props;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell align="right">{row?.name}</TableCell>
        <TableCell align="right">{row?.place}</TableCell>
        <TableCell align="right">{row?.type}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Offers
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>S.NO</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell align="middle">Type</TableCell>
                    <TableCell align="right">Active</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.offer_banners?.length > 0 ? (
                    row?.offer_banners?.map((historyRow) => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          {historyRow?.id}
                        </TableCell>
                        <TableCell>
                        
                          <a href={historyRow?.offer_docs[0]?.filepaths3} target="_blank">
                            {historyRow?.offer_docs[0]?.filepaths3}
                          </a>
                        </TableCell>
                        <TableCell>{historyRow?.type}</TableCell>
                        <TableCell align="right">
                          <Switch
                            checked={historyRow?.active}
                            onChange={(e) => {
                              togglechange(e, historyRow?.id);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <div style={{ textAlign: "middle", marginTop: "10px" }}>
                      No offers
                    </div>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Offers = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  const togglechange = async (e, id) => {
    const response = await Put(apiURL + "/api/v1/offer_banner/", id, {
      active: e.target.checked,
    });
    if (response && response.data && response.data.status) {
      getProjects();
    }
  };
  const getProjects = async () => {
    const response = await GetAll(apiURL + "/api/v1/projects");
    if (response && response.data && response.data.status) {
      setData(response.data.data);
      console.log(response.data.data);
    }
  };
  useEffect(() => {
    getProjects();
  }, []);

  return (
    <Container sx={{ m: 0, width: "100%", maxWidth: "100%" }} maxWidth={false}>
         <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          
        <Grid container spacing={2}>
  <Grid item xs={12} sm={6} lg={6} md={6} xl={6}>
  <Typography variant="h4" gutterBottom>
          Offers 
          </Typography>
</Grid>
<Grid item xs={12} sm={6} lg={6} md={6} xl={6} sx={{justifyContent: isMobile ? 'center' : 'flex-end', alignItems:'center', display:'flex' }}>
<Button variant='contained' onClick={()=> navigate('/dashboard/offers/create')} sx={{height: 'max-content', marginRight: '24px !important', marginBottom:'15px !important', width: isMobile ? '80%': 'max-content'}} 
         startIcon={<Iconify icon="subway:add" />}>Create Offer</Button>
          </Grid></Grid>    
        </Stack>
<Card  sx={{boxShadow:"0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.2)"}}>
      <TableContainer component={Paper} >
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>S.No.</TableCell>
              <TableCell align="right">Project Name</TableCell>
              <TableCell align="right">Place</TableCell>
              <TableCell align="right">Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <Row key={row?.name} row={row} togglechange={togglechange} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Card>
    </Container>
    
  );
};
export default Offers;
