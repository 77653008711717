import React from 'react';
import { Typography } from "@mui/material";

const Wallet = () => {

    return (
<Typography variant="h3" align='center'>Comming Soon</Typography>
    )
};

export default Wallet;