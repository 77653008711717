import React from 'react';
import { FormikProvider, useFormik, Form } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Paper, Button, CardContent, CardActions, Card, TextField,  Grid, Typography, TableRow, TableCell, IconButton, TablePagination } from '@mui/material';
import { Post, apiURL } from '../../../utils/apiCalls';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';

const AdminSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().required('Email is required'),
    phone_number: Yup.string().required('Phone Number is required'),
    aadhar_number: Yup.string().required('Aadhar Number is required'),
    pan_number: Yup.string().required('Pan Number is required'),
    aadhar_upload: Yup.string().required('Aadhar Upload is required'),
    pan_upload: Yup.string().required('Pan Upload is required'),
    personal_address: Yup.string().required('Personal Address is required'),
    bank_name: Yup.string().required('Bank Name is required'),
    account_number: Yup.string().required('Account Number is required'),
    ifsc_number: Yup.string().required('IFSC Number is required'),
    bank_address: Yup.string().required('Bank Address is required'),
    role: Yup.string().required('Role is required'),
    active: Yup.string().required('Active is required'),
  });

const AdminEditCreate = () => {
const { id } =useParams()
    const formik = useFormik({
        initialValues: {
           first_name: '',
           last_name: '',
           email: '',
           phone_number: '',
           aadhar_number: '',
           pan_number: '',
           aadhar_upload: '',
           pan_upload: '',
           personal_address: '',
           bank_name: '',
           account_number: '',
           ifsc_number: '',
           bank_address: '',
           role: '',
           active: '',
        },
        validationSchema: AdminSchema,
        onSubmit: async (values, { setSubmitting }) => {
         const response = await Post(apiURL + '/api/v1/users/create/admin', {...values})
         if(response && response.data && response.status) {
           toast.success('Company Details Saved Sucessfully')
           setSubmitting(false)
         }
         else {
           toast.error(response?.data.message || 'Please Try Again')
           setSubmitting(false)
         }
      }
      });
      
      const { errors, touched, values, setFieldValue, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;



    return (
        <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
            <CardContent>
                <Typography align='center' variant='h4'>CREATE AGENTS</Typography>
          <Grid container spacing={2} sx={{ mt: 0 }}>
           <Grid item xs={4}>
            <TextField fullWidth  label="First Name" {...getFieldProps('first_name')} error={Boolean(touched.first_name && errors.first_name)} helperText={touched.first_name && errors.first_name} />
            </Grid>
            <Grid item xs={4}>
            <TextField fullWidth label="Last Name" {...getFieldProps('last_name')} error={Boolean(touched.last_name && errors.last_name)} helperText={touched.last_name && errors.last_name} />
         </Grid>
         <Grid item xs={4}>
            <TextField fullWidth label="Email Address" {...getFieldProps('email')} error={Boolean(touched.email && errors.email)} helperText={touched.email && errors.email} />
         </Grid></Grid>
      
         <Grid container spacing={2} sx={{ mt: 0 }}>
           <Grid item xs={4}>
            <TextField fullWidth  label="Phone Number" {...getFieldProps('phone_number')} error={Boolean(touched.phone_number && errors.phone_number)} helperText={touched.phone_number && errors.phone_number} />
            </Grid>
            <Grid item xs={4}>
            <TextField fullWidth label="Aadhar Number" {...getFieldProps('aadhar_number')} error={Boolean(touched.aadhar_number && errors.aadhar_number)} helperText={touched.aadhar_number && errors.aadhar_number} />
         </Grid>
         <Grid item xs={4}>
            <TextField fullWidth label="Pan Number" {...getFieldProps('pan_number')} error={Boolean(touched.pan_number && errors.pan_number)} helperText={touched.pan_number && errors.pan_number} />
         </Grid></Grid>

         <Grid container spacing={2} sx={{ mt: 0 }}>
           <Grid item xs={4}>
            {/* <TextField fullWidth  label="Company Name" {...getFieldProps('company_name')} error={Boolean(touched.company_name && errors.company_name)} helperText={touched.company_name && errors.company_name} /> */}
            </Grid>
            <Grid item xs={4}>
            {/* <TextField fullWidth label="Company Address" {...getFieldProps('address')} error={Boolean(touched.address && errors.address)} helperText={touched.address && errors.address} /> */}
         </Grid>
         <Grid item xs={4}>
            <TextField fullWidth label="Personal Address" {...getFieldProps('personal_address')} error={Boolean(touched.personal_address && errors.personal_address)} helperText={touched.personal_address && errors.personal_address} />
         </Grid></Grid>

         <Grid container spacing={2} sx={{ mt: 0 }}>
           <Grid item xs={4}>
            <TextField fullWidth  label="Bank Name" {...getFieldProps('bank_name')} error={Boolean(touched.bank_name && errors.bank_name)} helperText={touched.bank_name && errors.bank_name} />
            </Grid>
            <Grid item xs={4}>
            <TextField fullWidth label="Account Number" {...getFieldProps('account_number')} error={Boolean(touched.account_number && errors.account_number)} helperText={touched.account_number && errors.account_number} />
         </Grid>
         <Grid item xs={4}>
            <TextField fullWidth label="IFSC Code" {...getFieldProps('ifsc_number')} error={Boolean(touched.ifsc_number && errors.ifsc_number)} helperText={touched.ifsc_number && errors.ifsc_number} />
         </Grid></Grid>

         <Grid container spacing={2} sx={{ mt: 0 }}>
           <Grid item xs={4}>
            <TextField fullWidth  label="Bank Address" {...getFieldProps('bank_address')} error={Boolean(touched.bank_address && errors.bank_address)} helperText={touched.bank_address && errors.bank_address} />
            </Grid>
            <Grid item xs={4}>
            <TextField fullWidth label="Postion or Role" {...getFieldProps('role')} error={Boolean(touched.role && errors.role)} helperText={touched.role && errors.role} />
         </Grid>
         <Grid item xs={4}>
            <TextField fullWidth label="Active" {...getFieldProps('active')} error={Boolean(touched.active && errors.active)} helperText={touched.active && errors.active} />
         </Grid></Grid>
         </CardContent>

         <CardActions sx={{display:'flex', justifyContent: 'space-between'}}>
              <Button href="#/dashboard/agent" variant='contained' sx={{ backgroundColor: '#3f51b5', '&:hover': {color: '#ffff', backgroundColor: '#3f51b5'} }}>Back</Button>
              <LoadingButton  size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ backgroundColor: '#3f51b5', '&:hover': {color: '#ffff', backgroundColor: '#3f51b5'} }}>Save </LoadingButton>
              </CardActions>
              </Card>
        </Form>
      </FormikProvider>
    )
};

export default AdminEditCreate;