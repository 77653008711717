import React, { useEffect } from 'react';
import homeImage from '../img/sections/home-top-background.jpg';
import section11 from '../img/sections/section-11.jpg';
import logoDark from '../img/logo-dark.png';
import logo from '../img/logo.png';
import '../css/main.css'
import WOW from 'wowjs';
import { Button, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';

const Home = () => {
    useEffect(() => {
        const wow = new WOW.WOW({
            live: false
        });
        wow.init();
    }, []);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
<>
{/* <div id="page-preloader">
    <span className="spinner" />
  </div> */}
  {/* ========================== */}
  {/* Navigation */}
  {/* ========================== */}
<header className="header scrolling-header">
            <nav id="nav" className="navbar navbar-default navbar-fixed-top" role="navigation">
                <div className="container relative-nav-container">
                    <a className="toggle-button visible-xs-block" data-toggle="collapse" data-target="#navbar-collapse">
                        <i className="fa fa-navicon"></i>
                    </a>
                    <a className="navbar-brand scroll" href="#/home" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <img className="normal-logo hidden-xs" src={logo} alt="logo" style={{background:'300px'}} />
                        <img className="scroll-logo hidden-xs" src={logoDark} alt="logo" />
                        <img className="scroll-logo visible-xs-block" src={logo} alt="logo" />
                    </a>
                    <div className="navbar-collapse collapse floated" id="navbar-collapse">
                        <ul className="nav navbar-nav navbar-with-inside clearfix navbar-right with-border"> 
                            <li className="active">
                                <a href="#/home">home</a>
                               
                            </li>
                           
                            <li>
                                <a href="#/about">about</a>
                            </li>
                            <li>
                                {/* <a href="#/project">Our Projects</a> */}
                                <Button
        aria-haspopup="true"
        // className='menuButton'
        // onClick={handleClick}
        href='#/projectLayout'
        
      >
        Our Projects
      </Button>
     {/* <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ '.MuiMenu-paper' : {minWidth:'110px'} }}
      >
        <MenuItem component={'a'} href='#/project' onClose={handleClose}>
            <ListItemIcon >
            <AdminPanelSettingsIcon sx={{width:'1.5em', height:'1.5em'}} />
          </ListItemIcon>
          <ListItemText>Admin</ListItemText>
            </MenuItem>
        <MenuItem component={'a'} href='#/login' target='_blank' onClose={handleClose}>           
            <ListItemIcon>
            <PersonIcon sx={{width:'1.5em', height:'1.5em'}} />
          </ListItemIcon>
          <ListItemText>Agent</ListItemText>
            </MenuItem>
</Menu> */}
                            </li>
                            <li><a href="#/services">services</a></li>
                            {/* <li>
                                <a  href="#/blog">Blog</a>
                               
                            </li> */}
                            <li>
                                <a href="#/contact">Contacts</a>
                               
                            </li>
                            <li> <Button className='menuButton' href='#/login' target='_blank'> Login In</Button> </li>
                        </ul>
                    </div>
                </div>
               
            </nav>
        </header>

        {/* <!-- ========================== -->
        <!-- HOME - HEADER -->
        <!-- ========================== --> */}
        <section className="top-header homeImage home-header with-bottom-effect transparent-effect dark-strong">
            {/* <div className="bottom-effect"></div> */}
            <div className="header-container">	
                <div className="wrap-section-slider" id="topSlider">
                    <div className="">
                      
                        <div className="slide-item sp-slide">
                            {/* <div className="slide-image">
                                <img src={section11} alt="" />
                            </div> */}
                            <div className="slide-content sp-layer">
                                {/* <p className="top-title sp-layer"  data-show-transition="left" data-hide-transition="up" data-show-delay="400" data-hide-delay="100" style={{color:'#ff6400'}}>Blooms</p> */}
                                <div className="title sp-layer" data-show-transition="right" data-hide-transition="up" data-show-delay="500" data-hide-delay="110" style={{ fontSize:'35px'}}>Explore the possibility: your dream property awaits!</div>
                                <div className="under-title sp-layer" data-show-transition="up" data-hide-transition="up" data-show-delay="600" data-hide-delay="200" style={{ opacity: 1}}>Your Dreams Our Villa Plots</div>
                                <div className="controls sp-layer" data-show-transition="up" data-hide-transition="up" data-show-delay="800" data-hide-delay="250">
                                    {/* <!-- <a href="#" className="btn btn-primary">Get Started NOW</a>
                                    <a href="#" className="btn btn-info">&nbsp;&nbsp;Discover More&nbsp;&nbsp;</a> --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--./container--> */}
        </section>  


        {/* <!-- ========================== -->
        <!-- HOME - FEATURES -->
        <!-- ========================== --> */}
        <section className="features-section">
            <div className="container">
                <div className="section-heading " >
                    <div className="section-title">Tailored Solutions: Delivering Optimum Results for Every Customer</div>
                    <div className="section-subtitle">Unlocking Your Real Estate Potential: The Pathway to Success 🏡💼</div>
                    <div className="design-arrow"></div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-sm-6 wow fadeIn">
                        <article>
                            <div className="feature-item ">
                                <div className="wrap-feature-icon">
                                    <div className="feature-icon">
                                        <span className="icon icon-ClipboardChart"></span>
                                    </div>
                                </div>
                                <div className="title">Punctual Property Registration: Your Key to Peace of Mind</div>
                                <div className="text">
                                Secure land acquisitions on behalf of our clients, promptly facilitating smooth transfers to customers.
                                </div>
                            </div>
                        </article>
                    </div> 
                    <div className="col-md-3 col-sm-6 wow fadeIn">
                        <article>
                            <div className="feature-item">
                                <div className="wrap-feature-icon">
                                    <div className="feature-icon">
                                        <span className="icon  icon-Search"></span>
                                    </div>
                                </div>
                                <div className="title">Ensuring Legal Compliance: Your Due Diligence Partner </div>
                                <div className="text">
                                Protecting Your Interests: Robust Documentation Solutions
                                </div>
                            </div>
                        </article>
                    </div> 
                    <div className="col-md-3 col-sm-6 wow fadeIn">
                        <article>
                            <div className="feature-item">
                                <div className="wrap-feature-icon">
                                    <div className="feature-icon">
                                        <span className="icon icon-Blog"></span>
                                    </div>
                                </div>
                                <div className="title">Cost-effectiveness: Ensuring Value for Every Budget</div>
                                <div className="text">
                                Guaranteed Contract Fulfillment: Total Transparency Assured
                                </div>
                            </div>
                        </article>
                    </div>  
                    <div className="col-md-3 col-sm-6 wow fadeIn">
                        <article>
                            <div className="feature-item">
                                <div className="wrap-feature-icon">
                                    <div className="feature-icon">
                                        <span className="icon icon-Dollars"></span>
                                    </div>
                                </div>
                                <div className="title">Empowering Your Homeownership Journey: Expert Loan Support</div>
                                <div className="text">
                                Guidance for Home Financing on HMDA-Certified Properties
                                </div>
                            </div>
                        </article>
                    </div> 
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-6 wow fadeIn">
                        <article>
                            <div className="feature-item active">
                                <div className="wrap-feature-icon">
                                    <div className="feature-icon">
                                        <span className="icon icon-ClosedLock"></span>
                                    </div>
                                </div>
                                <div className="title">Assured Security and Transparency: Our Promise to You</div>
                                <div className="text">
                                Ensuring Accurate Billing and Eliminating Fraudulent Practices
                                </div>
                            </div>
                        </article>
                    </div> 
                    <div className="col-md-4 col-sm-6 wow fadeIn">
                        <article>
                            <div className="feature-item ">
                                <div className="wrap-feature-icon">
                                    <div className="feature-icon">
                                        <span className="icon icon-StorageBox"></span>
                                    </div>
                                </div>
                                <div className="title"> Dependable Solutions: Your Trusted Partner</div>
                                <div className="text">
                                Trustworthy Services: Expertise and Dedication at Your Service
                                </div>
                            </div>
                        </article>
                    </div> 
                    <div className="col-md-4 col-sm-6 wow fadeIn">
                        <article>
                            <div className="feature-item">
                                <div className="wrap-feature-icon">
                                    <div className="feature-icon">
                                        <span className="icon  icon-House"></span>
                                    </div>
                                </div>
                                <div className="title">Simplify Your Experience: Hassle-Free Solutions Await</div>
                                <div className="text">
                                Seamless Government Approvals: Smooth Construction Process Guaranteed
                                </div>
                            </div>
                        </article>
                    </div> 
                  
                </div>
            </div>
        </section>


        {/* <!-- ========================== -->
        <!-- HOME - LAPTOPS -->
        <!-- ========================== -->
        <!-- <section className="laptops-section">
            <div className="container">
                <div className="laptops text-center wow fadeInUp" data-wow-duration="1s">
                    <img src="img/laptop.jpg" alt="" className="img-responsive" />
                </div>
            </div>
            <div className="container">
                <div className="content-logo text-center wow fadeInUp"  data-wow-duration="1s">
                    <img src="img/content-logo.png" alt="" className="img-responsive" />
                </div>
            </div>
        </section> -->

        <!-- ========================== -->
        <!-- HOME - AREAS OF EXPERTISE-->
        <!-- ========================== -->  */}
        <section className="areas-section with-icon with-top-effect">
            <div className="section-icon"><span className="icon icon-Umbrella"></span></div>
            <div className="container"> 
                <div className="row">
                    <div className="col-md-7 col-sm-7 text-right">
                        <div className="clearfix " style={{paddingRight: '3px'}}>
                            <div className="above-title">We think & deliver</div>
                            <h4>Real Estate Visionaries Network</h4>
                        </div>
                        {/* <!-- <div><em>Lorem ipsum dolor sit amet consectetur adipisicing elit</em></div> --> */}
                        
                        <p class="large text-left">
                        Embark on a journey to find your ideal piece of land with our premier real estate company. Whether you're envisioning a dream home, seeking a lucrative investment, or planning a development project, our extensive listings cater to diverse preferences. Our user-friendly search function and detailed property information empower you to make well-informed decisions with ease.

At our core is a team of seasoned real estate professionals, ready to offer guidance at every turn. Beyond the search process, we provide a range of services, including financing options, appraisals, and title services, ensuring a comprehensive and hassle-free experience.

Taking pride in our commitment to excellence, we strive to be your trusted partner in navigating the complexities of the land buying process. Let us help you make the right investment decision and transform your land ownership dreams into reality.

Take the first step towards your land ownership journey today. Explore our listings, utilize our expert guidance, and unlock the door to a seamless and rewarding land purchase experience. Contact us now, and let our dedicated professionals assist you in making informed and empowered decisions for your future.
                    </p>
                    </div>
                    <div className="col-md-5 col-sm-5 text-center">
                        <img src={require("../img/area.png")} alt="" className="img-responsive" />
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- ========================== -->
        <!-- HOME - ACHIEVEMENTS -->
        <!-- ========================== --> */}
        {/* <section className="achievements-section with-bottom-effect dark dark-strong">
            <div className="bottom-effect"></div>
            <div className="container dark-content">
                <div className="row list-achieve">
                    <div className="col-md-3 col-sm-3 col-xs-6 wow zoomIn" data-wow-delay="0.5s">
                        <article>
                            <div className="achieve-item">
                                <div className="achieve-icon">
                                    <span className="icon icon-Wallet"></span>
                                </div>
                                <div className="count">561</div>
                                <div className="name">projects delivered</div>
                            </div>
                        </article>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-6 wow zoomIn" data-wow-delay="1.5s">
                        <article>
                            <div className="achieve-item">
                                <div className="achieve-icon">
                                    <span className="icon icon-Cup"></span>
                                </div>
                                <div className="count">24</div>
                                <div className="name">awards won</div>
                            </div>
                        </article>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-6 wow zoomIn" data-wow-delay="2.5s">
                        <article>
                            <div className="achieve-item">
                                <div className="achieve-icon">
                                    <span className="icon icon-Like"></span>
                                </div>
                                <div className="count">160</div>
                                <div className="name">happy clients</div>
                            </div>
                        </article>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-6 wow zoomIn" data-wow-delay="3.5s">
                        <article>
                            <div className="achieve-item">
                                <div className="achieve-icon">
                                    <span className="icon icon-Users"></span>
                                </div>
                                <div className="count">285</div>
                                <div className="name">employees working</div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section> */}

        {/* <!-- ========================== -->
        <!-- HOME - LATEST WORKS -->
        <!-- ========================== -->
        <!-- <section className="latest-works-section clearfix">
            <div className="container">
                <div className="section-heading">
                    <div className="section-title">Latest Works</div>
                    <div className="section-subtitle">Lorem ipsum dolor amet consectetur adipisic elit</div>
                    <div className="design-arrow"></div>
                </div>
            </div>

            <div className="scroll-pane ">
                <div className="scroll-content">
                    <div className="scroll-content-item  ">
                        <a href="img/img3.jpg" className="fancybox">
                            <img src="img/img3.jpg" alt="" />
                        </a>
                        <div className="name">dahls 160</div> 
                    </div>
                    <div className="scroll-content-item  ">
                        <a href="img/img4.jpg" className="fancybox">
                            <img src="img/img4.jpg" alt="" />
                        </a>
                        <div className="name">dahls 160</div> 
                    </div>
                    <div className="scroll-content-item  ">
                        <a href="img/img1.jpg" className="fancybox">
                            <img src="img/img1.jpg" alt="" />
                        </a>
                        <div className="name">dahls 160</div> 
                    </div>
                    <div className="scroll-content-item  ">
                        <a href="img/img2.jpg" className="fancybox">
                            <img src="img/img2.jpg" alt="" />
                        </a>
                        <div className="name">dahls 160</div> 
                    </div>
                    <div className="scroll-content-item  ">
                        <a href="img/img3.jpg" className="fancybox">
                            <img src="img/img3.jpg" alt="" />
                        </a>
                        <div className="name">dahls 160</div> 
                    </div>
                    <div className="scroll-content-item  ">
                        <a href="img/img3.jpg" className="fancybox">
                            <img src="img/img3.jpg" alt="" />
                        </a>
                        <div className="name">dahls 160</div> 
                    </div>
                    <div className="scroll-content-item  ">
                        <a href="img/img4.jpg" className="fancybox">
                            <img src="img/img4.jpg" alt="" />
                        </a>
                        <div className="name">dahls 160</div> 
                    </div>
                    <div className="scroll-content-item  ">
                        <a href="img/img1.jpg" className="fancybox">
                            <img src="img/img1.jpg" alt="" />
                        </a>
                        <div className="name">dahls 160</div> 
                    </div>
                    <div className="scroll-content-item  ">
                        <a href="img/img2.jpg" className="fancybox">
                            <img src="img/img2.jpg" alt="" />
                        </a>
                        <div className="name">dahls 160</div> 
                    </div>
                    <div className="scroll-content-item  ">
                        <a href="img/img3.jpg" className="fancybox">
                            <img src="img/img3.jpg" alt="" />
                        </a>
                        <div className="name">dahls 160</div> 
                    </div>
                    <div className="scroll-content-item  ">
                        <a href="img/img3.jpg" className="fancybox">
                            <img src="img/img3.jpg" alt="" />
                        </a>
                        <div className="name">dahls 160</div> 
                    </div>
                    <div className="scroll-content-item  ">
                        <a href="img/img4.jpg" className="fancybox">
                            <img src="img/img4.jpg" alt="" />
                        </a>
                        <div className="name">dahls 160</div> 
                    </div>
                    <div className="scroll-content-item  ">
                        <a href="img/img1.jpg" className="fancybox">
                            <img src="img/img1.jpg" alt="" />
                        </a>
                        <div className="name">dahls 160</div> 
                    </div>
                    <div className="scroll-content-item  ">
                        <a href="img/img2.jpg" className="fancybox">
                            <img src="img/img2.jpg" alt="" />
                        </a>
                        <div className="name">dahls 160</div> 
                    </div>
                    <div className="scroll-content-item  ">
                        <a href="img/img3.jpg" className="fancybox">
                            <img src="img/img3.jpg" alt="" />
                        </a>
                        <div className="name">dahls 160</div> 
                    </div>
                </div>
                <div className="scroll-bar-wrap ">
                    <div className="scroll-bar"></div>
                </div>
            </div>
        </section> -->


        <!-- ========================== -->
        <!-- HOME - STEPS  -->
        <!-- ========================== --> */}
        {/* <section className="steps-section with-icon ">
            <div className="section-icon"><span className="icon icon-Umbrella"></span></div>
            <div className="container">
                <div className="section-heading">
                    <div className="section-title">simple steps . quick results</div>
                    <div className="design-arrow"></div>
                </div>
            </div>
            <div className="container">
                <div className="row steps-list">
                    <div className="col-md-4 col-sm-4 col-xs-4 wow fadeIn" >
                        <div className="step-item">
                            <div className="item-icon" data-count="1">
                                <span className="icon icon-Pencil"></span>
                            </div>
                            <div className="item-text">
                                <h5>Login 
                                    
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4 wow fadeIn" data-wow-delay="0.3s">
                        <div className="step-item invert">
                            <div className="item-icon" data-count="2">
                                <span className="icon icon-Glasses"></span>
                            </div>
                            <div className="item-text">
                                <h5>View Layout &amp; <br />
                                    and Details .
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4 wow fadeIn" data-wow-delay="0.6s">
                        <div className="step-item">
                            <div className="item-icon" data-count="3">
                                <span className="icon icon-Plaine"></span>
                            </div>
                            <div className="item-text">
                                <h5>Book 
                                   
                                    
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </section> */}
 {/* <!-- <div className="container results-container">
                <div className="row">
                    <div className="col-md-6  col-sm-6">
                        <ul className="skills">
                            <li data-percent="75" className="skills-animated ">
                                <span>web design</span>
                                <div className="progress" >
                                    <div className="progress-percent">
                                        <span className="progress-bar-tooltip">75</span>%
                                    </div>
                                </div>
                            </li>
                            <li data-percent="90" className="skills-animated orange">
                                <span>programming</span>
                                <div className="progress" >
                                    <div className="progress-percent">
                                        <span className="progress-bar-tooltip">90</span>%
                                    </div>
                                </div>
                            </li>
                            <li data-percent="55" className="skills-animated">
                                <span>marketing</span>
                                <div className="progress" >
                                    <div className="progress-percent">
                                        <span className="progress-bar-tooltip">55</span>%
                                    </div>
                                </div>
                            </li>
                            <li data-percent="85" className="skills-animated orange">
                                <span>photography</span> 
                                <div className="progress">
                                    <div className="progress-percent">
                                        <span className="progress-bar-tooltip">85</span>%
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="results-description" >
                            <h5 className="italic-title">Every customer is importany to us!</h5>
                            <h4>Our Experienced guys at Oscend always giving their best
                                to make the clients happy!!
                            </h4>
                            <p>Amco laboris nisi ut aliquip xea commodo consequt. Duis aute irure dolor reprehenderit voluptate velit esse cillum dolore
                                fugiat lore ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua ut enim ad mid veniam quis nostrud exercitation ullamco laboris nisid. Lorem ipsum dolor sit amet consectetur adip
                                isicing elit sed eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                        </div>
                    </div>
                </div>
            </div> --> */}
        {/* <!-- ========================== -->
        <!-- HOME - BROWSERS  -->
        <!-- ========================== --> */}
        {/* <section className="browsers-section with-bottom-effect ">
            <div className="bottom-effect"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12" style={{height: "500px"}}>
                        <img src={require("../img/home1.jpg")}  alt=" " style={{height: "100%", width: "100%"}}  className="img-responsive" />
                    </div>
                </div>
            </div>
        </section> */}

        {/* <!-- ========================== -->
        <!-- HOME - SERVICES  -->
        <!-- ========================== -->
        <!-- <section className="services-section ">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-4 col-xs-4">
                        <div className="service-item">
                            <div className="media-left">
                                <div className="wrap-service-icon">
                                    <div className="service-icon">
                                        <span className="icon icon-Flag"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="media-body">
                                <h5>awesome icons</h5>
                                <p><em>best solutions that works</em></p>
                                <p>Lorem ipsum dolor sit amet consect letur se
                                    adipisicing elit sed don eiusmod.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4">
                        <div className="service-item">
                            <div className="media-left">
                                <div className="wrap-service-icon">
                                    <div className="service-icon">
                                        <span className="icon icon-Carioca"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="media-body">
                                <h5>Clean & modern</h5>
                                <p><em>lorem ipsum dolor sit amet</em></p>
                                <p>Lorem ipsum dolor sit amet consect letur se
                                    adipisicing elit sed don eiusmod.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4">
                        <div className="service-item">
                            <div className="media-left">
                                <div className="wrap-service-icon">
                                    <div className="service-icon">
                                        <span className="icon icon-Planet"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="media-body">
                                <h5>multipurpose</h5>
                                <p><em>providing excellent services</em></p>
                                <p>Lorem ipsum dolor sit amet consect letur se
                                    adipisicing elit sed don eiusmod.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="services-divider">
                        <div className="col-md-4 col-sm-4 col-xs-4"></div>
                        <div className="col-md-4 col-sm-4 col-xs-4"></div>
                        <div className="col-md-4 col-sm-4 col-xs-4"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-4 col-xs-4">
                        <div className="service-item">
                            <div className="media-left">
                                <div className="wrap-service-icon">
                                    <div className="service-icon">
                                        <span className="icon icon-Folder"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="media-body">
                                <h5>well documented</h5>
                                <p><em>providing excellent services</em></p>
                                <p>Lorem ipsum dolor sit amet consect letur se
                                    adipisicing elit sed don eiusmod.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4">
                        <div className="service-item">
                            <div className="media-left">
                                <div className="wrap-service-icon">
                                    <div className="service-icon">
                                        <span className="icon icon-Layers"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="media-body">
                                <h5>multiple colors</h5>
                                <p><em>best solutions that works</em></p>
                                <p>Lorem ipsum dolor sit amet consect letur se
                                    adipisicing elit sed don eiusmod.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4">
                        <div className="service-item">
                            <div className="media-left">
                                <div className="wrap-service-icon">
                                    <div className="service-icon">
                                        <span className="icon icon-DesktopMonitor"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="media-body">
                                <h5>responsive design</h5>
                                <p><em>lorem ipsum dolor sit amet</em></p>
                                <p>Lorem ipsum dolor sit amet consect letur se
                                    adipisicing elit sed don eiusmod.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

        <!-- ========================== -->
        <!-- HOME - VIDEO SECTION -->
        <!-- ========================== --> */}

        <section >
            {/* <!-- <div className="video-play" id="video-play" data-property="{videoURL:'https://www.youtube.com/watch?v=MFQ1CBz4sv4',containment:'#video-play',autoPlay:true, mute:true, startAt:0, opacity:1}"></div> --> */}
            {/* <!-- <video className="video-play" src="videos/perfectlayoutvideo1.mp4" autoplay="true">
                Your browser doesn't support the `<video>` tag. this is a fallback
                </video> --> */}
                {/* <div className="video-play" id="video-play" data-property="my-video">
                <video id="video-play" controls style={{width: "100%"}}>
                    <source src={require("../videos/perfectlayoutvideo1.mp4")} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div> */}

            
            <div className='container '>
            <div className="section-title ">Discover More: Watch Our Introduction Video</div>
            <div style={{display:'flex',justifyContent:"center",marginBottom:'50px',marginTop:'50px'}}>
            <iframe width="500" height="390" src="https://www.youtube.com/embed/MpBen6miMRU" style={{boxShadow:"rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"}} >
</iframe>

            </div>
        
            </div>
       
            {/* <div className="bottom-effect"></div> */}
            {/* <div className="container dark-content">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="title">Watch our Intro Video</div>
                        <em>Sample View of Land</em>
                        <a href={'https://youtu.be/MpBen6miMRU'} target="_blank"><button type="button" className="btn-play"></button></a>
                        <div className="duration">Duration : <span>2:34</span></div>
                    </div>
                </div>
            </div> */}
        </section>
        {/* <!-- ========================== -->
        <!-- HOME - TEAM -->
        <!-- ========================== -->
        <!-- <section className="team-section with-bottom-effect">
            <div className="bottom-effect"></div>
            <div className="container">
                <div className="section-heading wow fadeIn">
                    <div className="section-title">experienced team</div>
                    <div className="section-subtitle">Lorem ipsum dolor amet consectetur adipisic elit</div>
                    <div className="design-arrow"></div>
                </div>
            </div>
            <div className="container">
                <div className="wrap-team-slider wow fadeIn"> 
                    <div className="prev-btn"><span className="icon invertX icon-Goto"></span></div>
                    <div className="next-btn"><span className="icon icon-Goto"></span></div>
                    <div className="team-slider enable-stick-slider" data-slick='{"slidesToShow": 3, "slidesToScroll": 1, "prevArrow" : ".prev-btn", "nextArrow": ".next-btn" }'>
                        <div className="slide-item">
                            <div className="image">
                                <img src="img/team/member-1.png" alt="" />
                            </div>
                            <div className="slide-description">
                                <div className="member-info">
                                    <div className="name">dan tarkist</div>
                                    <div className="position">Creative Director</div>
                                </div>
                                <div className="contacts">
                                    <ul>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                        <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                        <li><a href="#"><i className="fa fa-envelope"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="slide-item">
                            <div className="image">
                                <img src="img/team/member-3.png" alt="" />
                            </div>
                            <div className="slide-description">
                                <div className="member-info">
                                    <div className="name">sarina ken</div>
                                    <div className="position">Creative Director</div>
                                </div>
                                <div className="contacts">
                                    <ul>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                        <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                        <li><a href="#"><i className="fa fa-envelope"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="slide-item">
                            <div className="image">
                                <img src="img/team/member-2.png" alt="" />
                            </div>
                            <div className="slide-description">
                                <div className="member-info">
                                    <div className="name">ana mes</div>
                                    <div className="position">Product Manager</div>
                                </div>
                                <div className="contacts">
                                    <ul>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                        <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                        <li><a href="#"><i className="fa fa-envelope"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div> 
                        <div className="slide-item">
                            <div className="image">
                                <img src="img/team/member-1.png" alt="" />
                            </div>
                            <div className="slide-description">
                                <div className="member-info">
                                    <div className="name">dan tarkist</div>
                                    <div className="position">Creative Director</div>
                                </div>
                                <div className="contacts">
                                    <ul>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                        <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                        <li><a href="#"><i className="fa fa-envelope"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="slide-item">
                            <div className="image">
                                <img src="img/team/member-3.png" alt="" />
                            </div>
                            <div className="slide-description">
                                <div className="member-info">
                                    <div className="name">sarina ken</div>
                                    <div className="position">Creative Director</div>
                                </div>
                                <div className="contacts">
                                    <ul>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                        <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                        <li><a href="#"><i className="fa fa-envelope"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section> --> */}

        {/* <!-- ========================== -->
        <!-- REVIEWS SECTION -->
        <!-- ========================== -->
        <!-- <section className="reviews-section">
            <div className="container">
                <div className="section-heading">
                    <div className="section-title">What clients said</div>
                    <div className="section-subtitle">Lorem ipsum dolor amet consectetur adipisic elit</div>
                    <div className="design-arrow"></div>
                </div>
            </div>
            <div className="container">
                <div className="reviews-slider enable-owl-carousel" data-single-item="true">
                    <div className="slide-item">
                        <div className="media-left">
                            <div className="image-block">
                                <img src="img/review-img1.jpg" alt="" />
                            </div>
                        </div>
                        <div className="media-body">
                            <div className="description-block">
                                <div className="name">
                                    <span>James H. ken </span>
                                    <em>Oscend Creative Inc.</em>
                                </div>
                                <div className="review">
                                    <em>Lorem ipsum dolor sit amet consecteur adipiscing elit sed eiusmod tempor ncididue labore 
                                        etor dolore magna aliqua. Ut enim ad minim veniam qu nostrud exercitat ullamco laboris
                                        nisi ut aliquip ex ea commodo consequat duis autes irure dolor rep.</em>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="slide-item">
                        <div className="media-left">
                            <div className="image-block">
                                <img src="img/review-img1.jpg" alt="" />
                            </div>
                        </div>
                        <div className="media-body">
                            <div className="description-block">
                                <div className="name">
                                    <span>James H. ken </span>
                                    <em>Oscend Creative Inc.</em>
                                </div>
                                <div className="review">
                                    <em>Lorem ipsum dolor sit amet consecteur adipiscing elit sed eiusmod tempor ncididue labore 
                                        etor dolore magna aliqua. Ut enim ad minim veniam qu nostrud exercitat ullamco laboris
                                        nisi ut aliquip ex ea commodo consequat duis autes irure dolor rep.</em>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section> --> */}


        {/* <!-- ========================== -->
        <!-- BUY SECTION -->
        <!-- ========================== -->
        <!-- <section className="buy-section with-icon">
            <div className="section-icon"><span className="icon icon-Umbrella"></span></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-md-offset-1 col-sm-9 wow fadeInLeft">
                        <div className="section-text">
                            <div className=" vcenter like">
                                <span className="icon icon-Briefcase"></span> 
                            </div>
                            <div className="buy-text vcenter">
                                <div className="top-text">web applications</div>
                                <div className="bottom-text">Discuss your project with us</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3 wow fadeInRight">
                        <a href="#" className="btn btn-info ">Get Started NOW</a>
                    </div>
                </div>
            </div>
        </section> -->

        <!-- ========================== -->
        <!-- HOME - PRICE -->
        <!-- ========================== --> */}
        {/* <!-- <section className="price-section">
            <div className="with-bottom-effect dark dark-strong pricing-background">
                <div className="bottom-effect"></div>
            </div>
            <div className="dark-content">
                <div className="container">
                    <div className="section-heading">
                        <div className="section-title">our pricing plans</div>
                        <div className="section-subtitle">Lorem ipsum dolor amet consectetur adipisic elit</div>
                        <div className="design-arrow"></div>
                    </div>
                </div>
            </div>
            <div className="dark-content">
                <div className="container">
                    <div className="row no-gutter plans-list text-center">
                        <div className="col-md-4 col-sm-4 vcenter">
                            <div className="plan-item wow zoomIn"  data-wow-delay="0.3s">
                                <div className="item-heading">
                                    <span className="name">basic</span>
                                    <div className="count">$125</div>
                                    <em>per month</em>
                                </div>
                                <div className="item-body">
                                    <ul className="list-features">
                                        <li className="active">2 Hosting Accounts</li>
                                        <li className="active">10 FREE Users</li>
                                        <li className="active">600GB Monthly Bandwidth</li>
                                        <li>Complete Analytics</li>
                                        <li>Unlimited Databses</li>
                                    </ul>
                                </div>
                                <div className="item-footer text-center">
                                    <a href="#" className="btn btn-default">sign up now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 vcenter">
                            <div className="plan-item wow zoomIn active" data-wow-delay="0.6s">
                                <div className="item-heading">
                                    <span className="name">popular</span>
                                    <div className="count">$175</div>
                                    <em>per month</em>
                                </div>
                                <div className="item-body">
                                    <ul className="list-features">
                                        <li className="active">8 Hosting Accounts </li>
                                        <li className="active">30 FREE Users</li>
                                        <li className="active">2 TB Monthly Bandwidth </li>
                                        <li className="active">Complete Analytics</li>
                                        <li>Unlimited Support</li>
                                    </ul>
                                </div>
                                <div className="item-footer text-center">
                                    <a href="#" className="btn btn-default">sign up now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4  col-sm-4 vcenter">
                            <div className="plan-item wow zoomIn"  data-wow-delay="0.3s">
                                <div className="item-heading">
                                    <span className="name">premier</span>
                                    <div className="count">$250</div>
                                    <em>per month</em>
                                </div>
                                <div className="item-body">
                                    <ul className="list-features">
                                        <li className="active">25 Hosting Accounts </li>
                                        <li className="active">Unlimited FREE Users </li>
                                        <li className="active">8 TB Monthly Bandwidth </li>
                                        <li className="active">Complete Analytics</li>
                                        <li className="active">Unlimited Storage</li>
                                    </ul>
                                </div>
                                <div className="item-footer text-center">
                                    <a href="#" className="btn btn-default">sign up now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->



        <!-- ========================== -->
        <!-- HOME - LATEST NEWS -->
        <!-- ========================== -->
        <!-- <section className="latest-news-section with-icon with-top-effect clearfix">
            <div className="section-icon"><span className="icon icon-Umbrella"></span></div>
            <div className="container">
                <div className="section-heading">
                    <div className="section-title">latest news</div>
                    <div className="section-subtitle">Lorem ipsum dolor sit amet consectetur elit</div>
                    <div className="design-arrow"></div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-4 wow fadeInUp">
                        <div className="news-item">
                            <div className="meta">
                                <div className="meta-item"><span className="icon icon-Tag"></span>MOBile ui, creative</div>
                                <div className="meta-item"><span className="icon icon-Agenda"></span>26 SEP</div>
                            </div>
                            <div className="image">
                                <a href="#">
                                    <img src="img/news/image1.jpg" alt="" />
                                    <div className="image-content">
                                        <span className="read-more">read more</span>  
                                    </div>
                                </a> 
                            </div> 
                            <div className="user-avatar clearfix">
                                <div className="avatar">
                                    <img src="img/avatars/img1.png" alt="" /> 
                                </div>
                            </div>
                            <div className="news-body">
                                <h5>Lorem ipsum dolor amet consectetur</h5>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elitsed
                                    eiusmod tempor enim minim veniam quis notru exercit ation
                                    Lorem ipsum dolor sit amet.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 wow fadeInUp">
                        <div className="news-item">
                            <div className="meta">
                                <div className="meta-item"><span className="icon icon-Tag"></span>project</div>
                                <div className="meta-item"><span className="icon icon-Agenda"></span>20 SEP</div>
                            </div>
                            <div className="image">
                                <a href="#">
                                    <img src="img/news/image2.jpg" alt="" />
                                    <div className="image-content">
                                        <span className="read-more">read more</span>  
                                    </div>
                                </a> 
                            </div> 
                            <div className="user-avatar clearfix">
                                <div className="avatar">
                                    <img src="img/avatars/img2.png" alt="" /> 
                                </div>
                            </div>
                            <div className="news-body">
                                <h5>eiusmod tempor incididunt labore</h5>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elitsed
                                    eiusmod tempor enim minim veniam quis notru exercit ation
                                    Lorem ipsum dolor sit amet.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 wow fadeInUp">
                        <div className="news-item">
                            <div className="meta">
                                <div className="meta-item"><span className="icon icon-Tag"></span>photography, analysis</div>
                                <div className="meta-item"><span className="icon icon-Agenda"></span>28 SEP</div>
                            </div>
                            <div className="image">
                                <a href="#">
                                    <img src="img/news/image3.jpg" alt="" />
                                    <div className="image-content">
                                        <span className="read-more">read more</span>  
                                    </div>
                                </a> 
                            </div> 
                            <div className="user-avatar clearfix">
                                <div className="avatar">
                                    <img src="img/avatars/img1.png" alt="" /> 
                                </div>
                            </div>
                            <div className="news-body">
                                <h5>liua enim minim veniam quis nostrui</h5>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elitsed
                                    eiusmod tempor enim minim veniam quis notru exercit ation
                                    Lorem ipsum dolor sit amet.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->


        <!-- ========================== -->
        <!-- HOME - SLIDER -->
        <!-- ========================== -->
        <!-- <section className="slider-section dark dark-strong with-bottom-effect">
            <div className="bottom-effect"></div>
            <div className="dark-content">
                <div className="wrap-section-slider enable-owl-carousel" data-single-item="true" >
                    <div className="slide-item">
                        <div className="slider-title">latest from twitter <i className="fa fa-twitter"></i> oscend creative agency</div>
                        <p className="large">Lorem ipsum dolor sit amet consecteur adipiscing elit sed eiusmod tempor ncididue ut labore etor dolore magna aliqua. Ut enim <br />
                            minim veniam qua nostrud exercitat ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                        <div className="time">2 minutes ago</div>
                    </div>
                    <div className="slide-item">
                        <div className="slider-title">latest from twitter <i className="fa fa-twitter"></i> oscend creative agency</div>
                        <p className="large">Lorem ipsum dolor sit amet consecteur adipiscing elit sed eiusmod tempor ncididue ut labore etor dolore magna aliqua. Ut enim <br />
                            minim veniam qua nostrud exercitat ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                        <div className="time">6 minutes ago</div>
                    </div>
                    <div className="slide-item">
                        <div className="slider-title">latest from twitter <i className="fa fa-twitter"></i> oscend creative agency</div>
                        <p className="large">Lorem ipsum dolor sit amet consecteur adipiscing elit sed eiusmod tempor ncididue ut labore etor dolore magna aliqua. Ut enim <br />
                            minim veniam qua nostrud exercitat ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                        <div className="time">12 minutes ago</div>
                    </div>

                </div>
            </div>
        </section> -->


        <!-- ========================== -->
        <!-- HOME - CLIENTS -->
        <!-- ========================== --> */}
        {/* <section className="clients-section clearfix">
            <div className="container">
                <div className="section-heading wow fadeInDown">
                    <div className="section-title">trusted clients</div>
                    <div className="section-subtitle">Lorem ipsum dolor sit amet consectetur elit</div>
                    <div className="design-arrow"></div>
                </div>
            </div>
            <div className="container">
                <div className="list-clients">
                    <div className="client-item wow fadeInLeft">
                        <img src={require("../img/partners/partner-4.png")} alt="" />
                    </div>
                    <div className="client-item wow fadeInLeft">
                        <img src={require("../img/partners/partner-1.png")} alt="" />
                    </div>
                    <div className="client-item wow fadeInRight">
                        <img src={require("../img/partners/partner-3.png")} alt="" />
                    </div> 
                    <div className="client-item wow fadeInRight">
                        <img src={require("../img/partners/partner-2.png")} alt="" />
                    </div>
                </div>
            </div>
        </section> */}

        {/* <!-- ========================== -->
        <!-- FOOTER -->
        <!-- ========================== --> */}
        <footer>
            {/* <!-- ========================== -->
            <!-- BUY SECTION -->
            <!-- ========================== -->
            <!-- <section className="buy-section with-icon">
                <div className="section-icon"><span className="icon icon-Umbrella"></span></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-1 col-sm-9 wow fadeInLeft">
                            <div className="section-text">
                                <div className=" vcenter like">
                                    <span className="icon icon-Like"></span> 
                                </div>
                                <div className="buy-text vcenter">
                                    <div className="top-text">oscend - a creative & multipurpose template</div>
                                    <div className="bottom-text">Lorem ipsum dolor sit amet consectetur elit sed</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-3  wow fadeInRight">
                            <a href="#" className="btn btn-info ">Buy it now</a>
                        </div>
                    </div>
                </div>
            </section> -->
            <!-- ========================== -->
            <!-- FOOTER - SOCIAL -->
            <!-- ========================== --> */}
            {/* <section className="social-section dark dark-strong">
                <div className="container dark-content">
                    <div className="tt">We are social 24/7 - Get in touch</div>
                    <ul className="list-socials">
                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                        <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                        <li><a href="#"><i className="fa fa-dribbble"></i></a></li>
                        <li><a href="#"><i className="fa fa-pinterest-p"></i></a></li>
                    </ul>
                </div>
            </section> */}

            {/* <!-- ========================== -->
            <!-- FOOTER - FOOTER -->
            <!-- ========================== --> */}
            <section className="footer-section">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-md-3 col-sm-3">
                            <h5>about us</h5>
                            <p>Crowned as ASIA’S GREATEST BRAND and ASIA’S GREATEST LEADER With Mr. Sathesh kumar Garu, the recipient of Global Indian Of The Year award, at the helm</p>
                        </div> */}
                        <div className="col-md-3 col-sm-3">
                            <h5>Sitemap</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="footer-nav">
                                        <li><a href="#">About Us</a></li>
                                        <li className="active"><a href="#">Latest News</a></li>
                                        <li><a href="#">Contact us</a></li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="footer-nav">
                                        <li><a href="#">Get Help</a></li>
                                        <li><a href="#">Careers</a></li>
                                        <li><a href="#">FAQ's</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <h5>Contact info</h5>
                            <ul className="contacts-list">
                                <li>
                                    <p><i className="icon icon-House"></i>NO. 2-1-266/1/6, Flat No.201, Plot NO. 6, 2nd Floor, GSI (Sr) Bandlanguda, Nagole, Hyderabad -500068</p>
                                </li>
                                {/* <li>
                                    <p><i className="icon icon-Phone2"></i>+91 8374416741</p>
                                </li> */}
                                <li>
                                    <p><i className="icon icon-Mail"></i><a href="mailto:support@oscend.com">care@perfectlayout.in</a> </p>

                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <h5>newsletter</h5>
                            <p>
                                Subscribe to our newsletter for latest updates 
                                about our creative agency
                            </p>
                            <div className="form-group has-feedback">
                                <input type="email" className="form-control " placeholder="Email address ..." />
                                <i className="icon icon-Mail form-control-feedback"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="copyright-section">
                <p>©2023 <span>PERFECT LAYOUT PRIVATE LIMITED </span>. All Rights Reserved</p>
            </section>
        </footer>

</>
    )
}


export default Home;
