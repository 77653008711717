import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Grid, Paper, Avatar, Box, Button, CssBaseline, TextField, Typography, Stack, Tabs, Tab, Card } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "animate.css/animate.css";
import react, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import WOW from "wowjs";
import { apiURL, Post } from "../../utils/apiCalls";
import { FormikProvider, useFormik, Form, Formik } from 'formik';
import * as Yup from 'yup';
import OtpInput from 'react-otp-input';

const defaultTheme = createTheme();
const rnd = ["sections/home-top-background.jpg", "hand.jpg", "home1.jpg", "area.png"]
const rnd_no = Math.floor(Math.random() * rnd.length)

const ForgotSchema = Yup.object({
  email: Yup.string().required('Email Address is required'),
});

export default function ForgetPassword() {
  const navigate = useNavigate();
const [validotp, setValidOtp] = useState(false);

const [otp, setOtp] = useState('');
const [email, setEmail] = useState('');

  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  const handleForgotLogin = async (values) => {
    if(email) {
    const response = await Post(apiURL + '/api/v1/users/login_otp', {login_id: email}, navigate)
    if(response && response.data && response.data.status) {
      toast.success('OTP Send Successful')
      setValidOtp(true)
    }
    else {
      toast.error(response?.data?.message || 'Please Try Again')
      setValidOtp(false)
    }
}
else {
    toast.error('Please Enter Email')
}
  };

  const handleOtp = async () => {
    if(otp) {
    const response = await Post(apiURL + '/api/v1/users/login_verify_otp', {login_id: email, otp: otp}, navigate)
    if(response && response.data && response.data.status) {
      sessionStorage.setItem('otp_token', response.data.data.token)
      toast.success('OTP Verified Successful')
        navigate("/resetPassword");
    }
    else {
      toast.error(response?.data?.message || 'Please Try Again')
    }
}
else {
    toast.error('Please Enter OTP')
}
  }
  return (
    <ThemeProvider theme={defaultTheme}>
     
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid  item xs={false} sm={4} md={7}
          sx={{
            backgroundImage:
              `url(${require(`../../img/${rnd[rnd_no]}`)})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>    

      <Box sx={{ mt: 1 }} width={'100%'}>
      {/* <Formik onSubmit={handleForgotLogin} initialValues={{ email: "" }} enableReinitialize= {true}>
       {({ handleSubmit, handleChange, values, errors, getFieldProps, touched, setFieldValue }) => ( */}
        <form style={{width:'100%'}}>
        {!validotp ? <TextField  margin="normal"
                // type="email"
                id="email"
                placeholder="Email Address"
                fullWidth
                label=""
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
:
<Box component={'span'} sx={{ mt: 1 }} width={'100%'} justifyContent={'center'} display={'flex'}>
<OtpInput numInputs={4} isInputNum onChange={(e)=>setOtp(e)} value={otp}
                              containerStyle={{ gap: '30px', display: 'flex', alignTtems: 'center', '-moz-box-align': 'center' }}
                              inputStyle={{
                                color: '#001B71', '-webkitTextFillColor': 'black !important', padding: '0px', fontWeight: 600, fontSize: '1rem',
                                borderRadius: '0px', boxSizing: 'content-box', height: '60px', animationName: 'mui-auto-fill-cancel',
                                animationDuration: '10ms', display: 'block', width: '50px', minWidth: '0px', border: '2px solid #BDCEE9'
                              }}
      renderInput={(props) => <input {...props} />}
   />
</Box> }

{!validotp ? <Stack spacing={2} direction={'row'} sx={{mt: 2}}>
              <Button onClick={handleForgotLogin} fullWidth variant="contained"  sx={{ mt: 3, mb: 2 }} >Send OTP</Button>
              </Stack> :

              <Stack spacing={2} direction={'row'} sx={{mt: 2}}>
              <Button onClick={handleOtp} fullWidth variant="contained"  sx={{ mt: 3, mb: 2 }} >Verify OTP</Button>
              </Stack> }
              </form>
               {/* )}
               </Formik> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
