import React, { useEffect } from 'react';
import WOW from 'wowjs';
import { Button, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';

const Contact = () => {
    useEffect(() => {
        const wow = new WOW.WOW({
            live: false
        });
        wow.init();
    }, []);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
<>
<>
  {/* <div id="page-preloader">
    <span className="spinner" />
  </div> */}
  {/* ========================== */}
  {/* Navigation */}
  {/* ========================== */}
  <header className="header scrolling-header">
    <nav
      id="nav"
      className="navbar navbar-default navbar-fixed-top"
      role="navigation"
    >
      <div className="container relative-nav-container">
        <a
          className="toggle-button visible-xs-block"
          data-toggle="collapse"
          data-target="#navbar-collapse"
        >
          <i className="fa fa-navicon" />
        </a>
        <a className="navbar-brand scroll" href="home.html" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
          <img
            className="normal-logo hidden-xs"
            src={require("../img/logo.png")}
            alt="logo"
          />
          <img
            className="scroll-logo hidden-xs"
            src={require("../img/logo-dark.png")}
            alt="logo"
          />
          <img
            className="scroll-logo visible-xs-block"
            src={require("../img/logo.png")}
            alt="logo"
          />
        </a>
        {/* <ul class="nav navbar-nav navbar-right nav-icons wrap-user-control">
                  <li>
                      <a id="search-open" href="#fakelink"><i class="fa fa-search"></i></a>
                  </li>
                  <li class="open-signup">
                      <a href="#fakelink"><i class="fa fa-user"></i></a>
                  </li>
              </ul> */}
        <div className="navbar-collapse collapse floated" id="navbar-collapse">
          <ul className="nav navbar-nav navbar-with-inside clearfix navbar-right with-border">
            <li>
              <a href="#/home">home</a>
              {/* <div class=" wrap-inside-nav">
                              <div class="inside-col">
                                  <ul class="inside-nav">
                                      <li><a href="home.html">Home 1</a></li>
                                      <li><a href="home-2.html">Home 2</a></li>
                                  </ul>
                              </div>
                          </div> */}
            </li>
            {/* <li>
                          <a href="#">Portfolio</a>
                          <div class=" wrap-inside-nav">
                              <div class="inside-col">
                                  <ul class="inside-nav">
                                      <li><a href="portfolio.html">Portfolio</a></li>
                                      <li><a href="portfolio-2.html">Portfolio 2</a></li>
                                      <li><a href="portfolio-3.html">Portfolio 3</a></li>
                                      <li><a href="portfolio-single.html">Portfolio single 1</a></li>
                                      <li><a href="portfolio-single-2.html">Portfolio single 2</a></li>
                                  </ul>
                              </div>
                          </div>
                      </li> */}
            <li>
              <a href="#/about">about</a>
            </li>
            <li>
              {/* <a href="#/project">Our Projects</a> */}
              {/* <Button
        aria-haspopup="true"
        className='menuButton'
        onClick={handleClick}
      >
        Our Projects
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ '.MuiMenu-paper' : {minWidth:'110px'} }}
      >
        <MenuItem component={'a'} href='#/project' onClose={handleClose}>
            <ListItemIcon >
            <AdminPanelSettingsIcon sx={{width:'1.5em', height:'1.5em'}} />
          </ListItemIcon>
          <ListItemText>Admin</ListItemText>
            </MenuItem>
        <MenuItem component={'a'} href='#/projectAgent' onClose={handleClose}>           
            <ListItemIcon>
            <PersonIcon sx={{width:'1.5em', height:'1.5em'}} />
          </ListItemIcon>
          <ListItemText>Agent</ListItemText>
            </MenuItem>
      </Menu> */}
   <Button aria-haspopup="true" href='#/projectLayout'>Our Projects</Button>

            </li>
            <li>
              <a href="#/services">services</a>
            </li>
            {/* <li>
              <a href="#/blog">Blog</a> */}
              {/* <div class=" wrap-inside-nav">
                              <div class="inside-col">
                                  <ul class="inside-nav">
                                      <li><a href="blog.html">Blog</a></li>
                                      <li><a href="blog-single.html">Blog single</a></li>
                                  </ul>
                              </div>
                          </div> */}
            {/* </li> */}
            <li className="active">
              <a href="#/contact">Contacts</a>
              {/* <div class=" wrap-inside-nav">
                              <div class="inside-col">
                                  <ul class="inside-nav">
                                      <li><a href="contact.html">Contacts 1 </a></li>
                                      <li><a href="contact-2.html">Contacts 2 </a></li>
                                  </ul>
                              </div>
                          </div> */}
            </li>
            <li> <Button className='menuButton' href='#/login' target='_blank'> Login In</Button> </li>

          </ul>
        </div>
      </div>
      {/* <div class="navbar-search ">
              <div class="container">
                  <form>
                      <div class="input-group">
                          <input type="text" placeholder="Type your search..." class="form-control" autocomplete="off">
                          <span class="input-group-btn">
                              <button type="reset" class="btn search-close" id="search-close">
                                  <i class="fa fa-close"></i>
                              </button>
                          </span>
                      </div>
                  </form>
              </div>
          </div> */}
    </nav>
  </header>
  {/*./navigation */}
  {/* ========================== */}
  {/* CONTACT - HEADER */}
  {/* ========================== */}
  <section className="top-header ourContectImg countact-us-header with-bottom-effect transparent-effect">
  
    <div className="header-container">
      <div className="header-title">
        {/* <div className="header-icon">
          <span className="icon icon-Wheelbarrow" />
        </div> */}
        <div className="title">contact us</div>
        {/* <em>
          PERFECT LAYOUT is one of the Largest diversified real estate company
        </em> */}
      </div>
    </div>
    {/*container*/}
  </section>
  <section className="steps-section with-icon ">
  <div className="section-icon">
      <span className="icon icon-Umbrella" />
    </div>
  </section>
  <section className="countact-us-section">
    
    <div className="container">
      
      <div className="row">
        <div className="col-md-8">
          <div className="contact-form">
            <div className="form-heading">
              <h3>send a message</h3>
              {/* <em>Lorem ipsum dolor sit amet adipisicing</em> */}
            </div>
            <div className="form-description">
              <p>
                Having Questions? Tell us about your Business, Our experts will
                check all the aspects and call you back to explain how
                RealEstateIndia.com would help you to get quotes for your
                Business.
              </p>
            </div>
            <form
              method="post"
              action="sendEmail.php"
              name="contact-form"
              id="contact-form"
            >
              <div id="response" />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="FIRST NAME"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      placeholder="LAST NAME"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="PHONE NO."
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      placeholder="SUBJECT"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="message"
                      id="message"
                      placeholder="MESSAGE"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <button
                      type="submit"
                      id="submit"
                      className="btn btn-default"
                    >
                      send message
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-12 col-sm-6">
              {/* <div class="contact-map" >
                              <div id="contactMap"></div>
                          </div> */}
            </div>
            <div className="col-md-12 col-sm-6">
              <div className="contact-block">
                <h5>keep in touch</h5>
                <div className="row ">
                  <div className="col-md-12 clearfix">
                    <div className="type-info pull-left">
                      <i className="icon icon-House" />
                      address
                    </div>
                    <div className="info pull-right text-right">
                      <p className="no-margin" style={{fontFamily:"sans-serif"+"important", textAlign:"left"}}>
                      NO. 2-1-266/1/6, Flat No.201, Plot NO. 6, 2nd Floor, GSI (Sr) Bandlanguda, Nagole, Hyderabad -500068
                      </p>
                    </div>
                  </div>
                  {/* <div className="col-md-12 clearfix">
                    <div className="type-info pull-left">
                      <i className="icon icon-Phone2" />
                      phone
                    </div>
                    <div className="info pull-right text-right">
                      <p className="no-margin">+91 8374416741</p>
                    </div>
                  </div> */}
                  <div className="col-md-12 clearfix">
                    <div className="type-info pull-left">
                      <i className="icon icon-Mail" />
                      email
                    </div>
                    <div className="info pull-right text-right">
                      <p className="no-margin">care@perfectlayout.in</p>
                    </div>
                  </div>
                  {/* <div class="col-md-12 clearfix">
                                      <div class="type-info pull-left">
                                          <i class="icon icon-Mouse"></i>
                                          Skype Chat
                                      </div>
                                      <div class="info pull-right text-right">
                                          <p class="no-margin">oscend.live</p>
                                      </div>
                                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* ========================== */}
  {/* FOOTER */}
  {/* ========================== */}
  <footer>
    {/* ========================== */}
    {/* BUY SECTION */}
    {/* ========================== */}
    {/* <section class="buy-section with-icon">
          <div class="section-icon"><span class="icon icon-Umbrella"></span></div>
          <div class="container">
              <div class="row">
                  <div class="col-md-8 col-md-offset-1 col-sm-9 wow fadeInLeft">
                      <div class="section-text">
                          <div class=" vcenter like">
                              <span class="icon icon-Like"></span> 
                          </div>
                          <div class="buy-text vcenter">
                              <div class="top-text">oscend - a creative & multipurpose template</div>
                              <div class="bottom-text">Lorem ipsum dolor sit amet consectetur elit sed</div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3 col-sm-3  wow fadeInRight">
                      <a href="#" class="btn btn-info ">Buy it now</a>
                  </div>
              </div>
          </div>
      </section> */}
    {/* ========================== */}
    {/* FOOTER - SOCIAL */}
    {/* ========================== */}
    {/* <section className="social-section dark dark-strong">
      <div className="container dark-content">
        <div className="tt">We are social 24/7 - Get in touch</div>
        <ul className="list-socials">
          <li>
            <a href="#">
              <i className="fa fa-twitter" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-facebook" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-linkedin" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-google-plus" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-dribbble" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-pinterest-p" />
            </a>
          </li>
        </ul>
      </div>
    </section> */}
    {/* ========================== */}
    {/* FOOTER - FOOTER */}
    {/* ========================== */}
    <section className="footer-section">
      <div className="container">
        <div className="row">
          {/* <div className="col-md-3 col-sm-3">
            <h5>about us</h5>
            <p>
              Crowned as ASIA’S GREATEST BRAND and ASIA’S GREATEST LEADER With
              Mr. Sathesh kumar Garu, the recipient of Global Indian Of The Year
              award, at the helm
            </p>
          </div> */}
          <div className="col-md-3 col-sm-3">
            <h5>Sitemap</h5>
            <div className="row">
              <div className="col-md-6">
                <ul className="footer-nav">
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li className="active">
                    <a href="#">Latest News</a>
                  </li>
                  <li>
                    <a href="#">Contact us</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="footer-nav">
                  <li>
                    <a href="#">Get Help</a>
                  </li>
                  <li>
                    <a href="#">Careers</a>
                  </li>
                  <li>
                    <a href="#">FAQ's</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <h5>Contact info</h5>
            <ul className="contacts-list">
              <li>
                <p>
                  <i className="icon icon-House" />
                  NO. 2-1-266/1/6, Flat No.201, Plot NO. 6, 2nd Floor, GSI (Sr) Bandlanguda, Nagole, Hyderabad -500068
                </p>
              </li>
              {/* <li>
                <p>
                  <i className="icon icon-Phone2" />
                  +91 8374416741
                </p>
              </li> */}
              <li>
                <p>
                  <i className="icon icon-Mail" />
                  <a href="mailto:support@oscend.com">
              care@perfectlayout.in
                  </a>{" "}
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-3">
            <h5>newsletter</h5>
            <p>
              Subscribe to our newsletter for latest updates about our creative
              agency
            </p>
            <div className="form-group has-feedback">
              <input
                type="email"
                className="form-control "
                placeholder="Email address ..."
              />
              <i className="icon icon-Mail form-control-feedback" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="copyright-section">
      <p>
        ©2023 <span>PERFECT LAYOUT PRIVATE LIMITED </span>. All Rights Reserved
      </p>
    </section>
  </footer>
</>
</>)
}
export default Contact;