
import React, { useEffect, useState, useRef } from 'react';
import WOW from 'wowjs';
import 'animate.css/animate.css';
import '../../css/layout/layout1.css'
import '../../css/layout/layout2.css'
import '../../css/layout/layout3.css'
import '../../css/layout/layout4.css'
import '../../css/layout/layout5.css'
import '../../css/layout/layout6.css'
import '../../css/layout/layout7.css'
import '../../css/layout/layout8.css'
import '../../css/layout/layout9.css'
import '../../css/layout/layout10.css'
import '../../css/layout/layout11.css'
import '../../css/layout/layout12.css'

import { DialogActions, Dialog, DialogTitle, Menu, MenuItem, ListItemIcon, ListItemText, TextField, CircularProgress, DialogContent, Divider, Grid, CardHeader, Card, CardContent, CardActions, Button, Stack, Typography, Popper, Paper, Box, ClickAwayListener, Grow } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { apiURL, Get, Post, Put } from '../../../utils/apiCalls';
import {
  WhatsappShareButton
} from "react-share";
import { toast } from 'react-toastify';
import Iconify from '../../../js/Iconify.js';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import Label from '../../../utils/Label.tsx';
import { makeStyles } from '@mui/styles';
import { capitalCase } from 'change-case';

const useStyles = makeStyles((theme) => {
  return {
    popper: {
      zIndex: 1
    },
    arrow: {
      position: "absolute",
      fontSize: 7,
      width: "3em",
      height: "3em",
      top: '-6px',
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
        width: '100%',
        height: '100%',
        color: 'white',
        transform: 'rotate(45deg)',
        background: 'white'
      },
    },
    arrowBottom: {
      position: "absolute",
      fontSize: 7,
      width: "3em",
      height: "3em",
      bottom: '0px',
      marginBottom: '-10px',
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
        width: '100%',
        height: '100%',
        color: 'white',
        transform: 'rotate(45deg)',
        background: 'white'
      },
    }
  };
});

const LayoutImageAdmin = () => {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState({ open: false, plot: {}, index: 0 })
  const anchorEl = useRef([])
  const [arrowRef, setArrowRef] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const [plots, setPlots] = useState([]);
  const [total, setTotal] = useState({});

  const prevOpen = useRef(openDialog.open);
  const [openPlot, setOpenPlot] = useState(false)
  // const [row, setRow] = useState({brochure:'', flyer:''});

  const getPlots = async () => {
    const response = await Get(apiURL + '/api/v1/plots/', id)
    if (response && response.data && response.data.status) {
      setPlots(response.data.data)
      setTotal({ ...response.data.count, ...response.data.project_details[0] })
    }
  }

  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const open = Boolean(anchorElMenu);
  const handleClick = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElMenu(null);
  };

  useEffect(() => {
    const wow = new WOW.WOW({
      live: false
    });
    wow.init();
    if (id) {
      getPlots()
    }
    else {
      navigate("/dashboard/projectAdmin")
    }
  }, []);

  useEffect(() => {
    if (prevOpen.current === true && openDialog.open === false) {
      if (anchorEl.current[openDialog['index']])
        anchorEl.current[openDialog['index']].focus();
    }
    prevOpen.current = openDialog.open;

  }, [openDialog.open]);

  const imageRef = useRef(null);

  useEffect(() => {
    // Scroll the image into the center of the viewport
    if (imageRef.current) {
      const container = imageRef.current.parentElement;
      const scrollLeft = (imageRef.current.offsetWidth - container.offsetWidth) / 2;
      container.scrollTo({ left: scrollLeft, behavior: 'smooth' });
    }
  }, []); // Empty dependency array ensures the effect runs only once after mount

  const handleOpenDialog = (e, data, i) => {
    setOpenDialog((prev) => ({ open: true, plot: data, index: i }));
  }

  const handleCloseDialog = (e) => {
    if (anchorEl.current[openDialog['index']] && anchorEl.current[openDialog['index']].contains(e.target)) {
      return;
    }
    //   if(prevOpen) {
    anchorEl.current[openDialog['index']] = ''
    setOpenDialog({ open: !prevOpen, plot: {}, index: 0 })
    //   }
  }

  const handleClickPlot = (status) => {
    setOpenDialog((prev) => ({ ...prev, open: false, index: 0, change_status: status }))
    setOpenPlot(true)
  }

  const handleClosePlot = () => {
    setOpenPlot(false)
  }

  const handleSubmit = async () => {
    const response = await Put(apiURL + "/api/v1/", 'plots', {
      ...openDialog.plot, status: openDialog['change_status'],
    })
    if (response && response.data && response.data.status) {
      toast.success('Plot Successfully Booked!')
      handleClosePlot()
      getPlots()
    }
  }

  const StatusColor = (status) => {
    switch (status) {
      case 'allocated':
        return 'error'
        break
      case 'unallocated':
        return 'success'
        break
      case 'registed':
        return 'primary'
        break
      default:
        return 'default'
    }
  };

  const StatusCase = (status) => {
    switch (status) {
      case 'allocated':
        return 'Allocated'
        break
      case 'unallocated':
        return 'Un Allocated'
        break
      case 'registed':
        return 'Registed'
        break
      default:
        return 'default'
    }
  };

  const handleChanePlot = (e) => {
    const list = { ...openDialog.plot }
    const { id, value } = e.target
    list[id] = value

    setOpenDialog((prevState) => ({ ...prevState, plot: list }))
  }

  const handleChange = (e) => {
    const { id, value } = e.target
    const list = [...plots]
    list[openDialog['index']][id] = value

    setPlots(list)
  }

  const handleSubmitEdit = async (e) => {
    const response = await Put(apiURL + "/api/v1/", 'plots', { ...plots[openDialog['index']] })
    if (response && response.data && response.data.status) {
      toast.success('Plot Successfully Updated!')
      handleCloseDialog(e)
      getPlots()
    }
  }

  const transform = () => {
    return {
      transform: 'rotate(-90deg)',
      transformOrigin: 'left top',
      whiteSpace: 'nowrap',
      position: 'absolute',
    }
  }

  const handleAlignItems = (css) => {
    switch (css) {
      case 'layout2_239':
        return {
          alignItems: 'center', justifyContent: 'start', height: '100%'
        }
      case 'layout2_285':
        return {
           ...transform(), ml: '5px', top: '100%', left:0
        }
      case 'layout2_272':
        return {
           ...transform(), justifyContent: 'end', top: '50%', left:0
        }
      case 'layout2_315':
        return {
           ...transform(), justifyContent: 'start', top: '100%', left:0
        }
      case 'layout2_334':
        return {
           ...transform(), justifyContent: 'center', top: '50%', left:0
        }
      case 'layout2_460':
        return {
           ...transform(), justifyContent: 'end', top: '35%', left:0
        }
      case 'layout2_452':
        return {
           ...transform(), top: '100%', left:0
        }
      case 'layout2_444':
        return {
          fontSize: '10px', height: '100%'
        }
      case 'layout4_79':
        return {
          alignItems: 'start', justifyContent: 'center', marginLeft: '15px',  ...transform(), width: '100%', top: '100%', left:0
        }
      case 'layout4_69':
        return {
           ...transform(), alignItems: 'center', justifyContent: 'center', width: '100%', top: '50%', left:'25%'
        }
      case 'layout3_12':
        return {
          alignItems: 'end', justifyContent: 'center', height: '100%'
        }
      case 'layout3_121':
        return {
           ...transform(), alignItems: 'end', justifyContent: 'center', top: '50%', left:0
        }
      case 'layout3_261':
        return {
           ...transform(), alignItems: 'end', justifyContent: 'center', top: '50%', left:0
        }
      case 'layout3_232':
        return {
          alignItems: 'start', justifyContent: 'center', height: '100%'
        }
      case 'layout3_284':
        return {
           alignItems: 'start', justifyContent: 'center', width: '100%', height: '100%'
        }
      case 'layout3_300':
        return {
          alignItems: 'start', justifyContent: 'center', height: '100%'
        }
      case 'layout3_313':
        return {
          alignItems: 'start', justifyContent: 'center', height: '100%'
        }
      case 'layout3_314':
        return {
           ...transform(), alignItems: 'end', justifyContent: 'center', width: '100%', top: '50%', left:0
        }
      case 'layout3_299':
        return {
          alignItems: 'end', justifyContent: 'center', height: '100%'
        }
      default:
        return {
          alignItems: 'center', justifyContent: 'center', height: '100%'
        }
    }
  }

  return (
    <>
      {/* <!-- ========================== -->
        <!-- BLOG - HEADER -->
        <!-- ========================== --> */}
      <section className="top-header blog-header with-bottom-effect transparent-effect dark dark-strong">
        <div className="bottom-effect"></div>
        <div className="header-container wow fadeInUp">
          <div className="header-title">
            <div className="header-icon"><span className="icon icon-Wheelbarrow"></span></div>
            <div className="title">{total.name}</div>

          </div>
        </div>

      </section>

      {total['img'] ? <section>

        <Stack spacing={2} direction={'row'} sx={{ marginBottom: "20px", justifyContent: 'center' }}>
          <Grid container spacing={2} sx={{ justifyContent: 'center', width: '80% !important' }}>
            <Grid item lg={2} md={2} sm={3} xs={6} >
              <Card elevation={12} sx={{ borderRadius: '5px', backgroundColor: 'green', color: 'white', width: '100%' }}>
                <CardContent sx={{ pb: '0px !important' }}>
                  <Typography sx={{ color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
                    Avaliable
                  </Typography>
                  <Typography sx={{ color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
                    {total['unallocated']}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={2} md={2} sm={3} xs={6} >
              <Card elevation={12} sx={{ borderRadius: '5px', backgroundColor: 'red', color: 'white', width: '100%' }} >
                <CardContent sx={{ pb: '0px !important' }}>
                  <Typography sx={{ color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
                    Allocated
                  </Typography>
                  <Typography sx={{ color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
                    {total['allocated']}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={2} md={2} sm={3} xs={6} >
              <Card elevation={12} sx={{ borderRadius: '5px', backgroundColor: '#2196f3', color: 'white', width: '100%' }} >
                <CardContent sx={{ pb: '0px !important' }}>
                  <Typography sx={{ color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
                    Registed
                  </Typography>
                  <Typography sx={{ color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
                    {total['registed']}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/* <Grid item lg={2} md={2} sm={3} xs={6} >
    <Card  elevation={12} sx={{borderRadius:'5px', backgroundColor: '#b28704', color: 'white', width: '100%'}} >
      <CardContent sx={{pb: '0px !important'}}>
        <Typography sx={{  color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
         Res.
        </Typography>
        <Typography sx={{  color: 'white',  textAlign: 'center' }} color="text.secondary" gutterBottom>
        {total['total']}
        </Typography>
      </CardContent>
    </Card>
    </Grid> */}
            <Grid item lg={2} md={2} sm={3} xs={6} >
              <Card elevation={12} sx={{ borderRadius: '5px', backgroundColor: '#673ab7', color: 'white', width: '100%' }} >
                <CardContent sx={{ pb: '0px !important' }}>
                  <Typography sx={{ color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
                    No of Plots
                  </Typography>
                  <Typography sx={{ color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
                    {total['total']}
                  </Typography>
                </CardContent>
              </Card>
            </Grid></Grid>
        </Stack>

        <Button href={'#/dashboard/projectAdmin'} startIcon={<ArrowBackIosNewIcon sx={{ width: 30, height: 30 }} />} >Back to Project List</Button>

        <Card >
          <CardHeader
            action={
              <Stack spacing={2} direction={'row'}>
                <Button href={total['brochure']} download={'Brochure'} target="_blank" rel="noreferrer" startIcon={<Iconify icon='material-symbols:download-sharp' sx={{ width: 30, height: 30 }} />}>Brochure</Button>
                <Button href={total['flyer']} download={'Flyer'} target="_blank" rel="noreferrer" startIcon={<Iconify icon='material-symbols:download-sharp' sx={{ width: 30, height: 30 }} />}>Flyers</Button>

                <WhatsappShareButton url='https://perfectlayout.in/' title={`Check out our  brochure and flyer to Know more about our Project:

Brochure URL:${total['brochure']} 
Flyer URL: ${total['flyer']}

Feel free to reach out if you have any questions or need more information.

Team,
Perfect layout`} >


                  <Button startIcon={<Iconify icon='ic:baseline-whatsapp' sx={{ width: 30, height: 30 }} />}>Share</Button>
                </WhatsappShareButton>
              </Stack>
            }
          />
           <div id="container">
            {plots && plots.length > 0 ? plots.map((data, i) => (
              <div key={i} className={`${total['css']}_${data['plot_id']}`} style={{ backgroundColor: data['status'] === 'unallocated' ? 'green' : data['status'] === 'allocated' ? 'red' : '#2196f3' }} ref={(e) => anchorEl.current[i] = e} onClick={(e) => handleOpenDialog(e, data, i)}><Typography sx={{ ...handleAlignItems(`${total['css']}_${data['plot_id']}`), display: 'flex', fontSize: total['css'] === 'layout1' ? 'inherit' : '14px' }}> {data['plot_no']} </Typography></div>
            )) : null}
            <img
              className={total['css']}
              src={total['img']} // Replace with the path to your image
              //  src={require('../../../img/layoutImages/layoutimg3.jpg')} // Replace with the path to your image
              alt="project"
              ref={imageRef}
            />
          </div> 
{/* 
<div id="container">
           {Array.from(Array(157)).map((_, index) => (
            <div key={index} className={`layout12_${index}`}  style={{backgroundColor: 'red' }} ><Typography  sx={{alignItems:'center', justifyContent:'center', height:'100%', display:'flex',fontSize: "14px"}}>{index}</Typography></div>
            ))}
            <img
    className='layout12'
       src={require('../../../img/layoutImages/layoutimg12.png')} // Replace with the path to your image
          alt="example"
        />
        </div> */}
        </Card>
      </section> : <CircularProgress />}

      {/* <Stack spacing={2} direction={'row'} justifyContent={'center'}>
 <Stack spacing={2}>
    <span style={{backgroundColor:'red', borderRadius:'10px', height:'10px'}}></span>
 <Typography >Allocated</Typography>
 </Stack>
 <Stack spacing={2}>
 <span style={{backgroundColor:'green', borderRadius:'10px', height:'10px'}}></span>
 <Typography >Un Allocated</Typography>
 </Stack></Stack> */}

      {/* {/* <!-- ========================== -->
        <!-- BLOG - CONTENT -->
        <!-- ========================== --> */}

      <Popper open={openDialog.open} anchorEl={anchorEl.current[openDialog['index']]} transition
        // sx={{  ...Popper_style }}
        className={classes.popper}
        // overflow: 'inherit', minWidth: { xs: 'auto', sm: 'auto' }, maxWidth: { xs: '40%', sm: '50%' }, height: { xs: '20%', sm: '30%' },
        placement="top"
        disablePortal={true}
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'scrollParent',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'scrollParent',
              padding: 8,
            },
          },
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} timeout={350} >
            <Paper elevation={12} sx={{ boxShadow: 'none' }}>
              <ClickAwayListener onClickAway={handleCloseDialog}>
                {/* <> */}
                <Paper elevation={10} sx={{ p: 3 }}>
                  <Box component={'span'} className={placement === 'top' ? classes.arrowBottom : classes.arrow} ref={(e) => setArrowRef(e)} />

                  <div style={{ position: 'relative', justifyContent: 'center', display: 'flex' }}>
                    <Stack spacing={2}>
                      <Stack spacing={2} direction={'row'}>
                        {/* <h4>id:{openDialog.plot['id']}</h4> */}
                        <h4>Sector:</h4>
                        {/* <h4>{openDialog.plot['sector']}</h4> */}
                        <TextField id='sector' variant='standard' sx={{ maxWidth: '6em', ml: "2em !important" }} size="small" value={openDialog.plot['sector']} onChange={handleChange} />
                      </Stack>
                      <Stack spacing={2} direction={'row'}>
                        <h4>Plot No:</h4>
                        {/* <h4>{openDialog.plot['plot_no']}</h4> */}
                        <TextField id='plot_no' variant='standard' sx={{ maxWidth: '6em', ml: "2em !important" }} size="small" value={openDialog.plot['plot_no']} onChange={handleChange} />

                      </Stack>
                      <Stack spacing={2} direction={'row'}>
                        <h4>Plot Area:</h4>
                        {/* <h4>{openDialog.plot['plot_area']}</h4> */}
                        <TextField id='plot_area' variant='standard' sx={{ maxWidth: '6em' }} size="small" value={openDialog.plot['plot_area']} onChange={handleChange} />

                      </Stack>
                      <Stack spacing={2} direction={'row'}>
                        <h4>Facing:</h4>
                        {/* <h4>{openDialog.plot['facing']}</h4> */}
                        <TextField id='facing' variant='standard' sx={{ maxWidth: '6em', ml: "2em !important" }} size="small" value={openDialog.plot['facing']} onChange={handleChange} />

                      </Stack>
                      {/* <Stack spacing={2} direction={'row'}>
                            <h4>Status:</h4>
                            <h4>{openDialog.plot['status']}</h4>
                        </Stack> */}
                    </Stack>
                  </div>
                  <Stack spacing={2} marginTop={2.5}>
                    {openDialog.plot['status'] === 'allocated' ? null : <Button sx={{ width: '100%', mt: 2, color: 'red' }} variant='outlined' onClick={() => handleClickPlot('allocated')}>Book</Button>}
                    {openDialog.plot['status'] === 'registed' ? null : <Button sx={{ width: '100%', mt: 2, color: '#2196f3' }} variant='outlined' onClick={() => handleClickPlot('registed')}>Register</Button>}
                    {openDialog.plot['status'] === 'unallocated' ? null : <Button sx={{ width: '100%', mt: 2, color: 'green' }} variant='outlined' onClick={() => handleClickPlot('unallocated')}>UnAllocate</Button>}
                    <Button variant='contained' sx={{ width: '100%', mt: 2 }} onClick={handleSubmitEdit}>Submit</Button>
                  </Stack>
                </Paper>
              </ClickAwayListener>
            </Paper>

          </Grow>
        )}
      </Popper>

      <Dialog maxWidth={'sm'} open={openPlot} onClose={handleClosePlot} fullWidth>
        <DialogTitle>Plot Section</DialogTitle>
        <Divider />
        <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <Stack spacing={2}>
            <Stack spacing={2} direction={'row'}>
              <h4>Sector:</h4>
              <h4>{openDialog.plot['sector']}</h4>
            </Stack>
            <Stack spacing={2} direction={'row'}>
              <h4 style={{ display: 'flex', alignItems: 'center' }}>Plot No:</h4>
              {/* <h4>{openDialog.plot['plot_no']}</h4> */}
              <TextField id="plot_no" label="" variant="standard" value={openDialog.plot['plot_no']} onChange={handleChanePlot} />
            </Stack>
            <Stack spacing={2} direction={'row'}>
              <h4>Plot Area:</h4>
              <h4>{openDialog.plot['plot_area']}</h4>
            </Stack>
            <Stack spacing={2} direction={'row'}>
              <h4 style={{ display: 'flex', alignItems: 'center' }}>Facing:</h4>
              {/* <h4>{openDialog.plot['facing']}</h4> */}
              <TextField id="facing" label="" variant="standard" value={openDialog.plot['facing']} onChange={handleChanePlot} />
            </Stack>
            <Stack spacing={2} direction={'row'}>
              <h4>Status:</h4>
              {/* <h4>{openDialog.plot['status']}</h4> */}
              <Label variant="ghost" color={StatusColor(openDialog.plot['status'])}>{capitalCase(StatusCase(openDialog.plot['status']))}</Label>

            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          {openDialog['change_status'] === 'allocated' ? <Button sx={{ color: 'red' }} variant='outlined' onClick={handleSubmit}>Book</Button> : null}
          {openDialog['change_status'] === 'registed' ? <Button sx={{ color: '#2196f3' }} variant='outlined' onClick={handleSubmit}>Register</Button> : null}
          {openDialog['change_status'] === 'unallocated' ? <Button sx={{ color: 'green' }} variant='outlined' onClick={handleSubmit}>UnAllocate</Button> : null}
        </DialogActions>
      </Dialog>

      {/* <!-- ========================== -->
        <!-- FOOTER -->
        <!-- ========================== -->  */}
      {/* <footer>
          
            <section className="social-section dark dark-strong">
                <div className="container dark-content">
                    <div className="tt">We are social 24/7 - Get in touch</div>
                    <ul className="list-socials">
                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                        <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                        <li><a href="#"><i className="fa fa-dribbble"></i></a></li>
                        <li><a href="#"><i className="fa fa-pinterest-p"></i></a></li>
                    </ul>
                </div>
            </section>

          
            <section className="footer-section">
                <div className="container">
                    <div className="row">
                       
                        <div className="col-md-3 col-sm-3">
                            <h5>Sitemap</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="footer-nav">
                                        <li><a href="#">About Us</a></li>
                                        <li className="active"><a href="#">Latest News</a></li>
                                        <li><a href="#">Contact us</a></li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="footer-nav">
                                        <li><a href="#">Get Help</a></li>
                                        <li><a href="#">Careers</a></li>
                                        <li><a href="#">FAQ's</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <h5>Contact info</h5>
                            <ul className="contacts-list">
                                <li>
                                    <p><i className="icon icon-House"></i>NO. 2-1-266/1/6, Flat No.201, Plot NO. 6, 2nd Floor, GSI (Sr) Bandlanguda, Nagole, Hyderabad -500068, 500068</p>
                                </li>
                                <li>
                                    <p><i className="icon icon-Phone2"></i>+91 8374416741</p>
                                </li>
                                <li>
                                    <p><i className="icon icon-Mail"></i><a href="mailto:support@oscend.com">admin@perfectlayout.com</a> </p>

                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <h5>newsletter</h5>
                            <p>
                                Subscribe to our newsletter for latest updates 
                                about our creative agency
                            </p>
                            <div className="form-group has-feedback">
                                <input type="email" className="form-control " placeholder="Email address ..." />
                                <i className="icon icon-Mail form-control-feedback"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="copyright-section">
                <p>©2023 <span>PERFECT LAYOUT PRIVATE LIMITED </span>. All Rights Reserved</p>
            </section>
        </footer> */}
    </>
  )
};

export default LayoutImageAdmin;