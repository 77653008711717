
import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css/animate.css';
import './css/Project.css'
import { useNavigate } from 'react-router-dom';
import {  Menu, MenuItem, ListItemIcon, ListItemText, TextField, Card, CardMedia, CardContent, Grid, Button, CardActions, Paper, TableHead, Container, Typography, IconButton, Stack } from '@mui/material';
import { apiURL, GetAll, Post } from '../utils/apiCalls';
import Iconify from '../js/Iconify';
import {
    WhatsappShareButton
  } from "react-share";
  import { toast } from 'react-toastify';
  import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
  import PersonIcon from '@mui/icons-material/Person';
  
const ProjectLogin = () => {
const [data, setData] = useState({email:'', password: ''});
const navigate = useNavigate();

    useEffect(() => {
        const wow = new WOW.WOW({
            live: false
        });
        wow.init();
    }, []);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

      const handleLogin = async () => {
        if(data['email'] && data['password']) {
        const response = await Post(apiURL + '/api/v1/projects/login', {...data})
        if(response && response.data && response.data.status) {
        toast.success('Login Successful');
           navigate('/projectAdmin')
        }
        else {
            toast.error('Please Try Again');
        }
    }
    else {
        toast.error('Please Enter Email or Password');
    }
      }

return (
    <>
        {/* // <!-- ========================== -->
        // <!-- Navigation -->
        // <!-- ========================== --> */}
        <header className="header scrolling-header">
            <nav id="nav" className="navbar navbar-default navbar-fixed-top" role="navigation">
                <div className="container relative-nav-container">
                    <a className="toggle-button visible-xs-block" data-toggle="collapse" data-target="#navbar-collapse">
                        <i className="fa fa-navicon"></i>
                    </a>
                    <a className="navbar-brand scroll" href="home.html" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <img className="normal-logo hidden-xs" src={require("../img/logo.png")} alt="logo" />
                        <img className="scroll-logo hidden-xs" src={require("../img/logo-dark.png")} alt="logo" />
                        <img className="scroll-logo visible-xs-block" src={require("../img/logo.png")} alt="logo" />
                    </a>
                   
                    <div className="navbar-collapse collapse floated" id="navbar-collapse">
                        <ul className="nav navbar-nav navbar-with-inside clearfix navbar-right with-border"> 
                            <li>
                                <a href="#/home">home</a>                            
                            </li>
                            
                            <li>
                                <a href="#/about">about</a>
                            </li>
                            <li className="active">
                                {/* <a href="#/project">Our Projects</a> */}
                                <Button
        aria-haspopup="true"
        className='menuButton'
        onClick={handleClick}
      >
        Our Projects
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ '.MuiMenu-paper' : {minWidth:'110px'} }}
      >
        <MenuItem component={'a'} href='#/project' onClose={handleClose}>
            <ListItemIcon >
            <AdminPanelSettingsIcon sx={{width:'1.5em', height:'1.5em'}} />
          </ListItemIcon>
          <ListItemText >Admin</ListItemText>
            </MenuItem>
        <MenuItem component={'a'} href='#/projectAgent' onClose={handleClose}>           
            <ListItemIcon>
            <PersonIcon sx={{width:'1.5em', height:'1.5em'}} />
          </ListItemIcon>
          <ListItemText>ASSOCIATE</ListItemText>
            </MenuItem>
      </Menu>
                            </li>
                            <li><a href="#/services">services</a></li>
                            {/* <li >
                                <a href="#/project">Blog</a>
                               
                            </li> */}
                            <li>
                               <a  href="#/contact">Contacts</a>                               
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>

        {/* <!-- ========================== -->
        <!-- BLOG - HEADER -->
        <!-- ========================== --> */}
        <section className="top-header blog-header with-bottom-effect transparent-effect dark dark-strong">
            <div className="bottom-effect"></div>
            <div className="header-container wow fadeInUp">	
                <div className="header-title">
                    <div className="header-icon"><span className="icon icon-Wheelbarrow"></span></div>
                    <div className="title">Our Projects Admin</div>             
                </div>
            </div>
            
        </section>  

<section>

<Container sx={{mb:2}} maxWidth={'lg'}>
    <div className="section-title" style={{ marginBottom:'20px' }}>Please Login For Project List</div>

    <Card elevation={8} sx={{p: 1.5}} >
      <CardContent>
        <Stack spacing={2}>
        <TextField type='email' id='email' placeholder='Email Address' fullWidth label="" value={data.email} variant="outlined"  onChange={(e)=> setData((prev)=> ({...prev, [e.target.id]: e.target.value}) )} />
        <TextField id='password' placeholder='Password' label="" fullWidth value={data.password} variant="outlined"  onChange={(e)=> setData((prev)=> ({...prev, [e.target.id]: e.target.value}) )} />
        </Stack >
      </CardContent>
      <CardActions>
        <Stack spacing={3} direction={'row'} width={'100%'} justifyContent={'end'}>
        <Button  variant='contained'  onClick={handleLogin}>Login as Admin</Button>
        {/* <Typography  align='center'>OR</Typography>
        <Button href='#/projectAgent' fullWidth variant='outlined' >Login as Agent</Button> */}
        </Stack>
      </CardActions>
    </Card>
    </Container>

</section>


        {/* <!-- ========================== -->
        <!-- BLOG - CONTENT -->
        <!-- ========================== -->
    

        <!-- ========================== -->
        <!-- FOOTER -->
        <!-- ========================== --> */}
        <footer>
            {/* <!-- ========================== -->
            <!-- BUY SECTION -->
            <!-- ========================== --> */}
            {/* <!-- <section className="buy-section with-icon">
                <div className="section-icon"><span className="icon icon-Umbrella"></span></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-1 col-sm-9 wow fadeInLeft">
                            <div className="section-text">
                                <div className=" vcenter like">
                                    <span className="icon icon-Like"></span> 
                                </div>
                                <div className="buy-text vcenter">
                                    <div className="top-text">oscend - a creative & multipurpose template</div>
                                    <div className="bottom-text">Lorem ipsum dolor sit amet consectetur elit sed</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-3  wow fadeInRight">
                            <a href="#" className="btn btn-info ">Buy it now</a>
                        </div>
                    </div>
                </div>
            </section> --> */}
            {/* <!-- ========================== -->
            <!-- FOOTER - SOCIAL -->
            <!-- ========================== --> */}
            {/* <section className="social-section dark dark-strong">
                <div className="container dark-content">
                    <div className="tt">We are social 24/7 - Get in touch</div>
                    <ul className="list-socials">
                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                        <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                        <li><a href="#"><i className="fa fa-dribbble"></i></a></li>
                        <li><a href="#"><i className="fa fa-pinterest-p"></i></a></li>
                    </ul>
                </div>
            </section> */}

            {/* <!-- ========================== -->
            <!-- FOOTER - FOOTER -->
            <!-- ========================== --> */}
            <section className="footer-section">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-md-3 col-sm-3">
                            <h5>about us</h5>
                            <p>Crowned as ASIA’S GREATEST BRAND and ASIA’S GREATEST LEADER With Mr. Sathesh kumar Garu, the recipient of Global Indian Of The Year award, at the helm</p>
                        </div> */}
                        <div className="col-md-3 col-sm-3">
                            <h5>Sitemap</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="footer-nav">
                                        <li><a href="#">About Us</a></li>
                                        <li className="active"><a href="#">Latest News</a></li>
                                        <li><a href="#">Contact us</a></li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="footer-nav">
                                        <li><a href="#">Get Help</a></li>
                                        <li><a href="#">Careers</a></li>
                                        <li><a href="#">FAQ's</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <h5>Contact info</h5>
                            <ul className="contacts-list">
                                <li>
                                    <p><i className="icon icon-House"></i>NO. 2-1-266/1/6, Flat No.201, Plot NO. 6, 2nd Floor, GSI (Sr) Bandlanguda, Nagole, Hyderabad -500068, 500068</p>
                                </li>
                                <li>
                                    <p><i className="icon icon-Phone2"></i>+91 8374416741</p>
                                </li>
                                <li>
                                    <p><i className="icon icon-Mail"></i><a href="mailto:support@oscend.com">admin@perfectlayout.com</a> </p>

                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <h5>newsletter</h5>
                            <p>
                                Subscribe to our newsletter for latest updates 
                                about our creative agency
                            </p>
                            <div className="form-group has-feedback">
                                <input type="email" className="form-control " placeholder="Email address ..." />
                                <i className="icon icon-Mail form-control-feedback"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="copyright-section">
                <p>©2023 <span>PERFECT LAYOUT PRIVATE LIMITED </span>. All Rights Reserved</p>
            </section>
        </footer>
       </>
)
};

export default ProjectLogin;