import React, { useEffect } from 'react';
import WOW from 'wowjs';
import 'animate.css/animate.css';
import Tables from './Table';
import { Divider } from '@mui/material';
import { Button, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';

const Services = () => {
    useEffect(() => {
        const wow = new WOW.WOW({
            live: false
        });
        wow.init();
    }, []);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
       <>
        <header className="header scrolling-header">
            <nav id="nav" className="navbar navbar-default navbar-fixed-top" role="navigation">
                <div className="container relative-nav-container">
                    <a className="toggle-button visible-xs-block" data-toggle="collapse" data-target="#navbar-collapse">
                        <i className="fa fa-navicon"></i>
                    </a>
                    <a className="navbar-brand scroll" href="home.html" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <img className="normal-logo hidden-xs" src={require('../../img/logo.png')} alt="logo" />
            <img className="scroll-logo hidden-xs" src={require('../../img/logo-dark.png')} alt="logo" />
            <img className="scroll-logo visible-xs-block" src={require('../../img/logo.png')} alt="logo" />
                    </a>
                   
                    <div className="navbar-collapse collapse floated" id="navbar-collapse">
                        <ul className="nav navbar-nav navbar-with-inside clearfix navbar-right with-border"> 
                            <li>
                                <a href="#/home">home</a>                              
                            </li>
                            
                            <li>
                                <a href="#/about">about</a>
                            </li>
                            <li>
                                {/* <a href="#/project">Our Projects</a> */}
                                {/* <Button
        aria-haspopup="true"
        className='menuButton'
        onClick={handleClick}
      >
        Our Projects
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ '.MuiMenu-paper' : {minWidth:'110px'} }}
      >
        <MenuItem component={'a'} href='#/project' onClose={handleClose}>
            <ListItemIcon >
            <AdminPanelSettingsIcon sx={{width:'1.5em', height:'1.5em'}} />
          </ListItemIcon>
          <ListItemText>Admin</ListItemText>
            </MenuItem>
        <MenuItem component={'a'} href='#/projectAgent' onClose={handleClose}>           
            <ListItemIcon>
            <PersonIcon sx={{width:'1.5em', height:'1.5em'}} />
          </ListItemIcon>
          <ListItemText>Agent</ListItemText>
            </MenuItem>
      </Menu> */}
                                      <Button aria-haspopup="true" href='#/projectLayout'>Our Projects</Button>

                            </li>
                            <li className="active"><a href="#/service">services</a></li>
                           
                            {/* <li>
                                <a href="#/blog">Blog</a>
                               
                            </li> */}
                            <li>
                                <a href="#/contact">Contacts</a>                              
                            </li>
                            <li> <Button className='menuButton' href='#/login' target='_blank'> Login In</Button> </li>                           
                        </ul>
                    </div>
                </div>
            </nav>
        </header>

        {/* <!-- ========================== -->
        <!-- SERVICES - HEADER -->
        <!-- ========================== --> */}
        <section className="top-header ourServiceImg services-header with-bottom-effect transparent-effect ">
            {/* <div className="bottom-effect"></div> */}
            <div className="header-container wow fadeInUp">	
                <div className="header-title">
                    {/* <div className="header-icon"><span className="icon icon-Wheelbarrow"></span></div> */}
                    <div className="title">Our Comprehensive Services</div>
                    {/* <em>Perfect Layout Private Limited</em> */}
                </div>
            </div>
        </section>

{/*   <!-- ========================== -->
        <!-- SERVICES - SERVICES  -->
        <!-- ========================== --> */}
         <section className="steps-section with-icon ">
  <div className="section-icon">
      <span className="icon icon-Umbrella" />
    </div>
  </section>
           <section className="areas-section  service-areas-section with-bottom-effect steps-section with-icon">
           
        <Tables />
        </section>
        
        <section className="services-section service-service-section dark dark-strong with-bottom-effect">
            <div className="bottom-effect" ></div>
            <div className="container dark-content ">
                <div className="row wow zoomIn">
                    <div className="col-md-4 col-sm-4 col-xs-4" >
                        <div className="service-item ">
                            <div className="media-left">
                                <div className="wrap-service-icon">
                                    <div className="service-icon">
                                        <span className="icon icon-ClipboardChart"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="media-body">
                                <h5>Punctual Property Registration: Your Key to Peace of Mind</h5>
                                <p><em></em></p>
                                <p>Secure land acquisitions on behalf of our clients, promptly facilitating smooth transfers to customers.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4">
                        <div className="service-item">
                            <div className="media-left">
                                <div className="wrap-service-icon">
                                    <div className="service-icon">
                                        <span className="icon icon-Search"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="media-body">
                                <h5>Ensuring Legal Compliance: Your Due Diligence Partner</h5>
                                <p><em></em></p>
                                <p>Robust documentation to protect Customer's intersts. </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4">
                        <div className="service-item">
                            <div className="media-left">
                                <div className="wrap-service-icon">
                                    <div className="service-icon">
                                        <span className="icon icon-Blog"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="media-body">
                                <h5>Cost-effectiveness: Ensuring Value for Every Budget</h5>
                                <p><em></em></p>
                                <p>   Guaranteed Contract Fulfillment: Total Transparency Assured  </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="services-divider">
                        <div className="col-md-4 col-sm-4 col-xs-4"></div>
                        <div className="col-md-4 col-sm-4 col-xs-4"></div>
                        <div className="col-md-4 col-sm-4 col-xs-4"></div>
                    </div>
                </div>
                <div className="row wow zoomIn">
                    <div className="col-md-4 col-sm-4 col-xs-4">
                        <div className="service-item">
                            <div className="media-left">
                                <div className="wrap-service-icon">
                                    <div className="service-icon">
                                        <span className="icon icon-Dollars"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="media-body">
                                <h5>Empowering Your Homeownership Journey: Expert Loan Support</h5>
                                <p><em></em></p>
                                <p>Guidance for Home Financing on HMDA-Certified Properties</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4">
                        <div className="service-item">
                            <div className="media-left">
                                <div className="wrap-service-icon">
                                    <div className="service-icon">
                                        <span className="icon icon-ClosedLock"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="media-body">
                                <h5>Assured Security and Transparency: Our Promise to You</h5>
                                <p><em></em></p>
                                <p> Ensuring Accurate Billing and Eliminating Fraudulent Practices
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4">
                        <div className="service-item">
                            <div className="media-left">
                                <div className="wrap-service-icon">
                                    <div className="service-icon">
                                        <span className="icon icon-StorageBox"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="media-body">
                                <h5>Dependable Solutions: Your Trusted Partner</h5>
                                <p><em></em></p>
                                <p>Trustworthy Services: Expertise and Dedication at Your Service</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

{/* <!-- ========================== -->
        <!-- SERVICE - Table -->
        <!-- ========================== --> */}
     

        <Divider variant='middle'/>
        {/* <!-- ========================== -->
        <!-- SERVICES - AREAS OF EXPERTISE  -->
        <!-- ========================== --> */}
        {/* <section className="areas-section  service-areas-section">
            <div className="container"> 
                <div className="row  wow fadeIn">
                    <div className="col-md-12 col-sm-12">
                        <div className="clearfix text-center" style={{paddingRight: "3px"}}>
                            <h4>We think &amp; deliver</h4>
                        </div>
                        <div className="text-center"><em>Creating Real Value in Property and places</em></div>                     
                    </div>
                    <div className="col-md-12 col-sm-12 text-center">
                        <img src={require('../../img/Layout1.png')} alt="" className="img-responsive" />
                    </div>
                </div>
            </div>
        </section> */}

        {/* <section className="buy-section with-icon">
            <div className="section-icon"><span className="icon icon-Umbrella"></span></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-md-offset-1 col-sm-9 wow fadeInLeft">
                        <div className="section-text">
                            <div className=" vcenter like">
                                <span className="icon icon-Briefcase"></span> 
                            </div>
                            <div className="buy-text vcenter">
                                <div className="top-text">Web applications</div>
                                <div className="bottom-text">Discuss your project with us</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3 wow fadeInRight">
                        <a href="#" className="btn btn-info ">Get Started NOW</a>
                    </div>
                </div>
            </div>
        </section> */}

        {/* <!-- ========================== -->
        <!-- SERVICE - REVIEWS SECTION -->
        <!-- ========================== --> */}
        {/* <section className="reviews-section with-icon with-top-effect clearfix">
            <div className="section-icon"><span className="icon icon-Umbrella"></span></div>
            <div className="container">
                <div className="section-heading">
                    <div className="section-title">What clients say</div>
                    <div className="section-subtitle">Lorem ipsum dolor amet consectetur adipisic elit</div>
                    <div className="design-arrow"></div>
                </div>
            </div>
            <div className="container">
                <div className="reviews-slider review-slider-seconds enable-owl-carousel" data-pagination="true" data-min1200="2" data-min800="1" data-min600="1">
                    <div className="slide-item">
                        <div className="media-left">
                            <div className="image-block">
                                <img src={require('../../img/review-img2.jpg')} alt="" />
                            </div>
                        </div>
                        <div className="media-body">
                            <div className="description-block">
                                <div className="name">
                                    <span>James</span>
                                    <em>Oscend Creative Inc.</em>
                                </div>
                                <div className="review">
                                    <em>Lorem ipsum dolor sit amet consecteur adipis
                                        cing elit sed eiusmod tempor ncidue labore etor
                                        dolore magna aliqua. Enim ad minim veniam
                                        qua nostrud exercitat ullamco laborisnisi ut
                                        aliquip exea commodo.</em>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide-item">
                        <div className="media-left">
                            <div className="image-block">
                                <img src={require('../../img/review-img3.jpg')} alt="" />
                            </div>
                        </div>
                        <div className="media-body">
                            <div className="description-block">
                                <div className="name">
                                    <span>sophia </span>
                                    <em>Oscend Creative Inc.</em>
                                </div>
                                <div className="review">
                                    <em>Lorem ipsum dolor sit amet consecteur adipis
                                        cing elit sed eiusmod tempor ncidue labore etor
                                        dolore magna aliqua. Enim ad minim veniam
                                        qua nostrud exercitat ullamco laborisnisi ut
                                        aliquip exea commodo.</em>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide-item">
                        <div className="media-left">
                            <div className="image-block">
                                <img src={require('../../img/review-img2.jpg')} alt="" />
                            </div>
                        </div>
                        <div className="media-body">
                            <div className="description-block">
                                <div className="name">
                                    <span>James</span>
                                    <em>Oscend Creative Inc.</em>
                                </div>
                                <div className="review">
                                    <em>Lorem ipsum dolor sit amet consecteur adipis
                                        cing elit sed eiusmod tempor ncidue labore etor
                                        dolore magna aliqua. Enim ad minim veniam
                                        qua nostrud exercitat ullamco laborisnisi ut
                                        aliquip exea commodo.</em>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide-item">
                        <div className="media-left">
                            <div className="image-block">
                                <img src={require('../../img/review-img3.jpg')} alt="" />
                            </div>
                        </div>
                        <div className="media-body">
                            <div className="description-block">
                                <div className="name">
                                    <span>sophia </span>
                                    <em>Oscend Creative Inc.</em>
                                </div>
                                <div className="review">
                                    <em>Lorem ipsum dolor sit amet consecteur adipis
                                        cing elit sed eiusmod tempor ncidue labore etor
                                        dolore magna aliqua. Enim ad minim veniam
                                        qua nostrud exercitat ullamco laborisnisi ut
                                        aliquip exea commodo.</em>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

        {/* <!-- ========================== -->
        <!-- SERVICES - REVIEWS SECTION -->
        <!-- ========================== --> */}
        {/* <section className="browsers-section service-browse-section ">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <img src={require('../../img/browsers-image2.jpg')} alt=" " className="img-responsive" />
                    </div>
                </div>
            </div>
        </section> */}


        {/* <!-- ========================== -->
        <!-- SERVICES - SLIDER -->
        <!-- ========================== --> */}
        {/* <section className="slider-section dark dark-strong with-bottom-effect">
            <div className="bottom-effect"></div>
            <div className="dark-content">
                <div className="wrap-section-slider enable-owl-carousel" data-single-item="true" >
                    <div className="slide-item">
                        <div className="slider-title">latest from twitter <i className="fa fa-twitter"></i> oscend creative agency</div>
                        <p className="large">Lorem ipsum dolor sit amet consecteur adipiscing elit sed eiusmod tempor ncididue ut labore etor dolore magna aliqua. Ut enim <br />
                            minim veniam qua nostrud exercitat ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                        <div className="time">2 minutes ago</div>
                    </div>
                    <div className="slide-item">
                        <div className="slider-title">latest from twitter <i className="fa fa-twitter"></i> oscend creative agency</div>
                        <p className="large">Lorem ipsum dolor sit amet consecteur adipiscing elit sed eiusmod tempor ncididue ut labore etor dolore magna aliqua. Ut enim <br />
                            minim veniam qua nostrud exercitat ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                        <div className="time">6 minutes ago</div>
                    </div>
                    <div className="slide-item">
                        <div className="slider-title">latest from twitter <i className="fa fa-twitter"></i> oscend creative agency</div>
                        <p className="large">Lorem ipsum dolor sit amet consecteur adipiscing elit sed eiusmod tempor ncididue ut labore etor dolore magna aliqua. Ut enim <br />
                            minim veniam qua nostrud exercitat ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                        <div className="time">12 minutes ago</div>
                    </div>

                </div>
            </div>
        </section> */}



        {/* <!-- ========================== -->
        <!-- SERVICES - CLIENTS -->
        <!-- ========================== --> */}
        {/* <section className="clients-section clearfix">
            <div className="container">
                <div className="section-heading wow fadeInUp">
                    <div className="section-title">trusted clients</div>
                    <div className="section-subtitle">Lorem ipsum dolor sit amet consectetur elit</div>
                    <div className="design-arrow"></div>
                </div>
            </div>
            <div className="container">
                <div className="list-clients">
                    <div className="client-item wow fadeInLeft">
                        <img src={require('../../img/partners/partner-4.png')} alt="" />
                    </div>
                    <div className="client-item wow fadeInLeft">
                        <img src={require('../../img/partners/partner-1.png')} alt="" /> 
                    </div>
                    <div className="client-item wow fadeInRight">
                        <img src={require('../../img/partners/partner-3.png')} alt="" />
                    </div> 
                    <div className="client-item wow fadeInRight">
                        <img src={require('../../img/partners/partner-2.png')} alt="" />
                    </div>
                </div>
            </div>
        </section> */}

        {/* <!-- ========================== -->
        <!-- FOOTER -->
        <!-- ========================== --> */}
        <footer>
     
            {/* <!-- ========================== -->
            <!-- FOOTER - SOCIAL -->
            <!-- ========================== --> */}
            {/* <section className="social-section dark dark-strong">
                <div className="container dark-content">
                    <div className="tt">We are social 24/7 - Get in touch</div>
                    <ul className="list-socials">
                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                        <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                        <li><a href="#"><i className="fa fa-dribbble"></i></a></li>
                        <li><a href="#"><i className="fa fa-pinterest-p"></i></a></li>
                    </ul>
                </div>
            </section> */}

            {/* <!-- ========================== -->
            <!-- FOOTER - FOOTER -->
            <!-- ========================== --> */}
            <section className="footer-section">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-md-3 col-sm-3">
                            <h5>about us</h5>
                            <p>Crowned as ASIA’S GREATEST BRAND and ASIA’S GREATEST LEADER With Mr. Sathesh kumar Garu, the recipient of Global Indian Of The Year award, at the helm</p>
                        </div> */}
                        <div className="col-md-3 col-sm-3">
                            <h5>Sitemap</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="footer-nav">
                                        <li><a href="#">About Us</a></li>
                                        <li className="active"><a href="#">Latest News</a></li>
                                        <li><a href="#">Contact us</a></li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="footer-nav">
                                        <li><a href="#">Get Help</a></li>
                                        <li><a href="#">Careers</a></li>
                                        <li><a href="#">FAQ's</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <h5>Contact info</h5>
                            <ul className="contacts-list">
                                <li>
                                    <p><i className="icon icon-House"></i>NO. 2-1-266/1/6, Flat No.201, Plot NO. 6, 2nd Floor, GSI (Sr) Bandlanguda, Nagole, Hyderabad -500068, 500068</p>
                                </li>
                                {/* <li>
                                    <p><i className="icon icon-Phone2"></i>+91 8374416741</p>
                                </li> */}
                                <li>
                                    <p><i className="icon icon-Mail"></i><a href="mailto:support@oscend.com">care@perfectlayout.in</a> </p>

                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <h5>newsletter</h5>
                            <p>
                                Subscribe to our newsletter for latest updates 
                                about our creative agency
                            </p>
                            <div className="form-group has-feedback">
                                <input type="email" className="form-control " placeholder="Email address ..." />
                                <i className="icon icon-Mail form-control-feedback"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="copyright-section">
                <p>©2023 <span>PERFECT LAYOUT PRIVATE LIMITED </span>. All Rights Reserved</p>
            </section>
        </footer>
</>
    )
}

export default Services;