import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Grid, Paper, Avatar, Box, Button, CssBaseline, TextField, Typography, Stack, Tabs, Tab, IconButton, InputAdornment } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "animate.css/animate.css";
import react, {useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import WOW from "wowjs";
import { apiURL, Post } from "../../utils/apiCalls";
import { FormikProvider, useFormik, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const defaultTheme = createTheme();
const rnd = ["sections/home-top-background.jpg", "hand.jpg", "home1.jpg", "area.png"]
const rnd_no = Math.floor(Math.random() * rnd.length)

const AdminSchema = Yup.object({
  login_id: Yup.string().required('Email id / Mobile No. is required'),
    password: Yup.string().required('Password is required'),
});

const AgentSchema = Yup.object({
  login_id: Yup.string().required('Email id / Mobile No. is required'),
    password: Yup.string().required('M-Pin is required').matches(/^[0-9]{4}$/, 'Invalid M-Pin'),
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 3 }}>
         {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function LoginPage() {
  const navigate = useNavigate();
const [value, setValue] = useState(0);
const [showPassword, setShowPassword] = useState({'reenter_password': false, 'password': false});

const handleChange = (event, newValue) => {
  setValue(newValue);
};

  useEffect(() => {
    sessionStorage.removeItem('otpVerify')
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  // const formikAdmin = useFormik({
  //   initialValues: {
  //     email: "", password: ""
  //   },
  //   enableReinitialize: true,
  //   validationSchema: AdminSchema,
  //   onSubmit: async (values, { setSubmitting }) => {

  //   console.log(values)    
  // }
  // });

  // const { errors, touched, values, setFieldValue, isSubmitting, handleSubmit, getFieldProps, resetForm } = formikAdmin;

  // const handleChangeData = (e, tab) => {
  //   const { id , value } = e.target
  //   // const list = {...values}
  //   console.log(list[tab])
  //   // if(tab === 'admin') {
  //     list[tab] = {[id] : value}
  //   // }
  //   console.log(list)
  //   setFieldValue(list)
  // }

  const handleAgentLogin = async (values, { setSubmitting }) => {
    const response = await Post(apiURL + '/api/v1/users/agent/login', {...values}, navigate)
    if(response && response.data && response.data.status) {
      toast.success('Login Successful')
      setSubmitting(false)
          sessionStorage.setItem('token', response.data.data['token'] )
          sessionStorage.setItem('user_details', JSON.stringify(response.data.data['data']) )

        navigate("/dashboard/projectagent");
    }
    else {
      toast.error(response?.data?.message || 'Please Try Again')
      setSubmitting(false)
    }
  };

  const handleAdminLogin = async (values, { setSubmitting }) => {
    const response = await Post(apiURL + '/api/v1/users/admin/login', {...values}, navigate)
    if(response && response.data && response.data.status) {
      toast.success('Login Successful')
      setSubmitting(false)
          sessionStorage.setItem('token', response.data.data['token'] )
          sessionStorage.setItem('user_details', JSON.stringify(response.data.data['data']) )
        navigate("/dashboard/projectAdmin");
    }
    else {
      toast.error(response?.data?.message || 'Please Try Again')
      setSubmitting(false)
    }
  }

  const handleClickShowPassword = (val) => setShowPassword((prev)=> ({...prev, [val] : !showPassword[val]}));
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginOtp = async (type, data) => {
    if(data.login_id) {
    sessionStorage.setItem('otpVerify', JSON.stringify({type: type, EmailorMobile: data.login_id}))
    const response = await Post(apiURL + '/api/v1/users/login_otp', {login_id: data.login_id}, navigate)
    if(response && response.data && response.data.status) {
      toast.success("OTP Sent succcessfully")
    navigate('/otp-verify')
    }
    else {
      toast.error(response?.data?.message || 'Please Try Again')
    }
    }
    else toast.error('Please Enter Valid Email/Mobile')
  }

  return (
    <ThemeProvider theme={defaultTheme}>
     
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid  item xs={false} sm={4} md={7}
          sx={{
            backgroundImage:
              `url(${require(`../../img/${rnd[rnd_no]}`)})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            <Box >
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Admin" {...a11yProps(0)} sx={{width:'50%'}} />
          <Tab label="Agent" {...a11yProps(1)} sx={{width:'50%'}} />
        </Tabs>

        <CustomTabPanel value={value} index={0}>
        <Box sx={{ mt: 1 }}>
        <Formik validationSchema={AdminSchema} onSubmit={handleAdminLogin}
         initialValues={{ login_id: sessionStorage.getItem('otpVerify')? JSON.parse(sessionStorage.getItem('otpVerify')).type === 'admin' ? JSON.parse(sessionStorage.getItem('otpVerify')).login_id : '' : '', password: "" }} enableReinitialize= {true}>
       {({ handleSubmit, handleChange, values, errors, getFieldProps, touched }) => (
        <Form onSubmit={handleSubmit}>
              <TextField  margin="normal"
                // type="email"
                // id="email"
                placeholder="Email Id / Mobile No."
                fullWidth
                label=""
                // value={values['admin']['email']}
                variant="outlined"
                // onChange={(e) => handleChangeData(e, 'admin')}
                {...getFieldProps('login_id')}
                error={touched?.login_id && Boolean(errors?.login_id)}
                helperText={touched?.login_id && errors?.login_id}
              />

              <TextField  margin="normal"
                // id="password"
                type={showPassword['password'] ? 'text' : 'password'}
                placeholder="Password"
                label=""
                fullWidth
                // value={values['admin']['password']}
                variant="outlined"
                // onChange={(e) => handleChangeData(e, 'admin')}
                {...getFieldProps('password')}
                error={touched?.password && Boolean(errors?.password)}
                helperText={touched?.password && errors?.password}
                InputProps={{
                  endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={()=>handleClickShowPassword('password')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword['password'] ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }}
              />

              <Stack spacing={1} sx={{mt: 2}}>
                {/* <Button variant="text" sx={{width:'fit-content', fontWeight:600}} onClick={()=>handleLoginOtp('admin', values)}>Login through OTP</Button> */}
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} > Sign In as Admin</Button>
              </Stack>
              </Form>
               )}
               </Formik>
            </Box>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
      <Box sx={{ mt: 1 }}>
      <Formik validationSchema={AgentSchema} onSubmit={handleAgentLogin}
       initialValues={{ login_id: sessionStorage.getItem('otpVerify')? JSON.parse(sessionStorage.getItem('otpVerify')).type === 'agent' ? JSON.parse(sessionStorage.getItem('otpVerify')).login_id : '' : '', password: "" }} enableReinitialize= {true}>
       {({ handleSubmit, handleChange, values, errors, getFieldProps, touched }) => (
        <form onSubmit={handleSubmit}>
              <TextField  margin="normal"
                // type="email"
                // id="email"
                placeholder="Email Id / Mobile No."
                fullWidth
                label=""
                variant="outlined"
                // onChange={(e) => handleChangeData(e, 'agent')}
                // value={values['agent']['email']}
                {...getFieldProps('login_id')}
                error={touched?.login_id && Boolean(errors?.login_id)}
                helperText={touched?.login_id && errors?.login_id}
              />

              <TextField  margin="normal"
                // id="password"
                type={showPassword['reenter_password'] ? 'text' : 'password'}
                placeholder="M-Pin"
                label=""
                fullWidth
                variant="outlined"
                // onChange={(e) => handleChangeData(e, 'agent')}
                // value={values['agent']['password']}
                {...getFieldProps('password')}
                error={touched?.password && Boolean(errors?.password)}
                helperText={touched?.password && errors?.password || 'Enter 4 digit M-Pin'}
                InputProps={{
                  endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={()=>handleClickShowPassword('reenter_password')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword['reenter_password'] ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }}
              />

              <Grid container spacing={2} sx={{mt: 1}}>
                <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button variant="text" sx={{width:'fit-content', fontWeight:600}} onClick={()=>handleLoginOtp('agent', values)}>Login through OTP</Button>
                  </Grid>
                  <Grid item xs={12} sm={8} md={6} lg={6} xl={6} justifyContent={'end'} display={'flex'}>
                    <Button endIcon={<ContactSupportIcon />} onClick={()=>navigate('/forgetPassword')}>Forgot M-Pin</Button>
                  </Grid>
              </Grid>

              <Stack spacing={2} direction={'row'} sx={{mt: 2}}>
              <Button type="submit" fullWidth variant="contained"  sx={{ mt: 3, mb: 2 }} > Sign In as ASSOCIATE</Button>
              </Stack>
              </form>
               )}
               </Formik>
            </Box>
      </CustomTabPanel>
      
          </Box></Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
