import OtpInput from 'react-otp-input';
import { LoadingButton } from '@mui/lab';
import { Grid, Paper, Card, Avatar, Box, Button, CssBaseline, TextField, Typography, Stack, Tabs, Tab, IconButton, InputAdornment } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "animate.css/animate.css";
import react, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import WOW from "wowjs";
import { apiURL, Post } from "../../utils/apiCalls";
import { FormikProvider, useFormik, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import EditIcon from '@mui/icons-material/Edit';
import Iconify from '../../layouts/Iconify';
import { dotCase, sentenceCase, capitalCase } from 'change-case';

const defaultTheme = createTheme();
const rnd = ["sections/home-top-background.jpg", "hand.jpg", "home1.jpg", "area.png"]
const rnd_no = Math.floor(Math.random() * rnd.length)

const OtpVerify = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState({EmailorMobile:'', type:''})
    console.log(data)
    const [otp, setOtp] = useState("");
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(30);

    useEffect(()=> {
     if(sessionStorage.getItem('otpVerify'))
     setData(JSON.parse(sessionStorage.getItem('otpVerify')))
    else {
        navigate('/login')
        toast.error('Invalid Email/Mobile')
    }
    }, [sessionStorage.getItem('otpVerify')])

    const resendOTP = async () => {
         const response = await Post(apiURL + '/api/v1/users/login_otp', {login_id: data.EmailorMobile}, navigate)
    if(response && response.data && response.data.status) {
      toast.success("OTP Sent succcessfully")
    navigate('/otp-verify')
    setSeconds(30)
    }
    else {
      toast.error(response?.data?.message || 'Please Try Again')
    }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else {
                if (minutes > 0) {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                } else {
                    clearInterval(interval);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const handleSubmit = async () => {
        const response = await Post(apiURL + '/api/v1/users/login_verify_otp', { otp: otp, login_id: data.EmailorMobile }, navigate)
        if (response && response.data && response.data.status) {
            toast.success('Login Successful')
            sessionStorage.setItem('token', response.data.data['token'] )
            sessionStorage.setItem('user_details', JSON.stringify(response.data.data['data']) )
          navigate("/dashboard/projectagent");
        }
        else {
            toast.error(response?.data?.message || 'Please Try Again')
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: "100vh" }}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7}
                    sx={{
                        backgroundImage:
                            `url(${require(`../../img/${rnd[rnd_no]}`)})`,
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                            t.palette.mode === "light"
                                ? t.palette.grey[50]
                                : t.palette.grey[900],
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            px: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "primary.main", color: '#ffff', width: '3rem', height:'3rem' }}>
                            <Iconify icon="wpf:security-checked" sx={{bgcolor: "primary.main", color: '#ffff', width: '2rem', height:'2rem' }} />
                        </Avatar>
                        <Typography variant="h4"> {capitalCase(data.type)} OTP Verification </Typography>
                        <Typography variant='body2'>Please enter 4 digit OTP sent to</Typography>

                        <Typography sx={{mb: 2}}>{data.EmailorMobile} <IconButton href='#/login'><EditIcon /></IconButton></Typography>

                        <OtpInput numInputs={4} isInputNum onChange={(e)=>setOtp(e)} value={otp}
                              containerStyle={{ gap: '10px', display: 'flex', alignTtems: 'center', '-moz-box-align': 'center' }}
                              inputStyle={{
                                color: '#001B71', '-webkitTextFillColor': 'black !important', padding: '0px', fontWeight: 600, fontSize: '1rem',
                                borderRadius: '0px', boxSizing: 'content-box', height: '50px', animationName: 'mui-auto-fill-cancel', backgroundColor:'#BDCEE9',
                                animationDuration: '10ms', display: 'block', width: '50px', minWidth: '0px', border: '2px solid #BDCEE9'
                              }}
      renderInput={(props) => <input {...props} />}
   />

                        <Box mt={2}>
                            {seconds > 0 || minutes > 0 ? (
                                <span>
                                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                    {seconds < 10 ? `0${seconds}` : seconds}
                                    &nbsp; &nbsp;</span>
                            ) : (
                                <span>Didn't recieve code? &nbsp; &nbsp;</span>
                            )}
                            <Button
                                disabled={seconds > 0 || minutes > 0}
                                sx={{
                                    color: seconds > 0 || minutes > 0 ? "#BEC3C6" : "#001b71",
                                    background: "none",
                                    border: "none",
                                }}
                                onClick={resendOTP}
                            >
                                Resend
                            </Button>
                        </Box>

                        <LoadingButton variant='contained' onClick={handleSubmit} fullWidth sx={{mt:2}}>Login</LoadingButton>
                    </Box>
                </Grid> </Grid>
        </ThemeProvider>
    );
};

export default OtpVerify;
