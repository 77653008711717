import React,{useState,useEffect} from "react";
import { FormikProvider, useFormik, Form } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  Button,
  CardContent,
  CardActions,
  Card,
  TextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,Box,Autocomplete
} from "@mui/material";
import { Post, apiURL ,GetAll, Put, Get} from "../../../utils/apiCalls";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import { FileUploader } from 'react-drag-drop-files';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import WifiIcon from '@mui/icons-material/Wifi';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
const AdminSchema = Yup.object().shape({
    commission_type: Yup.string().required("Type is required"),
    commission_value: Yup.number('please enter valid value').required("Value is required"),
    unit : Yup.number('please enter valid unit').required("unit is required"),
    price : Yup.number('please enter valid price').required("price is required")
});


const CommissionEditCreate = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [project_list, setProject_list] = useState({    commission_value: "",
    commission_type: "",unit:'',price:""})
    const { id } = useParams();

async function GetList () {
  setLoading(true);
  const response = await Get(apiURL + '/api/v1/projects/' , id);
       if(response) {
      if (response.data.status) {       
      setLoading(false);
      
      setProject_list(response.data.data)
      }
      else {
      setLoading(false);
      toast.error(response.data.message)
      setProject_list({}) 
      }
    }
    else {
      setLoading(false);
    }
};
useEffect(()=> {
  if(id){
  GetList();   
  } 
},[id]);

  const formik = useFormik({
    initialValues: {
     ...project_list
    },
    validationSchema: AdminSchema,
    enableReinitialize:true,
    onSubmit: async (values, { setSubmitting }) => {
      const response = await Put(apiURL + "/api/v1/projects/",id, {
        ...values
      });
      if (response && response.data && response.status) {
        toast.success("Commission Updated Sucessfully");
        setSubmitting(false);
        navigate(`/dashboard/commission`)
      } else {
        toast.error(response?.data.message || "Please Try Again");
        setSubmitting(false);
      }
    },
  });
  
  const {
    errors,
    touched,
    values,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <Typography align="center" variant="h4">
              EDIT COMMISSION
            </Typography>
            <Grid container spacing={2} sx={{ mt: 0 }}>
            <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="sqft"
                  {...getFieldProps("unit")}
                  type="number"
                  error={Boolean(touched.unit && errors.unit)}
                  helperText={touched.unit && errors.unit}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Price"
                  {...getFieldProps("price")}
                  type="number"
                  error={Boolean(touched.price && errors.price)}
                  helperText={touched.price && errors.price}
                />
            
              </Grid>
              <Grid item xs={12} md={3}>
              <FormControl
                fullWidth
                error={Boolean(touched.active && errors.active)}
              >
                <InputLabel id="type">Commission Type</InputLabel>
                <Select
                  id="type"
                  value={values.commission_type}
                  label="Commission Type"
                  onChange={(e) => setFieldValue("commission_type", e.target.value)}
                  defaultValue="P"
                >
                  <MenuItem  value={"P"}>Percentage</MenuItem>
                  <MenuItem value={"F"}>Fixed</MenuItem>
                </Select>
                <FormHelperText style={{color:'red'}}>
                  {touched.commission_type && errors.commission_type}
                </FormHelperText>
              </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Commission Value"
                  {...getFieldProps("commission_value")}
                  type="number"
                  error={Boolean(touched.commission_value && errors.commission_value)}
                  helperText={touched.commission_value && errors.commission_value}
                />
            
              </Grid>
            </Grid>
          </CardContent>
          <CardActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              onClick={(e) =>
                navigate(-1)
              }
              variant="contained"
              sx={{
                backgroundColor: "#3f51b5",
                "&:hover": { color: "#ffff", backgroundColor: "#3f51b5" },
              }}
            >
              Back
            </Button>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{
                backgroundColor: "#3f51b5",
                "&:hover": { color: "#ffff", backgroundColor: "#3f51b5" },
              }}
            >
              Save{" "}
            </LoadingButton>
          </CardActions>
        </Card>
      </Form>
    </FormikProvider>
  );
};

export default CommissionEditCreate;