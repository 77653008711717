
import React, { useEffect, useState, useRef } from 'react';
import WOW from 'wowjs';
import 'animate.css/animate.css';
import '../../css/layout/layout1.css'
import '../../css/layout/layout2.css'
import '../../css/layout/layout3.css'
import '../../css/layout/layout4.css'
import '../../css/layout/layout5.css'
import '../../css/layout/layout6.css'

import { DialogActions, Dialog, DialogTitle, IconButton, InputAdornment, Tabs, Tab,  ListItemIcon, ListItemText, TextField, CircularProgress, DialogContent, Divider, Grid, CardHeader, Card, CardContent, CardActions, Button, Stack, Typography, Popper, Paper, Box, ClickAwayListener, Grow } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { apiURL, Get, Post, Put } from '../../../utils/apiCalls';
import {
  WhatsappShareButton
} from "react-share";
import { toast } from 'react-toastify';
import Iconify from '../../../js/Iconify.js';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import Label from '../../../utils/Label.tsx';
import { makeStyles } from '@mui/styles';
import { capitalCase } from 'change-case';
import { FormikProvider, useFormik, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const AdminSchema = Yup.object({
  login_id: Yup.string().required('Email Address is required'),
    password: Yup.string().required('Password is required'),
});

const AgentSchema = Yup.object({
  login_id: Yup.string().required('Email Address is required'),
    password: Yup.string().required('Password is required'),
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 3 }}>
         {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

//  const Popper_style = {
//       zIndex: 4000,
//     //   top: '5px !important',
//     '&[x-placement*="bottom"] $arrow': {
//       top: 0,
//       left: 0,
//       marginTop: "-0.71em",
//       marginLeft: 4,
//       marginRight: 4,
//       "&::before": {
//         transformOrigin: "0 100%"
//       }
//     },
//     '&[x-placement*="top"] $arrow': {
//       bottom: 0,
//       left: 0,
//       marginBottom: "-0.71em",
//       marginLeft: 4,
//       marginRight: 4,
//       "&::before": {
//         transformOrigin: "100% 0"
//       }
//     },
//     '&[x-placement*="right"] $arrow': {
//       left: 0,
//       marginLeft: "-0.71em",
//       height: "1em",
//       width: "0.71em",
//       marginTop: 4,
//       marginBottom: 4,
//       "&::before": {
//         transformOrigin: "100% 100%"
//       }
//     },
//     '&[x-placement*="left"] $arrow': {
//       right: 0,
//       marginRight: "-0.71em",
//       height: "1em",
//       width: "0.71em",
//       marginTop: 4,
//       marginBottom: 4,
//       "&::before": {
//         transformOrigin: "0 0"
//       }
//     }
//   }

// const Arrow = {
//   overflow: "hidden",
//   position: "absolute",
//   width: "1em",
//   height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
//   boxSizing: "border-box",
//   color,
//   "&::before": {
//     content: '""',
//     margin: "auto",
//     display: "block",
//     width: "100%",
//     height: "100%",
//     boxShadow: theme.shadows[1],
//     backgroundColor: "currentColor",
//     transform: "rotate(45deg)"
//     }
// }

const useStyles = makeStyles((theme) => {
  return {
    popper: {
      zIndex: 1
    },
    arrow: {
      position: "absolute",
      fontSize: 7,
      width: "3em",
      height: "3em",
      top: '-6px',
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
        width: '100%',
        height: '100%',
        color: 'white',
        transform: 'rotate(45deg)',
        background: 'white'
      },
    },
    arrowBottom: {
      position: "absolute",
      fontSize: 7,
      width: "3em",
      height: "3em",
      bottom: '0px',
      marginBottom: '-10px',
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
        width: '100%',
        height: '100%',
        color: 'white',
        transform: 'rotate(45deg)',
        background: 'white'
      },
    },
  };
});

const ProjectEdit = () => {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState({ open: false, plot: {}, index: 0 })
  const anchorEl = useRef([])
  const [arrowRef, setArrowRef] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const [plots, setPlots] = useState([]);
  const [total, setTotal] = useState({});

  const prevOpen = useRef(openDialog.open);
  const [openPlot, setOpenPlot] = useState(false)
  // const [row, setRow] = useState({brochure:'', flyer:''});
  const [value, setValue] = useState(0);
  const [showPassword, setShowPassword] = useState({'reenter_password': false, 'password': false});
  const [openLogin, setOpenLogin] = useState(false)
  
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const getPlots = async () => {
    const response = await Get(apiURL + '/api/v1/plots/', id)
    if (response && response.data && response.data.status) {
      setPlots(response.data.data)
      setTotal({ ...response.data.count, ...response.data.project_details[0] })
    }
  }

  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const open = Boolean(anchorElMenu);
  const handleClick = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElMenu(null);
  };

  useEffect(() => {
    const wow = new WOW.WOW({
      live: false
    });
    wow.init();
    if (id) {
      getPlots()
    }
    else {
      navigate("/dashboard/projectAdmin")
    }
  }, []);

  useEffect(() => {
    if (prevOpen.current === true && openDialog.open === false) {
      if (anchorEl.current[openDialog['index']])
        anchorEl.current[openDialog['index']].focus();
    }
    prevOpen.current = openDialog.open;

  }, [openDialog.open]);

  const imageRef = useRef(null);

  useEffect(() => {
    // Scroll the image into the center of the viewport
    if (imageRef.current) {
      const container = imageRef.current.parentElement;
      const scrollLeft = (imageRef.current.offsetWidth - container.offsetWidth) / 2;
      container.scrollTo({ left: scrollLeft, behavior: 'smooth' });
    }
  }, []); // Empty dependency array ensures the effect runs only once after mount

  const handleOpenDialog = (e, data, i) => {
    setOpenDialog((prev) => ({ open: true, plot: data, index: i }));
  }

  const handleCloseDialog = (e) => {
    if (anchorEl.current[openDialog['index']] && anchorEl.current[openDialog['index']].contains(e.target)) {
      return;
    }
    //   if(prevOpen) {
    anchorEl.current[openDialog['index']] = ''
    setOpenDialog({ open: !prevOpen, plot: {}, index: 0 })
    //   }
  }

  const handleClickPlot = (status) => {
    setOpenDialog((prev) => ({ ...prev, open: false, index: 0, change_status: status }))
    setOpenPlot(true)
  }

  const handleClosePlot = () => {
    setOpenPlot(false)
  }

  const handleSubmit = async () => {
    const response = await Put(apiURL + "/api/v1/", 'plots', {
      ...openDialog.plot, status: openDialog['change_status'],
    })
    if (response && response.data && response.data.status) {
      toast.success('Plot Successfully Booked!')
      handleClosePlot()
      getPlots()
    }
  }

  const StatusColor = (status) => {
    switch (status) {
      case 'allocated':
        return 'error'
        break
      case 'unallocated':
        return 'success'
        break
      case 'registed':
        return 'primary'
        break
      default:
        return 'default'
    }
  };

  const StatusCase = (status) => {
    switch (status) {
      case 'allocated':
        return 'Allocated'
        break
      case 'unallocated':
        return 'Un Allocated'
        break
      case 'registed':
        return 'Registed'
        break
      default:
        return 'default'
    }
  };

  const handleChanePlot = (e) => {
    const list = { ...openDialog.plot }
    const { id, value } = e.target
    list[id] = value

    setOpenDialog((prevState) => ({ ...prevState, plot: list }))
  }

  const handleChange = (e) => {
    const { id, value } = e.target
    const list = [...plots]
    list[openDialog['index']][id] = value

    setPlots(list)
  }

  const handleSubmitEdit = async (e) => {
    const response = await Put(apiURL + "/api/v1/", 'plots', { ...plots[openDialog['index']] })
    if (response && response.data && response.data.status) {
      toast.success('Plot Successfully Updated!')
      handleCloseDialog(e)
      getPlots()
    }
  }

  const transform = () => {
    return {
      transform: 'rotate(-90deg)',
      transformOrigin: 'left top',
      whiteSpace: 'nowrap',
      position: 'absolute',
    }
  }

  const handleAlignItems = (css) => {
    switch (css) {
      case 'layout2_239':
        return {
          alignItems: 'center', justifyContent: 'start', height: '100%'
        }
      case 'layout2_285':
        return {
          ...transform(), ml: '5px', top: '100%', left: 0
        }
      case 'layout2_272':
        return {
          ...transform(), justifyContent: 'end', top: '50%', left: 0
        }
      case 'layout2_315':
        return {
          ...transform(), justifyContent: 'start', top: '100%', left: 0
        }
      case 'layout2_334':
        return {
          ...transform(), justifyContent: 'center', top: '50%', left: 0
        }
      case 'layout2_460':
        return {
          ...transform(), justifyContent: 'end', top: '35%', left: 0
        }
      case 'layout2_452':
        return {
          ...transform(), top: '100%', left: 0
        }
      case 'layout2_444':
        return {
          fontSize: '10px', height: '100%'
        }
      case 'layout4_79':
        return {
          alignItems: 'start', justifyContent: 'center', marginLeft: '15px', ...transform(), width: '100%', top: '100%', left: 0
        }
      case 'layout4_69':
        return {
          ...transform(), alignItems: 'center', justifyContent: 'center', width: '100%', top: '50%', left: '25%'
        }
      case 'layout3_12':
        return {
          alignItems: 'end', justifyContent: 'center', height: '100%'
        }
      case 'layout3_121':
        return {
          ...transform(), alignItems: 'end', justifyContent: 'center', top: '50%', left: 0
        }
      case 'layout3_261':
        return {
          ...transform(), alignItems: 'end', justifyContent: 'center', top: '50%', left: 0
        }
      case 'layout3_232':
        return {
          alignItems: 'start', justifyContent: 'center', height: '100%'
        }
      case 'layout3_284':
        return {
          alignItems: 'start', justifyContent: 'center', width: '100%', height: '100%'
        }
      case 'layout3_300':
        return {
          alignItems: 'start', justifyContent: 'center', height: '100%'
        }
      case 'layout3_313':
        return {
          alignItems: 'start', justifyContent: 'center', height: '100%'
        }
      case 'layout3_314':
        return {
          ...transform(), alignItems: 'end', justifyContent: 'center', width: '100%', top: '50%', left: 0
        }
      case 'layout3_299':
        return {
          alignItems: 'end', justifyContent: 'center', height: '100%'
        }
      default:
        return {
          alignItems: 'center', justifyContent: 'center', height: '100%'
        }
    }
  }

  const handleClickShowPassword = (val) => setShowPassword((prev)=> ({...prev, [val] : !showPassword[val]}));
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const handleCloseLogin = () => {
    setOpenLogin(false)
  }

  const handleAgentLogin = async (values, { setSubmitting }) => {
    const response = await Post(apiURL + '/api/v1/users/agent/login', {...values}, navigate)
    if(response && response.data && response.data.status) {
      toast.success('Login Successful')
      setSubmitting(false)
          sessionStorage.setItem('token', response.data.data['token'] )
          sessionStorage.setItem('user_details', JSON.stringify(response.data.data['data']) )
        navigate(`/dashboard/projectagent/${id}`);
    }
    else {
      toast.error(response?.data?.message || 'Please Try Again')
      setSubmitting(false)
    }
  };

  const handleAdminLogin = async (values, { setSubmitting }) => {
    const response = await Post(apiURL + '/api/v1/users/admin/login', {...values}, navigate)
    if(response && response.data && response.data.status) {
      toast.success('Login Successful')
      setSubmitting(false)
      handleSubmit()
          sessionStorage.setItem('token', response.data.data['token'] )
          sessionStorage.setItem('user_details', JSON.stringify(response.data.data['data']) )
        navigate(`/dashboard/projectAdmin/${id}`);
    }
    else {
      toast.error(response?.data?.message || 'Please Try Again')
      setSubmitting(false)
    }
  }


  return (
    <>
      {/* <!-- ========================== -->
        <!-- BLOG - HEADER -->
        <!-- ========================== --> */}
      <section className="top-header blog-header with-bottom-effect transparent-effect dark dark-strong">
        <div className="bottom-effect"></div>
        <div className="header-container wow fadeInUp">
          <div className="header-title">
            <div className="header-icon"><span className="icon icon-Wheelbarrow"></span></div>
            <div className="title">{total.name}</div>

          </div>
        </div>

      </section>

      <section>

        {/* <!-- ========================== -->
        <!-- SERVICES - STEPS  -->
        <!-- ========================== --> */}
        <section className="core-features-section">
          <div className="container">
            <div className="section-heading">
              <div className="section-title">core features</div>
              <div className="section-subtitle">Project Info</div>
              <div className="design-arrow"></div>
            </div>
          </div>

          <div className="container">
            <div className="service-navigation">
              <ul className="row" role="tablist" style={{ display: 'flex', justifyContent: 'center' }}>
                <li role="presentation" className="active">
                  <a href="#tabWeb" aria-controls="tabWeb" role="tab" data-toggle="tab">
                    <div className="col-md-3 col-sm-3 col-xs-3 wow zoomInUp" data-wow-delay="0.2s" style={{ display: 'flex', width: "100%" }}>
                      <div className="navigation-item">
                        <div className="navigation-icon">
                          <span className="icon icon-Home"></span>
                        </div>
                        <h5>Project Highlights</h5>
                      </div>
                    </div>
                  </a>
                </li>
                <li role="presentation" >
                  <a href="#tabMobile" aria-controls="tabMobile" role="tab" data-toggle="tab">
                    <div className="col-md-3 col-sm-3 col-xs-3 wow zoomInUp" data-wow-delay="0.3s" style={{ display: 'flex', width: "100%" }}>
                      <div className="navigation-item">
                        <div className="navigation-icon">
                          <span className="icon icon-WorldGlobe"></span>
                        </div>
                        <h5>Location Highlights</h5>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="container tab-content wow fadeInUp" >
            <div role="tabpanel" className="tab-pane active" id="tabWeb">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="italic-title">Every customer is importany to us!</h5>
                  <h4>{total.project_highlights?.Header}</h4>
                  <ul className="marker-list">

                  {total?.project_highlights?.content && total?.project_highlights?.content.map((data, i)=> {
                    return (
                         <li key={i}>{data}</li>
                    )})}
                  </ul>
                </div>
              </div>
            </div>

            <div role="tabpanel" className="tab-pane" id="tabMobile">
              <div className="row">
                <div className="col-md-4">
                  <img src={total?.roadmap} alt="" style={{ width: "100%", height: "100%" }} />
                </div>
                <div className="col-md-8 ">
                  <h5 className="italic-title">Every customer is importany to us!</h5>
                  <h4>{total.location_highlights?.Header}</h4>

                  <ul className="marker-list">
                  {total?.location_highlights?.content && total?.location_highlights?.content.map((data, i)=> {
                    return (
                         <li key={i}>{data}</li>
                    )})}

                  </ul>
                </div>
              </div>
            </div>
            <div role="tabpanel" className="tab-pane" id="tabPhoto">
              <div className="row">
                <div className="col-md-12 ">
                  <h5 className="italic-title">Every customer is importany to us!</h5>
                  <h4>Our Experienced guys at Oscend always giving their best
                    to make the clients happy!!</h4>

                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                  <p>
                    Curabitur pretium tincidunt lacus. Nulla gravida orci a odio. Nullam varius, turpis et commodo pharetra, est eros bibendum elit, nec luctus magna felis sollicitudin mauris. Integer in mauris eu nibh euismod gravida. Duis ac tellus et risus vulputate vehicula. Donec lobortis risus a elit. Etiam tempor. Ut ullamcorper, ligula eu tempor congue, eros est euismod turpis, id tincidunt sapien risus a quam. Maecenas fermentum consequat mi. Donec fermentum. Pellentesque malesuada nulla a mi. Duis sapien sem, aliquet nec, commodo eget, consequat quis, neque. Aliquam faucibus, elit ut dictum aliquet, felis nisl adipiscing sapien, sed malesuada diam lacus eget erat. Cras mollis scelerisque nunc. Nullam arcu. Aliquam consequat. Curabitur augue lorem, dapibus quis, laoreet et, pretium ac, nisi. Aenean magna nisl, mollis quis, molestie eu, feugiat in, orci. In hac habitasse platea dictumst.
                  </p>
                  <p>
                    Fusce convallis, mauris imperdiet gravida bibendum, nisl turpis suscipit mauris, sed placerat ipsum urna sed risus. In convallis tellus a mauris. Curabitur non elit ut libero tristique sodales. Mauris a lacus. Donec mattis semper leo. In hac habitasse platea dictumst. Vivamus facilisis diam at odio. Mauris dictum, nisi eget consequat elementum, lacus ligula molestie metus, non feugiat orci magna ac sem. Donec turpis. Donec vitae metus. Morbi tristique neque eu mauris. Quisque gravida ipsum non sapien. Proin turpis lacus, scelerisque vitae, elementum at, lobortis ac, quam. Aliquam dictum eleifend risus. In hac habitasse platea dictumst. Etiam sit amet diam. Suspendisse odio. Suspendisse nunc. In semper bibendum libero.
                  </p>


                </div>
              </div>
            </div>
            <div role="tabpanel" className="tab-pane" id="tabDesign">
              <div className="row">
                <div className="col-md-12 ">
                  <h5 className="italic-title">Every customer is importany to us!</h5>
                  <h4>Our Experienced guys at Oscend always giving their best
                    to make the clients happy!!</h4>
                  <p>
                    Amco laboris nisi ut aliquip xea commodo consequt. Duis aute irure dolor reprehenderit voluptate velit esse cillum dolore
                    aliqua ut enim ad mid veniam quis nostrud exercitation ullamco laboris nisid. Lorem ipsum dolor sit amet consectetur adip
                    isicing elit sed eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>

                  <ul className="marker-list">
                    <li>Commodo consequt. Duis aute irure dolor reprehenderit </li>
                    <li>Atetur adipisicing elit sed do eiusmod tempor incididunt ut labore dolore</li>
                    <li>Pliqua ut enim ad mid veniam quis nostrud exercitation ullamco</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </section>
        {/* /////////////////////////////////////////////////////////////////////////////////////////// */}


        {/* <div className="image-container">
      <div className="box"></div>
      <div className="image-wrapper">
        <img
       src={require('../img/layoutimg.jpg')} // Replace with the path to your image
          alt="example"
          className="responsive-image"
        />
      </div>
    </div> */}

        <Stack spacing={2} direction={'row'} sx={{ marginBottom: "20px", justifyContent: 'center' }}>
          <Grid container spacing={2} sx={{ justifyContent: 'center', width: '80% !important' }}>
            <Grid item lg={2} md={2} sm={3} xs={6} >
              <Card elevation={12} sx={{ borderRadius: '5px', backgroundColor: 'green', color: 'white', width: '100%' }}>
                <CardContent sx={{ pb: '0px !important' }}>
                  <Typography sx={{ color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
                    Avaliable
                  </Typography>
                  <Typography sx={{ color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
                    {total['unallocated']}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={2} md={2} sm={3} xs={6} >
              <Card elevation={12} sx={{ borderRadius: '5px', backgroundColor: 'red', color: 'white', width: '100%' }} >
                <CardContent sx={{ pb: '0px !important' }}>
                  <Typography sx={{ color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
                    Allocated
                  </Typography>
                  <Typography sx={{ color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
                    {total['allocated']}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={2} md={2} sm={3} xs={6} >
              <Card elevation={12} sx={{ borderRadius: '5px', backgroundColor: '#2196f3', color: 'white', width: '100%' }} >
                <CardContent sx={{ pb: '0px !important' }}>
                  <Typography sx={{ color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
                    Registed
                  </Typography>
                  <Typography sx={{ color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
                    {total['registed']}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/* <Grid item lg={2} md={2} sm={3} xs={6} >
    <Card  elevation={12} sx={{borderRadius:'5px', backgroundColor: '#b28704', color: 'white', width: '100%'}} >
      <CardContent sx={{pb: '0px !important'}}>
        <Typography sx={{ color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
         Res.
        </Typography>
        <Typography sx={{ color: 'white',  textAlign: 'center' }} color="text.secondary" gutterBottom>
          60
        </Typography>
      </CardContent>
    </Card>
    </Grid> */}
            <Grid item lg={2} md={2} sm={3} xs={6} >
              <Card elevation={12} sx={{ borderRadius: '5px', backgroundColor: '#673ab7', color: 'white', width: '100%' }} >
                <CardContent sx={{ pb: '0px !important' }}>
                  <Typography sx={{ color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
                    No of Plots
                  </Typography>
                  <Typography sx={{ color: 'white', textAlign: 'center' }} color="text.secondary" gutterBottom>
                    {total['total']}
                  </Typography>
                </CardContent>
              </Card>
            </Grid></Grid>
        </Stack>

        <Button href={'#/projectLayout'} startIcon={<ArrowBackIosNewIcon sx={{ width: 30, height: 30 }} />} >Back to Project List</Button>

        <Card >
          <CardHeader
            action={
              <Stack spacing={2} direction={'row'}>
                <Button href={total['brochure']} download={'Brochure'} target="_blank" rel="noreferrer" startIcon={<Iconify icon='material-symbols:download-sharp' sx={{ width: 30, height: 30 }} />}>Brochure</Button>
                <Button href={total['flyer']} download={'Flyer'} target="_blank" rel="noreferrer" startIcon={<Iconify icon='material-symbols:download-sharp' sx={{ width: 30, height: 30 }} />}>Flyers</Button>
                <Button href={total['roadmap']} download={'Roadmap'} target="_blank" rel="noreferrer" startIcon={<Iconify icon='material-symbols:download-sharp' sx={{ width: 30, height: 30 }} />}>Roadmap</Button>

                <WhatsappShareButton url='https://perfectlayout.in/' title={`Check out our  brochure and flyer to Know more about our Project:

Brochure URL:${total['brochure']} 
Flyer URL: ${total['flyer']}

Feel free to reach out if you have any questions or need more information.

Team,
Perfect layout`} >


                  <Button startIcon={<Iconify icon='ic:baseline-whatsapp' sx={{ width: 30, height: 30 }} />}>Share</Button>
                </WhatsappShareButton>
              </Stack>
            }
          />
          <div id="container">
            {plots && plots.length > 0 ? plots.map((data, i) => (
              <div key={i} className={`${total['css']}_${data['plot_id']}`} style={{ backgroundColor: data['status'] === 'unallocated' ? 'green' : data['status'] === 'allocated' ? 'red' : '#2196f3' }} ref={(e) => anchorEl.current[i] = e} onClick={(e) => handleOpenDialog(e, data, i)}><Typography sx={{ ...handleAlignItems(`${total['css']}_${data['plot_id']}`), display: 'flex', fontSize: total['css'] === 'layout1' ? 'inherit' : '14px' }}> {data['plot_no']} </Typography></div>
            )) : null}
            <img
              className={total['css']}
              src={total['img']} // Replace with the path to your image
              //  src={require('../../../img/layoutImages/layoutimg3.jpg')} // Replace with the path to your image
              alt="project"
              ref={imageRef}
            />
          </div>
        </Card>
      </section>

      {/* {/* <!-- ========================== -->
        <!-- BLOG - CONTENT -->
        <!-- ========================== --> */}

      <Popper open={openDialog.open} anchorEl={anchorEl.current[openDialog['index']]} transition
        // sx={{  ...Popper_style }}
        className={classes.popper}
        // overflow: 'inherit', minWidth: { xs: 'auto', sm: 'auto' }, maxWidth: { xs: '40%', sm: '50%' }, height: { xs: '20%', sm: '30%' },
        placement="top"
        disablePortal={true}
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'scrollParent',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'scrollParent',
              padding: 8,
            },
          },
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} timeout={350} >
            <Paper elevation={12} sx={{ boxShadow: 'none' }}>
              <ClickAwayListener onClickAway={handleCloseDialog}>
                {/* <> */}
                <Paper elevation={10} sx={{ p: 3 }}>
                  <Box component={'span'} className={placement === 'top' ? classes.arrowBottom : classes.arrow} ref={(e) => setArrowRef(e)} />

                  <div style={{ position: 'relative', justifyContent: 'center', display: 'flex' }}>
                    <Stack spacing={2}>
                      <Stack spacing={2} direction={'row'}>
                        {/* <h4>id:{openDialog.plot['id']}</h4> */}
                        <h4>Sector:</h4>
                         <h4>{openDialog.plot['sector']}</h4> 
                        {/* <TextField id='sector' variant='standard' sx={{ maxWidth: '6em', ml: "2em !important" }} size="small" value={openDialog.plot['sector']} onChange={handleChange} /> */}
                      </Stack>
                      <Stack spacing={2} direction={'row'}>
                        <h4>Plot No:</h4>
                        <h4>{openDialog.plot['plot_no']}</h4>
                        {/* <TextField id='plot_no' variant='standard' sx={{ maxWidth: '6em', ml: "2em !important" }} size="small" value={openDialog.plot['plot_no']} onChange={handleChange} /> */}

                      </Stack>
                      <Stack spacing={2} direction={'row'}>
                        <h4>Plot Area:</h4>
                        <h4>{openDialog.plot['plot_area']}</h4>
                        {/* <TextField id='plot_area' variant='standard' sx={{ maxWidth: '6em' }} size="small" value={openDialog.plot['plot_area']} onChange={handleChange} /> */}

                      </Stack>
                      <Stack spacing={2} direction={'row'}>
                        <h4>Facing:</h4>
                        <h4>{openDialog.plot['facing']}</h4>
                        {/* <TextField id='facing' variant='standard' sx={{ maxWidth: '6em', ml: "2em !important" }} size="small" value={openDialog.plot['facing']} onChange={handleChange} /> */}

                      </Stack>
                      {/* <Stack spacing={2} direction={'row'}>
                            <h4>Status:</h4>
                            <h4>{openDialog.plot['status']}</h4>
                        </Stack> */}
                    </Stack>
                  </div>
                  <Stack spacing={2} marginTop={2.5}>
                    {openDialog.plot['status'] === 'allocated' ? null : <Button sx={{ width: '100%', mt: 2, color: 'red' }} variant='outlined' onClick={() => handleClickPlot('allocated')}>Book</Button>}
                    {openDialog.plot['status'] === 'registed' ? null : <Button sx={{ width: '100%', mt: 2, color: '#2196f3' }} variant='outlined' onClick={() => handleClickPlot('registed')}>Register</Button>}
                    {openDialog.plot['status'] === 'unallocated' ? null : <Button sx={{ width: '100%', mt: 2, color: 'green' }} variant='outlined' onClick={() => handleClickPlot('unallocated')}>UnAllocate</Button>}
                    {/* <Button variant='contained' sx={{ width: '100%', mt: 2 }} onClick={handleSubmitEdit}>Submit</Button> */}
                  </Stack>
                </Paper>
              </ClickAwayListener>
            </Paper>

          </Grow>
        )}
      </Popper>

      <Dialog maxWidth={'sm'} open={openPlot} onClose={handleClosePlot} fullWidth>
        <DialogTitle>Plot Section</DialogTitle>
        <Divider />
        <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <Stack spacing={2}>
            <Stack spacing={2} direction={'row'}>
              <h4>Sector:</h4>
              <h4>{openDialog.plot['sector']}</h4>
            </Stack>
            <Stack spacing={2} direction={'row'}>
              <h4 style={{ display: 'flex', alignItems: 'center' }}>Plot No:</h4>
              <h4>{openDialog.plot['plot_no']}</h4>
              {/* <TextField id="plot_no" label="" variant="standard" value={openDialog.plot['plot_no']} onChange={handleChanePlot} /> */}
            </Stack>
            <Stack spacing={2} direction={'row'}>
              <h4>Plot Area:</h4>
              <h4>{openDialog.plot['plot_area']}</h4>
            </Stack>
            <Stack spacing={2} direction={'row'}>
              <h4 style={{ display: 'flex', alignItems: 'center' }}>Facing:</h4>
              <h4>{openDialog.plot['facing']}</h4>
              {/* <TextField id="facing" label="" variant="standard" value={openDialog.plot['facing']} onChange={handleChanePlot} /> */}
            </Stack>
            <Stack spacing={2} direction={'row'}>
              <h4>Status:</h4>
              {/* <h4>{openDialog.plot['status']}</h4> */}
              <Label variant="ghost" color={StatusColor(openDialog.plot['status'])}>{capitalCase(StatusCase(openDialog.plot['status']))}</Label>

            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          {openDialog['change_status'] === 'allocated' ? <Button sx={{ color: 'red' }} variant='outlined' onClick={()=>setOpenLogin(true)}>Book</Button> : null}
          {openDialog['change_status'] === 'registed' ? <Button sx={{ color: '#2196f3' }} variant='outlined' onClick={()=>setOpenLogin(true)}>Register</Button> : null}
          {openDialog['change_status'] === 'unallocated' ? <Button sx={{ color: 'green' }} variant='outlined' onClick={()=>setOpenLogin(true)}>UnAllocate</Button> : null}
        </DialogActions>
      </Dialog>

      <Dialog maxWidth={'sm'} open={openLogin} onClose={handleCloseLogin} fullWidth>
        <DialogTitle>Sign In</DialogTitle>
        <Divider />
        <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
        </DialogContent>
        <DialogActions>
        <Box >
            <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab label="Admin" {...a11yProps(0)} sx={{width:'50%'}} />
          {/* <Tab label="Agent" {...a11yProps(1)} sx={{width:'50%'}} /> */}
        </Tabs>

        <CustomTabPanel value={value} index={0}>
        <Box sx={{ mt: 1 }}>
        <Formik validationSchema={AdminSchema} onSubmit={handleAdminLogin} initialValues={{ login_id: "", password: "" }} enableReinitialize= {true}>
       {({ handleSubmit, values, errors, getFieldProps, touched }) => (
        <form onSubmit={handleSubmit}>
              <TextField  margin="normal"
                // type="email"
                // id="email"
                placeholder="Email Address"
                fullWidth
                label=""
                // value={values['admin']['email']}
                variant="outlined"
                // onChange={(e) => handleChangeData(e, 'admin')}
                {...getFieldProps('login_id')}
                error={touched?.login_id && Boolean(errors?.login_id)}
                helperText={touched?.login_id && errors?.login_id}
              />

              <TextField  margin="normal"
                // id="password"
                type={showPassword['password'] ? 'text' : 'password'}
                placeholder="Password"
                label=""
                fullWidth
                // value={values['admin']['password']}
                variant="outlined"
                // onChange={(e) => handleChangeData(e, 'admin')}
                {...getFieldProps('password')}
                error={touched?.password && Boolean(errors?.password)}
                helperText={touched?.password && errors?.password}
                InputProps={{
                  endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={()=>handleClickShowPassword('password')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword['password'] ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }}
              />

              <Stack spacing={2} direction={'row'} sx={{mt: 2}}>
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} > Sign In as Admin</Button>
              </Stack>
              </form>
               )}
               </Formik>
            </Box>
      </CustomTabPanel>

      {/* <CustomTabPanel value={value} index={1}>
      <Box sx={{ mt: 1 }}>
      <Formik validationSchema={AgentSchema} onSubmit={handleAgentLogin} initialValues={{ login_id: "", password: "" }} enableReinitialize= {true}>
       {({ handleSubmit, handleChange, values, errors, getFieldProps, touched }) => (
        <form onSubmit={handleSubmit}>
              <TextField  margin="normal"
                // type="email"
                // id="email"
                placeholder="Email Address"
                fullWidth
                label=""
                variant="outlined"
                // onChange={(e) => handleChangeData(e, 'agent')}
                // value={values['agent']['email']}
                {...getFieldProps('login_id')}
                error={touched?.login_id && Boolean(errors?.login_id)}
                helperText={touched?.login_id && errors?.login_id}
              />

              <TextField  margin="normal"
                // id="password"
                type={showPassword['reenter_password'] ? 'text' : 'password'}
                placeholder="Password"
                label=""
                fullWidth
                variant="outlined"
                // onChange={(e) => handleChangeData(e, 'agent')}
                // value={values['agent']['password']}
                {...getFieldProps('password')}
                error={touched?.password && Boolean(errors?.password)}
                helperText={touched?.password && errors?.password}
                InputProps={{
                  endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={()=>handleClickShowPassword('reenter_password')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword['reenter_password'] ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }}
              />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
                  </Grid>
                  <Grid item xs={12} sm={8} md={6} lg={6} xl={6} justifyContent={'end'} display={'flex'}>
                    <Button endIcon={<ContactSupportIcon />} onClick={()=>navigate('/forgetPassword')}>Forgot Password</Button>
                  </Grid>
              </Grid>

              <Stack spacing={2} direction={'row'} sx={{mt: 2}}>
              <Button type="submit" fullWidth variant="contained"  sx={{ mt: 3, mb: 2 }} > Sign In as Agent</Button>
              </Stack>
              </form>
               )}
               </Formik>
            </Box>
      </CustomTabPanel> */}
      
          </Box>
        </DialogActions>
      </Dialog>

      {total?.['youtube_link'] ? <div className='container'>
        <div className="section-title ">Watch our Intro Video</div>
        <div style={{ display: 'flex', justifyContent: "center", marginBottom: '50px', marginTop: '50px' }}>
          <iframe width="500" height="390" src={total['youtube_link']} style={{ boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" }} >
          </iframe>

        </div>

      </div> : null}
    </>
  )
};

export default ProjectEdit;