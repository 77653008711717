import { Container, Stack, Typography, Button, Card, TableContainer, Table, TableBody, TableRow, TableCell, IconButton, TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Iconify from '../../../layouts/Iconify';
import Scrollbar from '../../../layouts/Scrollbar';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../layouts/user';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import MiscSkeleton from '../../../layouts/user/MiscSkeleton';
import { GetAll, apiURL } from '../../../utils/apiCalls';
import { filter } from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import Label from '../../../layouts/Label';
import { capitalCase } from 'change-case';
import SearchNotFound from '../../../layouts/SearchNotFound';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'no', label: 'SNo.', align: 'left' },
    { id: 'name', label: 'Project Name', align: 'left' },
    { id: 'place', label: 'Place', align: 'left' },
    { id: 'type', label: 'Type', align: 'left' },
    { id: '', label: '', align: 'center' },
  ];
  
  // ----------------------------------------------------------------------
  
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => JSON.stringify(_user['name']).toLowerCase().trim().indexOf(query.toLowerCase().trim()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
  }

const AgentsOffers = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState();
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
  
    async function GetList () {
      setLoading(true);
      const response = await GetAll(apiURL + '/api/v1/projects');
           if(response) {
          if (response.data.status) {       
          setLoading(false);
          setList(response.data.data)
          }
          else {
          setLoading(false);
          toast.error(response.data.message)
            setList([])
          }
        }
        else {
          setLoading(false);
        }
  };
  
    useEffect(()=> {
      GetList();    
    },[])
  
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = list.map((n) => n.exporter_company_Name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const handleFilterByName = (event) => {
      setFilterName(event.target.value);
    };
  
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;
  
    const filteredUsers = applySortFilter(list, getComparator(order, orderBy), filterName);
  
    const isUserNotFound = filteredUsers.length === 0;
    
    const handleClickEdit = (e, id) => {
      // e.preventDefault();
      navigate(`/dashboard/agentofferimages/${id}`)
  };


    return (
        <Container sx={{m:0, width:'100%', maxWidth:'100%'}} maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Agent Offers 
          </Typography>
        </Stack>

        <Card sx={{boxShadow:"0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.2)"}} >
          <UserListToolbar placeholder= "Search by Project Name" numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          {/* <Scrollbar sx={undefined}> */}
            <TableContainer sx={{overflowX: 'auto'}}>
              <Table stickyHeader >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={list.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  isCheckbox={false}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { id, name, place ,type} = row;
                    return (
                      <TableRow hover key={id} onClick={(e) => handleClickEdit(e, id)} sx={{cursor:'pointer'}}>
                        <TableCell component="th" scope="row" align="left">{index+1}</TableCell>
                        <TableCell align="left" sx={{color:'#37B4EE'}}>{capitalCase(name)}</TableCell>
                        <TableCell align="left"><Iconify icon={'zondicons:location'}/>&nbsp;{place}</TableCell>
                        <TableCell align="left" sx={{color:'#37B4EE'}}>{type}</TableCell>
                        <TableCell align="center">
                       <IconButton color="primary" onClick={(e) => handleClickEdit(e, id)}> <EditIcon /> </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody> {loading ? <MiscSkeleton record={{number: 7}}/> :
                    <TableRow>
                      <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                       {list.length <=0 ? <Typography gutterBottom align="center" variant="subtitle1"> Not found</Typography> :<SearchNotFound searchQuery={filterName} /> }
                      </TableCell>
                    </TableRow> }
                  </TableBody> 
                )}
              </Table>
            </TableContainer>
          {/* </Scrollbar> */}

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    )
}

export default AgentsOffers;