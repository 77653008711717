import { Container, Stack, Typography, Button, Card, TableContainer, Table, TableBody, TableRow, TableCell, IconButton, TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Iconify from '../../../layouts/Iconify';
import Scrollbar from '../../../layouts/Scrollbar';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../layouts/user';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import MiscSkeleton from '../../../layouts/user/MiscSkeleton';
import { GetAll, apiURL } from '../../../utils/apiCalls';
import { filter } from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import Label from '../../../layouts/Label';
import { capitalCase } from 'change-case';
import SearchNotFound from '../../../layouts/SearchNotFound';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'no', label: 'SNo.', align: 'left' },
    { id: 'name', label: 'Project Name', align: 'left' },
    { id: 'place', label: 'Place', align: 'left' },
    { id: 'type', label: 'Type', align: 'left' },
    { id: '', label: '', align: 'center' },
  ];
  
  // ----------------------------------------------------------------------
  
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => JSON.stringify(_user['name']).toLowerCase().trim().indexOf(query.toLowerCase().trim()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
  }

const ProjectList = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState();
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
  
    async function GetList () {
      setLoading(true);
      const response = await GetAll(apiURL + '/api/v1/projects');
           if(response) {
          if (response.data.status) {       
          setLoading(false);
          setList(response.data.data)
          }
          else {
          setLoading(false);
          toast.error(response.data.message)
            setList([])
          }
        }
        else {
          setLoading(false);
        }
  };
  
    useEffect(()=> {
      GetList();    
    },[])
  
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = list.map((n) => n.exporter_company_Name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const handleFilterByName = (event) => {
      setFilterName(event.target.value);
    };
  
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;
  
    const filteredUsers = applySortFilter(list, getComparator(order, orderBy), filterName);
  
    const isUserNotFound = filteredUsers.length === 0;
    
    const handleClickEdit = (e, id) => {
      // e.preventDefault();
      navigate(`/projectLayout/${id}`)
  };


    return (
      <>
 <header className="header scrolling-header">
      <nav
        id="nav"
        className="navbar navbar-default navbar-fixed-top"
        role="navigation"
        style={{backgroundColor:'rgba(255, 255, 255, 0.9)'}}
      >
        <div className="container relative-nav-container">
          <a
            className="toggle-button visible-xs-block"
            data-toggle="collapse"
            data-target="#navbar-collapse"
          >
            <i className="fa fa-navicon" />
          </a>
          <a className="navbar-brand scroll" href="home.html" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <img className="normal-logo hidden-xs" src={require('../../../img/logo.png')} alt="logo" />
            <img className="scroll-logo hidden-xs" src={require('../../../img/logo-dark.png')} alt="logo" />
            <img className="scroll-logo visible-xs-block" src={require('../../../img/logo.png')} alt="logo" />
          </a>
          {/* <ul class="nav navbar-nav navbar-right nav-icons wrap-user-control">
                    <li>
                        <a id="search-open" href="#fakelink"><i class="fa fa-search"></i></a>
                    </li>
                    <li class="open-signup">
                        <a href="#fakelink"><i class="fa fa-user"></i></a>
                    </li>
                </ul> */}
          <div className="navbar-collapse collapse floated" id="navbar-collapse">
            <ul className="nav navbar-nav navbar-with-inside clearfix navbar-right with-border">
              <li >
                <a href="#/home" style={{color: '#333'}}>home</a>
                {/* <div class=" wrap-inside-nav">
                                <div class="inside-col">
                                    <ul class="inside-nav">
                                        <li><a href="home.html">Home 1</a></li>
                                        <li><a href="home-2.html">Home 2</a></li>
                                    </ul>
                                </div>
                            </div> */}
              </li>
              {/* <li>
                            <a href="#">Portfolio</a>
                            <div class=" wrap-inside-nav">
                                <div class="inside-col">
                                    <ul class="inside-nav">
                                        <li><a href="portfolio.html">Portfolio</a></li>
                                        <li><a href="portfolio-2.html">Portfolio 2</a></li>
                                        <li><a href="portfolio-3.html">Portfolio 3</a></li>
                                        <li><a href="portfolio-single.html">Portfolio single 1</a></li>
                                        <li><a href="portfolio-single-2.html">Portfolio single 2</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li> */}
              <li>
                <a href="#/about" style={{color: '#333'}}>about</a>
              </li>
              <li className="active">
                {/* <a href="#/project">Our Projects</a> */}
                {/* <Button
        aria-haspopup="true"
        className='menuButton'
        onClick={handleClick}
      >
        Our Projects
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ '.MuiMenu-paper' : {minWidth:'110px'} }}
      >
        <MenuItem component={'a'} href='#/project' onClose={handleClose}>
            <ListItemIcon >
            <AdminPanelSettingsIcon sx={{width:'1.5em', height:'1.5em'}} />
          </ListItemIcon>
          <ListItemText>Admin</ListItemText>
            </MenuItem>
        <MenuItem component={'a'} href='#/projectAgent' onClose={handleClose}>           
            <ListItemIcon>
            <PersonIcon sx={{width:'1.5em', height:'1.5em'}} />
          </ListItemIcon>
          <ListItemText>Agent</ListItemText>
            </MenuItem>
      </Menu> */}
      <Button aria-haspopup="true" href='#/projectLayout'  sx={{color: '#333 !important'}}>Our Projects</Button>

              </li>
              <li>
                <a href="#/services" style={{color: '#333'}}>services</a>
              </li>
              {/* <li>
                <a href="#/blog">Blog</a> */}
                {/* <div class=" wrap-inside-nav">
                                <div class="inside-col">
                                    <ul class="inside-nav">
                                        <li><a href="blog.html">Blog</a></li>
                                        <li><a href="blog-single.html">Blog single</a></li>
                                    </ul>
                                </div>
                            </div> */}
              {/* </li> */}
                {/* <a href="#/blog">Blog</a>
 
              </li> */}
              <li>
                <a href="#/contact" style={{color: '#333'}}>Contacts</a>
                {/* <div class=" wrap-inside-nav">
                                <div class="inside-col">
                                    <ul class="inside-nav">
                                        <li><a href="contact.html">Contacts 1 </a></li>
                                        <li><a href="contact-2.html">Contacts 2 </a></li>
                                    </ul>
                                </div>
                            </div> */}
              </li>
              <li> <Button className='menuButton' href='#/login' target='_blank' sx={{color: '#333 !important'}}> Login In</Button> </li>

            </ul>
          </div>
        </div>
        {/* <div class="navbar-search ">
                <div class="container">
                    <form>
                        <div class="input-group">
                            <input type="text" placeholder="Type your search..." class="form-control" autocomplete="off">
                            <span class="input-group-btn">
                                <button type="reset" class="btn search-close" id="search-close">
                                    <i class="fa fa-close"></i>
                                </button>
                            </span>
                        </div>
                    </form>
                </div>
            </div> */}
      </nav>
    </header>
  
  <section>
        <Container sx={{m:0, width:'100%', maxWidth:'100%', mt:'10%'}} maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          PROJECTS 
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar placeholder= "Search by Project Name" numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          {/* <Scrollbar sx={undefined}> */}
            <TableContainer sx={{ overflowX: 'auto'}}>
              <Table stickyHeader >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={list.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  isCheckbox={false}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { id, name, place,type } = row;
                    return (
                      <TableRow hover key={id} onClick={(e) => handleClickEdit(e, id)} sx={{cursor:'pointer'}}>
                        <TableCell component="th" scope="row" align="left">{index+1}</TableCell>
                        <TableCell align="left" sx={{color:'#37B4EE'}}>{capitalCase(name)}</TableCell>
                        <TableCell align="left"><Iconify icon={'zondicons:location'}/>&nbsp;{place}</TableCell>
                        <TableCell align="left" sx={{color:'#37B4EE'}}>{capitalCase(type)}</TableCell>
                        <TableCell align="center">
                       <IconButton color="primary" onClick={(e) => handleClickEdit(e, id)}> <EditIcon /> </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody> {loading ? <MiscSkeleton record={{number: 7}}/> :
                    <TableRow>
                      <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                       {list.length <=0 ? <Typography gutterBottom align="center" variant="subtitle1"> Not found</Typography> :<SearchNotFound searchQuery={filterName} /> }
                      </TableCell>
                    </TableRow> }
                  </TableBody> 
                )}
              </Table>
            </TableContainer>
          {/* </Scrollbar> */}

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      </section>

      <footer>
    {/* ========================== */}
    {/* BUY SECTION */}
    {/* ========================== */}
    {/* <section class="buy-section with-icon">
          <div class="section-icon"><span class="icon icon-Umbrella"></span></div>
          <div class="container">
              <div class="row">
                  <div class="col-md-8 col-md-offset-1 col-sm-9 wow fadeInLeft">
                      <div class="section-text">
                          <div class=" vcenter like">
                              <span class="icon icon-Like"></span> 
                          </div>
                          <div class="buy-text vcenter">
                              <div class="top-text">oscend - a creative & multipurpose template</div>
                              <div class="bottom-text">Lorem ipsum dolor sit amet consectetur elit sed</div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3 col-sm-3  wow fadeInRight">
                      <a href="#" class="btn btn-info ">Buy it now</a>
                  </div>
              </div>
          </div>
      </section> */}
    {/* ========================== */}
    {/* FOOTER - SOCIAL */}
    {/* ========================== */}
    {/* <section className="social-section dark dark-strong">
      <div className="container dark-content">
        <div className="tt">We are social 24/7 - Get in touch</div>
        <ul className="list-socials">
          <li>
            <a href="#">
              <i className="fa fa-twitter" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-facebook" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-linkedin" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-google-plus" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-dribbble" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-pinterest-p" />
            </a>
          </li>
        </ul>
      </div>
    </section> */}
    {/* ========================== */}
    {/* FOOTER - FOOTER */}
    {/* ========================== */}
    <section className="footer-section">
      <div className="container">
        <div className="row">
          {/* <div className="col-md-3 col-sm-3">
            <h5>about us</h5>
            <p>
              Crowned as ASIA’S GREATEST BRAND and ASIA’S GREATEST LEADER With
              Mr. Sathesh kumar Garu, the recipient of Global Indian Of The Year
              award, at the helm
            </p>
          </div> */}
          <div className="col-md-3 col-sm-3">
            <h5>Sitemap</h5>
            <div className="row">
              <div className="col-md-6">
                <ul className="footer-nav">
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li className="active">
                    <a href="#">Latest News</a>
                  </li>
                  <li>
                    <a href="#">Contact us</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="footer-nav">
                  <li>
                    <a href="#">Get Help</a>
                  </li>
                  <li>
                    <a href="#">Careers</a>
                  </li>
                  <li>
                    <a href="#">FAQ's</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <h5>Contact info</h5>
            <ul className="contacts-list">
              <li>
                <p>
                  <i className="icon icon-House" />
                  NO. 2-1-266/1/6, Flat No.201, Plot NO. 6, 2nd Floor, GSI (Sr) Bandlanguda, Nagole, Hyderabad -500068
                </p>
              </li>
              {/* <li>
                <p>
                  <i className="icon icon-Phone2" />
                  +91 8374416741
                </p>
              </li> */}
              <li>
                <p>
                  <i className="icon icon-Mail" />
                  <a href="mailto:support@oscend.com">
              care@perfectlayout.in
                  </a>{" "}
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-3">
            <h5>newsletter</h5>
            <p>
              Subscribe to our newsletter for latest updates about our creative
              agency
            </p>
            <div className="form-group has-feedback">
              <input
                type="email"
                className="form-control "
                placeholder="Email address ..."
              />
              <i className="icon icon-Mail form-control-feedback" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="copyright-section">
      <p>
        ©2023 <span>PERFECT LAYOUT PRIVATE LIMITED </span>. All Rights Reserved
      </p>
    </section>
  </footer>
      </>
    )
}

export default ProjectList;