import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Container } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 'bolder',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 'inherit',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const RightCheck = () => {
    return (
        <CheckCircleIcon sx={{width: '2em', height:'2em', color: '#3cb371'}} />
    )
};

const CrossCheck = () => {
    return (
        <CancelIcon sx={{width: '2em', height:'2em', color: '#ff0000'}}/>
    )
};

const rows = [
  createData('On-time Properity Registration', RightCheck(), CrossCheck(), CrossCheck(), CrossCheck()),
  createData('Security Guarantee', RightCheck(), CrossCheck(), CrossCheck(), 'Limited'),
  createData('100% Transparent', RightCheck(), CrossCheck(), CrossCheck(), 'Limited'),
  createData('Fully Developed Plots', RightCheck(), RightCheck(), RightCheck(), RightCheck()),
  createData('Affordability', RightCheck(), CrossCheck(), CrossCheck(), CrossCheck()),
  createData('Legal & Documentation', RightCheck(), RightCheck(), RightCheck(), RightCheck()),

];

export default function Tables() {
  return (
    <Container className='with-bottom-effect transparent-effect'>
         <div className="row  wow fadeIn">
                    <div className="col-md-12 col-sm-12">
                        <div className="clearfix text-center" style={{paddingRight: "3px"}}>
                            <h4>Competitive Analysis</h4>
                        </div>
                    </div>
                </div>

                <div className="wow fadeInLeft">
    <TableContainer component={Paper} sx={{ mt:5 }} elevation={6}>
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell align="center">Perfect Layout</StyledTableCell>
            <StyledTableCell align="center">Company 1</StyledTableCell>
            <StyledTableCell align="center">Company 2</StyledTableCell>
            <StyledTableCell align="center">Company 3</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="center">{row.calories}</StyledTableCell>
              <StyledTableCell align="center">{row.fat}</StyledTableCell>
              <StyledTableCell align="center">{row.carbs}</StyledTableCell>
              <StyledTableCell align="center">{row.protein}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
    </Container>
  );
}