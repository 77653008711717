import React, { useRef, useState, useEffect } from "react";
import { saveAs } from "file-saver";
import imgEdit from '../../../img/agentposter.jpg'
import {
    Button,
    CardContent,
    CardActions,
    Card,
    TextField,
    Grid,

  } from "@mui/material";

const ImageTextReplacer = () => {
  const canvasRef = useRef(null);
  const [newText, setNewText] = useState();

  // Load and modify the image with text replacement
  useEffect(() => {
    const img = new Image();

    img.src = imgEdit
 img.crossOrigin = "anonymous"; // Prevent canvas from being tainted


    img.onload = () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image on the canvas
      ctx.drawImage(img, 0, 0);

      // Fill a rectangle to "erase" the old text (adjust coordinates/size as needed)
    //   ctx.fillStyle = "white"; // Match the image's background color if needed
    //   ctx.fillRect(50, 10, 300, 60); // Adjust this based on where the text is

      // Add the new text
      ctx.font = "40px Arial";
      ctx.fillStyle = "black"; // Set your desired text color
      ctx.fillText(newText, 650,850); // Adjust coordinates to place the new text
    };
  }, [newText]);

  // Handle download
  const handleDownload = () => {
    const canvas = canvasRef.current;
    canvas.toBlob((blob) => {
      saveAs(blob, "edited_image.png"); // Save the edited image
    });
  };

  return (
    <div>
      <h2>Replace Text on Predefined Image</h2>

      {/* Input to change the text */}
<Grid container>
      <Grid item xs={4} spacing={2} padding={2}>
                <TextField
                  fullWidth
                  label="NAME OF PERSON"
                  onChange={(e)=>setNewText(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}  spacing={2}padding={2}>
                <TextField
                  fullWidth
                  label="DESIGINAION"
                 
                />
              </Grid>
              <Grid item xs={4}  spacing={2} padding={2}>
              <Button onClick={handleDownload}  variant='contained' style={{ marginTop: "10px" }} >
        Download Image
      </Button>
              </Grid>
           
              </Grid>
      {/* Canvas to display the image */}
      <canvas ref={canvasRef} style={{ display: "block", marginTop: "20px" }}></canvas>

      {/* Button to download the edited image */}
    
   
    </div>
  );
};

export default ImageTextReplacer;
